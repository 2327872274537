import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../components/SliderForm.css';
import Loader from './Loader';

const SidebarForm = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const navigate = useNavigate();

  const apiUrl = window.location.hostname === 'localhost'
    ? 'http://localhost:5000/send-email'
    : 'https://giftingy.com/send-email';

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalVisible(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isScriptLoaded) {
      const script = document.createElement('script');
      script.charset = 'utf-8';
      script.type = 'text/javascript';
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '2400919',
            formId: '8c4d11d1-62da-4efd-8c1e-018391bd6ddf',
            target: '#hubspot-form',
            onFormReady: () => {
              setIsFormLoading(false);
            }
          });
        }
        setIsScriptLoaded(true);
      };
      document.body.appendChild(script);
    }
  }, [isScriptLoaded]);

  const handleCloseModal = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsModalVisible(false);
      setIsFadingOut(false);
    }, 500);
  };

  return (
    <div>
      <button
        className="stickySide"
        onClick={() => setIsModalVisible(true)}>REACH US</button>

      {isScriptLoaded && (
        <div className={`wow unique-modal ${isFadingOut ? 'close' : 'open'} ${isModalVisible ? 'visible' : 'hidden'}`}>
          <div
            className="unique-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="close-button" onClick={handleCloseModal}>
              &times;
            </button>
            <h3 className="bannerFormHed">Enquire</h3>
            <div id="hubspot-form" style={{ width: '100%', minHeight: '400px' }}>
              {isFormLoading && <Loader />}
            </div>
          </div>
        </div>
      )}

      {/* WhatsApp Icon for Mobile View */}
      <div className="whatsapp-icon-container ">
        <a href="https://wa.me/919990158777" target="_blank" rel="noopener noreferrer">
          <img src="/images/whatsaap.png" alt="WhatsApp Icon" className="whatsapp-icon" />
          <div className="whatsapp-message">How can we help you?</div>
        </a>
      </div>
    </div>
  );
};

export default SidebarForm;
