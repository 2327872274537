// SchemaProvider.js
import React from 'react';
import { Helmet } from 'react-helmet';

const SchemaProvider = () => {
  // Define the schema directly within the component
  const localSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "giftingy",
    "image": "https://giftingy.com/logo.png",
    "@id": "",
    "url": "https://giftingy.com/",
    "telephone": "999 01 58 777",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "301-C, 3rd Floor, Supermart 2 Dlf Phase IV,",
      "addressLocality": "Gurgaon",
      "postalCode": "122003",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 28.46148,
      "longitude": 77.08799
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "opens": "09:00",
      "closes": "07:00"
    },
    "sameAs": [
      "https://www.facebook.com/profile.php?id=61565610380237",
      "https://www.instagram.com/giftingy2/?igsh=MXh0dWk2MHRycWpxcg%3D%3D",
      "https://www.linkedin.com/company/giftingy/?viewAsMember=true"
    ]
  };

  // Convert schema object to JSON-LD string
  const schemaJson = JSON.stringify(localSchema, null, 2); // Pretty print with 2 spaces

  return (
    <Helmet>
      <script type="application/ld+json">
        {schemaJson}
      </script>
    </Helmet>
  );
};

export default SchemaProvider;
