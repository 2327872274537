import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';

import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/spicesimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalize your gourmet food and spice gifts to reflect the recipient's tastes." },
    { title: "Branding on Gifts", detail: "Ensure your brand is remembered with custom-branded packaging." },
    { title: "Value for Money", detail: "High-quality gourmet and spice products that fit within your budget." },
    { title: "Concept Creation", detail: "Unique gift ideas tailored to your needs." },
    { title: "Diverse Range of Gifts", detail: "There is an extensive selection of food and spice hampers for every occasion." },
    { title: "Quality Assurance", detail: " Premium quality is guaranteed in every gift." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services across India." },
    { title: "Eco-Friendly Options", detail: "Choose sustainable packaging for a responsible gifting option." },
];
const FoodandSpicesGiftng = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
        // Set meta title, description, and keywords
        document.title = "Luxury Food and Spices Corporate Gifts in India | Giftingy";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', "Discover premium food and spices corporate gifts in India. Choose from gourmet baskets, luxury spice hampers, and customized edible gifts for employees and clients.");
        } else {
          const meta = document.createElement('meta');
          meta.name = "description";
          meta.content = "Discover premium food and spices corporate gifts in India. Choose from gourmet baskets, luxury spice hampers, and customized edible gifts for employees and clients.";
          document.head.appendChild(meta);
        }
        
        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
            metaKeywords.setAttribute('content', "Edible products Corporate Products, Gifting corporate edible products, Gourmet food basket gifts, Gourmet food gift basket ideas, Gourmet food gifts company, Customized eatable gift hampers India, Food gift hampers India, Food gift baskets for employees, Food gift baskets for clients, Luxury food products gifting, Luxury food products gifting ideas, Luxury food products gifting company, Spices as corporate gifting, Spices as corporate gifting hampers, Spices in corporate gift boxes, Indian spices gift box corporate gift, Indian spices box corporate gift, Luxury Indian spices box corporate gift, Food and spices as Corporate Gifts");
        } else {
            const meta = document.createElement('meta');
            meta.name = "keywords";
            meta.content = "Edible products Corporate Products, Gifting corporate edible products, Gourmet food basket gifts, Gourmet food gift basket ideas, Gourmet food gifts company, Customized eatable gift hampers India, Food gift hampers India, Food gift baskets for employees, Food gift baskets for clients, Luxury food products gifting, Luxury food products gifting ideas, Luxury food products gifting company, Spices as corporate gifting, Spices as corporate gifting hampers, Spices in corporate gift boxes, Indian spices gift box corporate gift, Indian spices box corporate gift, Luxury Indian spices box corporate gift, Food and spices as Corporate Gifts";
            document.head.appendChild(meta);
        }
        
        
      }, []);
const faqs = [
    {
      "question": "What makes food and spices ideal for corporate gifts?",
      "answer": "Food and spices appeal to a wide range of tastes and preferences, offering a sensory experience that is both enjoyable and memorable. These gifts can be customized to reflect the recipient's cultural background, dietary preferences, or special occasions, making them a versatile and thoughtful choice.",
      "open": false
    },
    {
      "question": "How do I choose the right food and spice gifts for my clients?",
      "answer": "When choosing food and spice gifts for clients, consider their tastes, dietary preferences, and cultural significance. Gourmet food baskets, luxury spice hampers, and personalized edible products are all excellent options that can be tailored to suit the occasion. Consult with a reputable gifting company like Giftingy to ensure your selection is of the highest quality.",
      "open": false
    },
    {
      "question": "Can food and spice gifts be customized with my company's branding?",
      "answer": "Food and spice gifts can be customized with your company's branding. This includes adding your logo to packaging, personalizing messages, and selecting specific products that align with your brand's identity. Customized branding enhances the impact of your gift and ensures the recipient remembers it.",
      "open": false
    },
    {
      "question": "What are some popular food and spice gifts for employees?",
      "answer": "Popular food and spice gifts for employees include gourmet food baskets, personalized spice hampers, and luxury edible products. These gifts are perfect for celebrating milestones, showing appreciation, or simply treating your team to something special. Consider options that reflect the recipient's preferences and offer a mix of flavours and products.",
      "open": false
    },
    {
      "question": "How can I ensure the quality of food and spice gifts for corporate gifting?",
      "answer": "Ensuring the quality of food and spice gifts involves selecting reputable vendors and gifting companies, prioritizing premium ingredients and sustainable practices. Giftingy, for example, offers only the finest gourmet and spice products, with a focus on quality assurance and customer satisfaction. Always review the product selection and packaging options to meet your standards.",
      "open": false
    },
    {
      "question": "Are food and spice gifts suitable for international clients?",
      "answer": "Food and spice gifts are highly suitable for international clients as they showcase India's rich culinary heritage. These gifts are often cherished for their uniqueness and cultural significance. When selecting gifts for global clients, consider high-end options like luxury spice hampers or gourmet food baskets that reflect the flavours of India.",
      "open": false
    },
    {
      "question": "What are some health-conscious food and spice gift options?",
      "answer": "Health-conscious food and spice gift options include organic spices, gourmet nuts and seeds, artisanal teas, and gluten-free or vegan snacks. These products cater to the growing demand for health-focused gifts and are perfect for recipients who prioritize wellness and nutrition.",
      "open": false
    },
    {
      "question": "How do I package food and spice gifts for corporate events?",
      "answer": "Packaging food and spice gifts for corporate events involves selecting high-quality, eco-friendly materials that reflect the occasion's significance. Options include custom-branded boxes, reusable containers, or sustainable wrapping. Giftingy offers a variety of packaging solutions that can be tailored to your brand and the event.",
      "open": false
    },
    {
      "question": "What are some luxury food and spice gifts for top executives?",
      "answer": "Luxury food and spice gifts for top executives include bespoke gourmet hampers, curated spice collections, and exclusive artisanal products. These gifts reflect sophistication and are perfect for recognizing the achievements and contributions of senior leaders within your organization.",
      "open": false
    },
    {
      "question": "Why are food and spice gifts popular during festive seasons?",
      "answer": "Food and spice gifts are popular during festive seasons because they align with the cultural practice of sharing and celebrating with food. These gifts are perfect for Diwali, Christmas, Eid, and other festivals, offering a way to connect with clients and employees while honouring tradition.",
      "open": false
    },
    {
      "question": "How can I incorporate food and spice gifts into my corporate gifting strategy?",
      "answer": "Incorporating food and spice gifts into your corporate gifting strategy involves understanding your recipients' preferences, selecting high-quality products, and ensuring the gifts align with your brand's image. Giftingy can help you create a plan that includes these popular and impactful gifts.",
      "open": false
    },
    {
      "question": "What are some innovative spice gift ideas for corporate clients?",
      "answer": "Innovative gift ideas for corporate clients include curated spice boxes featuring exotic blends, personalized spice jars with custom labels, and gourmet spice collections paired with recipe cards. These gifts offer a unique and flavorful experience that clients will appreciate.",
      "open": false
    },
    {
      "question": "Can food and spice gifts be used for employee wellness programs?",
      "answer": "Food and spice gifts can be an excellent addition to employee wellness programs. Health-focused options like organic spices, gourmet teas, and nutrient-rich snacks promote wellness and show commitment to your employees' health. These gifts can be part of a broader wellness initiative within your organization.",
      "open": false
    },
    {
      "question": "What are some eco-friendly packaging options for food and spice gifts?",
      "answer": "Eco-friendly packaging options for food and spice gifts include recyclable cardboard boxes, reusable glass jars, and biodegradable wrapping. Sustainable packaging enhances the appeal of your gifts and aligns with corporate social responsibility goals. Giftingy offers various eco-friendly packaging solutions.",
      "open": false
    },
    {
      "question": "How do food and spice gifts reflect cultural appreciation?",
      "answer": "Food and spice gifts reflect cultural appreciation by showcasing different regions' culinary traditions and flavours. These gifts are a thoughtful way to honour cultural diversity and connect with recipients on a deeper level. Consider gifts that highlight local specialities or traditional spices.",
      "open": false
    },
    {
      "question": "What are some gourmet food gift ideas for corporate milestones?",
      "answer": "Gourmet food gift ideas for corporate milestones include luxury hampers featuring artisanal cheeses, fine chocolates, and premium wines. These gifts celebrate significant achievements and show appreciation for the contributions of employees, clients, or partners.",
      "open": false
    },
    {
      "question": "How can I ensure the freshness of food and spice gifts?",
      "answer": "Ensuring the freshness of food and spice gifts involves selecting high-quality products from reputable suppliers and ensuring proper storage and packaging. Giftingy works with trusted vendors to offer fresh and flavorful products with packaging that preserves their quality until delivery.",
      "open": false
    },
    {
      "question": "What are some unique spice gift ideas for cultural events?",
      "answer": "Unique gift ideas for cultural events include curated spice sets with regional blends, personalized spice jars with custom labels, and gourmet spice collections paired with recipe cards. These gifts offer a unique and memorable experience that celebrates cultural diversity.",
      "open": false
    },
    {
        "question": "Can I customize gourmet food gifts for specific corporate events?",
        "answer": "Gourmet food gifts can be customized for specific corporate events by selecting items that match the event's theme or significance. For example, you can choose festive-themed treats for holiday celebrations, health-focused snacks for wellness events, or luxury items for high-profile corporate gatherings. Custom packaging and branding can also enhance the impact of the gift.",
        "open": false
      },
      {
        "question": "How can snacks be used as effective corporate gifts?",
        "answer": "Snacks can be used as effective corporate gifts by selecting high-quality gourmet options that appeal to a wide audience. These gifts can be presented in beautifully packaged hampers or baskets, making them ideal for sharing in an office or home. Personalized snack packs with branded packaging can also make a memorable and appreciated gift.",
        "open": false
      },
      {
        "question": "What are some luxury food product ideas for corporate gifting?",
        "answer": "Luxury food product ideas for corporate gifting include artisanal cheeses, fine wines, gourmet chocolates, premium olive oils, and exotic spice blends. These items can be curated into bespoke hampers or gift boxes that reflect the recipient's tastes and preferences. Luxury food gifts are perfect for celebrating milestones, expressing appreciation, or impressing high-profile clients.",
        "open": false
      },
      {
        "question": "Why are edible corporate gifts popular during the holiday seasons?",
        "answer": "Edible corporate gifts are popular during the holiday seasons because they align with the tradition of sharing and enjoying food during festive celebrations. These gifts can be customized to include seasonal treats and flavours, making them a thoughtful and relevant choice. Edible gifts also create a sense of warmth and hospitality, which is especially appreciated during the holidays.",
        "open": false
      },
      {
        "question": "What are some health-focused edible gifts for corporate wellness programs?",
        "answer": "Health-focused edible gifts for corporate wellness programs include organic snack packs, nutrient-rich granola bars, gluten-free treats, and superfood blends. These gifts promote healthy eating habits and show the company values its employees' well-being. Including educational materials on nutrition or wellness tips can further enhance the impact of these gifts.",
        "open": false
      },
      {
        "question": "How do food and spice gifts promote cultural appreciation in corporate gifting?",
        "answer": "Food and spice gifts promote cultural appreciation in corporate gifting by showcasing different cultures' culinary traditions and flavours. These gifts can include regional specialities, traditional spice blends, or gourmet items that reflect the recipient's cultural background. Such gifts are a thoughtful way to connect with clients and employees from diverse cultural backgrounds.",
        "open": false
      },
      {
        "question": "What are some unique gourmet food gift ideas for corporate milestones?",
        "answer": "Unique gourmet food gift ideas for corporate milestones include customized gift hampers with artisanal products, exclusive wine and cheese pairings, luxury chocolate collections, and curated spice sets. These gifts celebrate significant achievements and show appreciation for the contributions of employees, clients, or partners.",
        "open": false
      },
      {
        "question": "Can food and spice gifts be personalized for corporate clients?",
        "answer": "Food and spice gifts can be personalized for corporate clients by selecting items that reflect their tastes and preferences, adding custom labels or messages, and packaging the gifts in branded boxes. Personalized gifts create a lasting impression and strengthen business relationships by showing that you've considered the selection.",
        "open": false
      },
      {
        "question": "What are some gourmet food gift basket ideas for corporate events?",
        "answer": "Gourmet food gift basket ideas for corporate events include themed baskets such as Italian cuisine, chocolate lovers, or a healthy snack collection. These baskets can be customized with high-quality products like artisanal pasta, gourmet sauces, premium chocolates, and organic snacks. Adding branded packaging or a personalized message can enhance the gift's impact.",
        "open": false
      },
      {
        "question": "How can I ensure the freshness of food and spice gifts for corporate events?",
        "answer": "Ensuring the freshness of food and spice gifts for corporate events involves selecting products from reputable suppliers, choosing items with longer shelf lives, and opting for packaging that preserves the quality and flavour of the contents. Working with a trusted gifting company like Giftingy can help you source fresh, high-quality products.",
        "open": false
      },
      {
        "question": "What are some eco-friendly packaging options for edible corporate gifts?",
        "answer": "Eco-friendly packaging options for edible corporate gifts include recyclable cardboard boxes, reusable glass jars, and biodegradable wrapping. Sustainable packaging enhances the appeal of your gifts and aligns with corporate social responsibility goals. Many gourmet and spice products also come with eco-friendly packaging as part of their brand identity.",
        "open": false
      },
      {
        "question": "Why are spices considered thoughtful corporate gifts?",
        "answer": "Spices are considered thoughtful corporate gifts because they offer a unique sensory experience that enhances the recipient's culinary creations. They are versatile and culturally significant and can be customized to include exotic blends or regional specialities. Spices also reflect the rich culinary heritage of India, making them an excellent choice for local and international clients.",
        "open": false
      },
      {
        "question": "What are some unique edible gift ideas for corporate celebrations?",
        "answer": "Unique edible gift ideas for corporate celebrations include gourmet popcorn tins, artisanal candy jars, customized chocolate boxes, and luxury tea sets. These gifts can be tailored to the festival's theme and are perfect for sharing in an office setting or enjoying at home.",
        "open": false
      },
      {
        "question": "How do food and spice gifts enhance client appreciation events?",
        "answer": "Food and spice gifts enhance client appreciation events by providing a memorable and enjoyable experience that resonates with the recipient. These gifts show appreciation, foster goodwill, and create lasting memories. They are a powerful tool for building and maintaining strong business relationships, especially when customized to suit clients' tastes.",
        "open": false
      },
      {
        "question": "What are some luxury food gift ideas for top-tier corporate clients?",
        "answer": "Luxury food gift ideas for top-tier corporate clients include exclusive wine and cheese pairings, bespoke gourmet hampers, premium caviar sets, and curated spice collections with rare and exotic blends. These gifts convey sophistication and appreciation, making them ideal for recognizing the contributions and importance of high-profile clients.",
        "open": false
      }
]

  
return (
<>
<ServiceHeader title="Food and Spices as Corporate Gifts" imageUrl={`${process.env.PUBLIC_URL}/images/spicesbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Appeal of Food and Spices as Corporate Gifts</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Food and spices have long been treasures of warmth, hospitality, and care. In the corporate world, gifting food and spices has become popular for companies seeking to leave a lasting impression on clients, employees, and partners. The diversity and richness of gourmet food and spice baskets make them ideal for corporate gifting. From luxury food products to finely curated spice hampers, these gifts are perfect for celebrating special occasions, recognizing achievements, or simply showing appreciation.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Food and spices as corporate gifts offer a sensory experience that delights the recipient and enhances your brand's image. Whether it's a gourmet food basket, a box of exotic spices, or a luxurious food hamper, these gifts cater to a wide range of tastes and preferences. They can be customized to reflect the recipient's cultural background, dietary preferences, or even the season, making them a versatile and thoughtful option.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Here's why food and spices are excellent choices for corporate gifting:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Versatility: Suitable for various occasions and recipients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Customizable: Tailor the gift to reflect personal tastes and preferences</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Luxury Appeal: High-end gourmet and spice products convey sophistication</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Health-Conscious: Opt for organic, health-focused products for a modern touch</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Cultural Significance: Spices and gourmet foods can reflect cultural traditions</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Memorability: A gift that engages all senses, creating lasting memories</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Convenience: Easy to package, transport, and distribute</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Eco-Friendly: Many gourmet and spice products come with sustainable packaging</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Corporate Branding: Customize with your company logo for enhanced brand recall</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Wide Appeal: Everyone enjoys high-quality food and spices, making these gifts universally appreciated</p>
            </li>
         </div>
      </ul>
    
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Food and Spices Corporate Gifts?</h2>
      <p >Here's why Giftingy is the best choice for food and spices corporate gifts:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/spicesimg2.jpg`} alt="Indian Spices Gift Box for Corporate Gifts" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy is your trusted partner for premium food and spices corporate gifts in India. As a leading gifting company, we offer an extensive selection of gourmet food baskets, luxury spice hampers, and customized edible products. Our curated collections are designed to impress and delight, making them perfect for corporate gifting.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               At Giftingy, we understand that every corporate gift reflects your brand's values and commitment to excellence. That's why we source only the finest ingredients and products for our food and spice gift hampers. Whether you're looking to celebrate a milestone, appreciate your employees, or thank your clients, our gifts are crafted to leave a lasting impression.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Our range of food and spices corporate gifts includes everything from traditional Indian spices to gourmet food baskets featuring artisanal products. We offer various packaging options, including eco-friendly and luxury materials, to suit your brand's image. Our expert team can customize each hamper with your company's branding, ensuring your gift is as unique as your business.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Premium Quality: We source only the best gourmet and spice products, ensuring top-notch quality in every gift.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customization: Tailor your gifts with branded packaging, personalized messages, and product selection.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide Range: Our extensive collection includes options for every taste, budget, and occasion.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expert Curation: Our team selects the finest products, ensuring a memorable gifting experience.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Timely Delivery: We guarantee delivery across India, ensuring your gifts reach the recipients on time.

            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Sustainable Choices: We offer eco-friendly packaging and sustainably sourced products.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customer Satisfaction: Our commitment to customer satisfaction means we go above and beyond to meet your gifting needs.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Corporate Experience: With years of experience in corporate gifting, we understand the nuances of professional gifting.

            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Flexible Ordering: We offer flexible ordering options for bulk orders or individual hampers.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Supportive Service: Our dedicated customer support team assists you at every step.
            </p>
         </div>
       
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy </h2>
            <h3 className='textYellow'>Giftingy: Your Partner for Gourmet Food and Spice Corporate Gifts </h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional food and spices corporate gifts that cater to a wide range of tastes and preferences. Our unique selling propositions (USPs) highlight our commitment to quality, customization, and customer satisfaction.
            </p>
           
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/spicesimg3.jpg`} alt="Customized Edible Gift Hampers India" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="FoodandSpicesGiftng" />

<FaqPage faqs={faqs} />
</>
);
};
export default FoodandSpicesGiftng;