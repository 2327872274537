import React from 'react';
import { Link } from 'react-router-dom';
import './CategoryList.css';

const categories = [
  { icon: '/images/corporateicon.png', label: 'Corporate Gifting', link: '/services/corporate-gifting' },
  { icon: '/images/hericon.png', label: 'Gifts For Her/Him', link: '/services/gifts-for-her' },
  { icon: '/images/festiveicon.png', label: 'Festive Gifting', link: '/services/festival-gifting' },
  { icon: '/images/homeicon.png', label: 'Home Decor', link: '/services/luxury-home-decor-gifting' },
  { icon: '/images/weddingicon.png', label: 'Wedding Gifts', link: '/services/wedding-return-gifts' },
  { icon: '/images/drufruicon.png', label: 'Dry Fruits & Sweets', link: '/services/dryfruits-and-sweets-gifting' },
  { icon: '/images/meedalsicon.png', label: 'Medals & Trophies', link: '/services/branded-medals-and-trophies' },
  { icon: '/images/vastuicon.png', label: 'Vastu Gifting', link: '/services/Vastu-corporate-gifting' },
  { icon: '/images/divinity.png', label: 'Divinity Gifting', link: '/services/divinity-gifts' },
];

const CategoryList = () => {
  return (
    <div className='mt-5'>
      <div className="category-list">
        {categories.map((category, index) => (
          <div className="category-item" key={index}>
            <Link to={category.link} className="category-link">
              <img src={category.icon} alt={category.label} className="category-icon2" />
              <div className="category-label">{category.label}</div>
            </Link>
            {index < categories.length - 1 && <div className="vertical-divider"></div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryList;
