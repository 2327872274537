// FaqPage.js
import React, { useState } from 'react';
import './FaqPage.css'; // Ensure your CSS file is imported
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import FAQSchemaGenerator from '../components/FAQSchemaGenerator'; // Adjust the import path as needed

const FaqPage = ({ faqs }) => {
  // Initialize state with one FAQ open by default in each column
  const [faqsState, setFaqs] = useState(() => {
    const halfLength = Math.ceil(faqs.length / 2);
    return faqs.map((faq, index) => ({
      ...faq,
      open: index === 0 || index === halfLength // Open the first FAQ of each column by default
    }));
  });

  const toggleFAQ = (index, column) => {
    setFaqs(faqsState.map((faq, i) => {
      if (column === 'col1' && i < Math.ceil(faqs.length / 2)) {
        return {
          ...faq,
          open: i === index ? !faq.open : false, // Toggle the clicked FAQ and close others in the same column
        };
      } else if (column === 'col2' && i >= Math.ceil(faqs.length / 2)) {
        return {
          ...faq,
          open: i === index ? !faq.open : false, // Toggle the clicked FAQ and close others in the same column
        };
      }
      return faq;
    }));
  };

  return (
    <div className="container faq-section">
      <h2>FAQs</h2>
      <div className="row">
        <div className="col-md-6">
          {faqsState.slice(0, Math.ceil(faqsState.length / 2)).map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${faq.open ? 'open' : ''}`}
              onClick={() => toggleFAQ(index, 'col1')}
            >
              <div className="faq-question">
                <h3>{faq.question}</h3>
                <span className="faq-icon">
                  <FontAwesomeIcon
                    icon={faq.open ? faChevronUp : faChevronDown}
                  />
                </span>
              </div>
              {faq.open && (
                <div className="faq-answer">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="col-md-6">
          {faqsState.slice(Math.ceil(faqsState.length / 2)).map((faq, index) => (
            <div
              key={index + Math.ceil(faqsState.length / 2)}
              className={`faq-item ${faq.open ? 'open' : ''}`}
              onClick={() => toggleFAQ(index + Math.ceil(faqsState.length / 2), 'col2')}
            >
              <div className="faq-question">
                <h3>{faq.question}</h3>
                <span className="faq-icon">
                  <FontAwesomeIcon
                    icon={faq.open ? faChevronUp : faChevronDown}
                  />
                </span>
              </div>
              {faq.open && (
                <div className="faq-answer">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <FAQSchemaGenerator faqItems={faqs} />
    </div>
  );
};

export default FaqPage;
