// FAQSchemaGenerator.js
import React from 'react';
import { Helmet } from 'react-helmet';

const FAQSchemaGenerator = ({ faqItems }) => {
  // Generate the FAQ schema JSON-LD
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqItems.map(faq => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer
      }
    }))
  };

  // Convert the FAQ schema to JSON-LD string
  const faqSchemaJson = JSON.stringify(faqSchema);

  return (
    <Helmet>
      <script type="application/ld+json">
        {faqSchemaJson}
      </script>
    </Helmet>
  );
};

export default FAQSchemaGenerator;
