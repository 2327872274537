import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p className='footerdisclaimer'>
          <b>Disclaimer:</b> The content on this Website,
          <NavLink to='/'> https://www.giftingy.com</NavLink> ("the Website"), including images, product descriptions, and catalogues (including attached PDFs), is provided for informational purposes only. By accessing and using the Website, you agree to the terms outlined in this disclaimer. If you disagree with any part of this disclaimer, please refrain from using the Website.
          <NavLink className='readMore' to='/disclaimer'>Read More</NavLink>
        </p>
        
        <div className="row">
          <div className="col-md-3">
            <h5 className="footer-heading textWhite logs">
              <NavLink to='/'>Giftingy</NavLink>
            </h5>
            <div>
              <span>
                Giftingy.com is owned and operated by Enlargenet GST no. – 06ADIPB5368H3ZJ Regd.
              </span>
            </div>
            <h5 className="footer-heading topmar">Address</h5>
            <address className='footeradd'>
              301-C, 3rd Floor, Supermart 2 <br/>
              Dlf Phase IV, Gurgaon<br/>
              <a href="tel:+91-9990158777">+91-9990158777</a><br/>
              <a href="mailto:vineet@giftingy.com">vineet@giftingy.com</a>
            </address>
            <div className="social-icons nomargin">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
            </div>
          </div>

          <div className="col-md-3">
            <h5 className="footer-heading">Services</h5>
            <ul className="list-unstyled">
              <li><NavLink to="/services/corporate-gifting">Corporate Gifting</NavLink></li>
              <li><NavLink to="/services/gifts-for-her">Gifts For Her/Him</NavLink></li>
              <li><NavLink to="/services/festival-gifting">Festive Gifting</NavLink></li>
              <li><NavLink to="/services/luxury-home-decor-gifting">Home Decor</NavLink></li>
              <li><NavLink to="/services/wedding-return-gifts">Wedding Return Gifts</NavLink></li>
              <li><NavLink to="/services/dryfruits-and-sweets-gifting">Dry Fruits & Sweets</NavLink></li>
              <li><NavLink to="/services/branded-medals-and-trophies">Medals & Trophies</NavLink></li>
              <li><NavLink to="/services/Vastu-corporate-gifting">Vastu Gifting</NavLink></li>
              <li><NavLink to="/services/divinity-gifts">Divinity Gifting</NavLink></li>
            </ul>
          </div>

          <div className="col-md-3">
            <h5 className="footer-heading">Quick Links</h5>
            <ul className="list-unstyled">
              <li><NavLink exact to="/">Home</NavLink></li>
              <li><NavLink to="/about">About Us</NavLink></li>
              <li><NavLink to="/blogs">Blog</NavLink></li>
              <li><NavLink to="/contact">Contact</NavLink></li>
              <li><NavLink to="/disclaimer">Disclaimer</NavLink></li>
            </ul>
          </div>

          <div className="col-md-3">
            <h5 className="footer-heading">Trending Products</h5>
            <div className="image-row">
              <img className='imageZoomm' src='/images/imaage3.jpg' alt='Product Image'></img>
              <img className='imageZoomm' src='/images/imaage2.jpg' alt='Product Image'></img>
            </div>
            <div className="image-row">
              <img className='imageZoomm' src='/images/imaage1.jpg' alt='Product Image'></img>
              <img className='imageZoomm' src='/images/imaage4.jpg' alt='Product Image'></img>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col text-center">
            <p className="footer-text">
              &copy; 2024 Giftingy. All rights reserved. | <NavLink to="/privacy-policy">Privacy Policy</NavLink> | <NavLink to="/terms-of-service">Terms of Service</NavLink> | Designed & Developed By <a target='_blank' href='https://www.conceptualise.in' rel="noopener noreferrer">Conceptualise</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
