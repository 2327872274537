import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../components/EnquireForm.css';
import Loader from './Loader'; // Import the Loader component

const ContactPage = () => {
  const [loading, setLoading] = useState(false); // State to control loader visibility
  const [error, setError] = useState(null); // State to control error message visibility
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true); // Show loader when the component is first rendered

    // Load the HubSpot form script
    const script = document.createElement('script');
    script.src = "https://js.hsforms.net/forms/embed/v2.js"; // Use HTTPS
    script.charset = "utf-8";
    script.type = "text/javascript";
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt && window.hbspt.forms) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "2400919",
          formId: "8c4d11d1-62da-4efd-8c1e-018391bd6ddf",
          target: "#hubspot-form",
          onFormSubmitted: () => {
            navigate('/thankyou'); // Redirect after form submission
          },
        });
      }
      setLoading(false); // Hide loader when script has loaded
    };

    script.onerror = () => {
      setError('Failed to load the form. Please try again later.');
      setLoading(false); // Hide loader on error
    };

    // Clean up script on component unmount
    return () => {
      document.body.removeChild(script);
      // Optional: You may need to remove the HubSpot form manually from the DOM if necessary.
      const hubspotForm = document.getElementById('hubspot-form');
      if (hubspotForm) {
        hubspotForm.innerHTML = ''; // Clear the form container
      }
    };
  }, [navigate]);

  return (
    <div className="contact-page">
      {loading && <Loader />} {/* Show loader if loading state is true */}
      {error && <p className="error-message">{error}</p>} {/* Show error message if there is any */}
      <div className="contact-info">
        <div className="address-section">
          <h2>Contact Us</h2>
          <p className='contacts'>
            <strong>Address:</strong>
            <a href='https://maps.app.goo.gl/PjsmRAU8PXu5Z3X16' target="_blank" rel="noopener noreferrer">
              301-C, 3rd Floor, Supermart 2 Dlf Phase IV, Gurgaon
            </a>
          </p>
          <p className='contacts'>
            <strong>Email:</strong>
            <a href='mailto:vineet@giftingy.com'>vineet@giftingy.com</a>
          </p>
          <p className='contacts'>
            <strong>Phone:</strong>
            <a href='tel:+919990158777'>(+91) 999 01 58 777</a>
          </p>
        </div>
        <div className="map-section">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3507.62405958686!2d77.0850931!3d28.460747!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18db2f872c01%3A0x5fac1063e266c418!2sConceptualise!5e0!3m2!1sen!2sin!4v1724159716868!5m2!1sen!2sin"
            width="100%"
            height="500"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            aria-hidden="false"
            tabIndex="0"
            title="Company Location"
          ></iframe>
        </div>
      </div>
      <div className="contact-form">
        <h2>Get in Touch</h2>
        <div id="hubspot-form"></div> {/* Placeholder for HubSpot form */}
      </div>
    </div>
  );
};

export default ContactPage;
