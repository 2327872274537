import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/dryswetimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised dry fruits and sweets gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality dry fruits and sweets within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const DryFruitsAndSweets = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
       
      }, []);
const faqs = [
    {
        "question": "What are good dry fruits and sweets for corporate gifting?",
        "answer": "Good dry fruits and sweets for corporate gifting include luxury dry fruit hampers, premium mithai, and custom sweet boxes. Popular choices are gourmet dry fruits, high-end chocolates, and personalised sweet gifts. The key is to choose gifts that reflect the recipient's preferences and the occasion. Consulting a gourmet gifting company can provide valuable insights and recommendations.",
        "open": false
    },
    {
        "question": "Why is corporate gifting with dry fruits and sweets important?",
        "answer": "Corporate gifting with dry fruits and sweets is important because it expresses appreciation and gratitude. These gifts enhance the joy and spirit of celebrations, fostering stronger business relationships. Thoughtful and well-chosen dry fruit and sweet gifts reflect the company's appreciation for employees, clients, and partners. They also promote a positive work environment and enhance brand visibility. Investing in quality gifts signifies a commitment to hospitality and goodwill.",
        "open": false
    },
    {
        "question": "What are some popular dry fruits and sweets gifts for employees?",
        "answer": "Popular dry fruit and sweets gifts for employees include branded sweet hampers, customised dry fruit boxes, and premium mithai assortments. Handcrafted items such as gourmet dry fruits, festive sweet hampers, and personalised sweet gifts are also appreciated. Consider luxury dry fruit gift sets, high-end sweets, and bespoke dry fruit assortments. These gifts reflect the company's appreciation and foster a positive work environment.",
        "open": false
    },
    {
        "question": "How do I choose the right dry fruits and sweets for corporate gifting?",
        "answer": "Choosing the right dry fruits and sweets for corporate gifting involves understanding the recipients' preferences and the occasion. Opt for high-quality items that reflect thoughtfulness and the festive spirit. Personalisation can add a special touch, making the gifts more meaningful. Consider the practicality and relevance of the gifts to ensure they are appreciated and used. Consulting a gourmet gifting company can provide valuable insights and recommendations.",
        "open": false
    },
    {
        "question": "What are the benefits of working with a gourmet corporate gifting company?",
        "answer": "Working with a gourmet corporate gifting company ensures you receive high-quality, well-crafted items that reflect the festive spirit and the company's appreciation. These companies offer various customisation options to make your gifts unique and personal. They provide expertise in selecting and designing gifts that suit your needs and preferences. Reliable delivery services and excellent customer support are additional benefits. Partnering with a reputable company ensures a seamless and satisfactory gifting experience.",
        "open": false
    },
    {
        "question": "Can I order dry fruits and sweets for corporate gifting in bulk?",
        "answer": "Yes, you can order dry fruits and sweets in bulk from various gifting companies for corporate gifting. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a gourmet gifting company can provide valuable insights and options for bulk orders.",
        "open": false
    },
    {
        "question": "What are some premium dry fruits and sweets gift options?",
        "answer": "Premium dry fruits and sweets gift options include bespoke dry fruit assortments, luxury sweet hampers, and high-end mithai boxes. Handcrafted items such as gourmet dry fruits, fine sweet confections, and personalised sweet gifts are also appreciated. Consider festive sweet hampers, luxury gift sets, and exclusive dry fruit assortments. These gifts reflect thoughtfulness and are ideal for premium corporate gifting.",
        "open": false
    },
    {
        "question": "How do dry fruits and sweets enhance corporate celebrations?",
        "answer": "Dry fruits and sweets enhance corporate celebrations by adding a touch of sweetness and appreciation. They are ideal for company anniversaries, client appreciation events, and employee recognition programs. These gifts reflect the company's commitment to quality and excellence. Customised dry fruit and sweet gifts make the celebration more memorable and meaningful. They foster a positive and respectful corporate culture.",
        "open": false
    },
    {
        "question": "What are some unique dry fruit and sweet gift ideas for corporate events?",
        "answer": "Unique dry fruit and sweet gift ideas for corporate events include bespoke dry fruit assortments, customised sweet boxes, and handcrafted sweet confections. High-end items such as luxury dry fruit gift sets, gourmet sweets, and fine mithai confections are also popular. Consider festive sweet hampers, personalised gifts, and exclusive dry fruit assortments. The key is to choose gifts that reflect the recipient's preferences and the event's significance.",
        "open": false
    },
    {
        "question": "Why should companies invest in branded dry fruit and sweet gifts?",
        "answer": "Companies should invest in branded dry fruit and sweet gifts to show appreciation and recognition for their employees, clients, and partners. These gifts enhance corporate relationships and convey a commitment to quality and excellence. Branded dry fruit and sweet gifts are highly valued and leave a lasting impression on the recipients. They also foster a positive and respectful corporate culture. Investing in branded gifts promotes a strong and prestigious brand image.",
        "open": false
    },
    {
        "question": "How can I ensure the quality of dry fruits and sweets for corporate gifting?",
        "answer": "Ensuring the quality of dry fruits and sweets for corporate gifting involves selecting reputable gifting companies and suppliers. Check reviews and testimonials to gauge the reliability and quality of their products. Opt for companies with a proven track record of high-quality craftsmanship and customisation. Regularly review samples and provide clear specifications for the customisation. Partnering with a trusted gourmet gifting company ensures the quality and satisfaction of your gifts.",
        "open": false
    },
    {
        "question": "What are the latest trends in dry fruit and sweet corporate gifting?",
        "answer": "The latest trends in dry fruit and sweet corporate gifting include eco-friendly products, gourmet dry fruits, and bespoke sweet gift sets. Sustainable and environmentally friendly items are increasingly popular. Personalised dry fruit and sweet gifts such as custom mithai boxes and engraved dry fruit hampers are in demand. Handcrafted items that reflect traditional craftsmanship are also trending. Staying updated with these trends ensures your gifts are relevant and appreciated.",
        "open": false
    },
    {
        "question": "What are the benefits of customising dry fruit and sweet corporate gifts?",
        "answer": "Customising dry fruit and sweet corporate gifts adds a personal touch that enhances the gift's value and meaning. It shows that extra thought and effort have been put into selecting the gift. Personalisation can include engraving names, adding personal messages, or incorporating the company logo. Customised gifts are more memorable and appreciated by the recipients. They help in fostering stronger personal and corporate relationships.",
        "open": false
    },
    {
        "question": "How do dry fruit and sweet gifts benefit employee recognition programs?",
        "answer": "Dry fruit and sweet gifts benefit employee recognition programs by adding a touch of sweetness and appreciation to the recognition process. They make employees feel valued and appreciated, boosting morale and motivation. Customised dry fruit and sweet gifts reflect the company's commitment to recognising individual achievements. They enhance job satisfaction and loyalty, promoting a positive work environment. Dry fruit and lovely gifts are more impactful and memorable than generic awards.",
        "open": false
    },
    {
        "question": "Can dry fruit and sweet gifts be ordered in bulk?",
        "answer": "Yes, most gifting companies can order dry fruit and sweet gifts in bulk. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a gourmet gifting company can provide valuable insights and options for bulk orders.",
        "open": false
    },
    {
        "question": "What are some cost-effective dry fruit and sweet gift options?",
        "answer": "Cost-effective dry fruit and sweet gifts include branded dry fruit assortments, personalised sweet boxes, and festive dry fruit hampers. Consider premium dry fruits, gourmet sweets, and customised sweet gifts. The key is choosing gifts that reflect the recipient's preferences and the occasion without compromising quality. Cost-effective gifts can still make a significant impact when chosen thoughtfully.",
        "open": false
    },
    {
        "question": "Why is personalisation important in dry fruit and sweet corporate gifting?",
        "answer": "Personalisation is important in dry fruit and sweet corporate gifting because it adds a unique and meaningful touch to the gifts. It shows that the giver has put thought into selecting the gift, making it more memorable and appreciated. Personalised gifts reflect the recipient's tastes and preferences. They enhance the overall impact of the gift, fostering stronger relationships. Personalisation also helps in promoting brand recall and loyalty.",
        "open": false
    },
    {
        "question": "How can dry fruit and sweet gifts be used for client retention?",
        "answer": "Dry fruit and sweet gifts can be used to retain clients by showing appreciation and strengthening relationships. Customised gifts reflect thoughtfulness and attention to detail, making clients feel valued. They create a positive association with the brand, fostering loyalty. Regularly gifting dry fruit and sweet items can enhance client satisfaction and retention rates. These gifts are a constant reminder of the company's appreciation and recognition.",
        "open": false
    },
    {
        "question": "What are some good dry fruit and sweet gifts for employees?",
        "answer": "Good dry fruit and sweet gifts for employees include customised dry fruit boxes, branded sweet hampers, and premium mithai assortments. Consider festive sweet hampers, gourmet dry fruits, and personalised sweet gifts. These gifts reflect the company's appreciation and foster a positive work environment. They are ideal for employee recognition programs and corporate celebrations.",
        "open": false
    },
    {
        "question": "How can I choose the right dry fruits and sweets for corporate gifting?",
        "answer": "Choosing the right dry fruits and sweets for corporate gifting involves understanding the recipients' preferences and the occasion. Opt for high-quality items that reflect thoughtfulness and the festive spirit. Personalisation can add a special touch, making the gifts more meaningful. Consider the practicality and relevance of the gifts to ensure they are appreciated and used. Consulting a gourmet gifting company can provide valuable insights and recommendations.",
        "open": false
    },
    {
        "question": "What are the benefits of working with a gourmet corporate gifting company?",
        "answer": "Working with a gourmet corporate gifting company ensures you receive high-quality, well-crafted items that reflect the festive spirit and the company's appreciation. These companies offer various customisation options to make your gifts unique and personal. They provide expertise in selecting and designing gifts that suit your needs and preferences. Reliable delivery services and excellent customer support are additional benefits. Partnering with a reputable company ensures a seamless and satisfactory gifting experience.",
        "open": false
    },
    {
        "question": "Can I order dry fruits and sweets for corporate gifting in bulk?",
        "answer": "Yes, you can order dry fruits and sweets in bulk from various gifting companies for corporate gifting. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a gourmet gifting company can provide valuable insights and options for bulk orders.",
        "open": false
    },
    {
        "question": "What are some premium dry fruits and sweets gift options?",
        "answer": "Premium dry fruits and sweets gift options include bespoke dry fruit assortments, luxury sweet hampers, and high-end mithai boxes. Handcrafted items such as gourmet dry fruits, fine sweet confections, and personalised sweet gifts are also appreciated. Consider festive sweet hampers, luxury gift sets, and exclusive dry fruit assortments. These gifts reflect thoughtfulness and are ideal for premium corporate gifting.",
        "open": false
    },
    {
        "question": "How do dry fruits and sweets enhance corporate celebrations?",
        "answer": "Dry fruits and sweets enhance corporate celebrations by adding a touch of sweetness and appreciation. They are ideal for company anniversaries, client appreciation events, and employee recognition programs. These gifts reflect the company's commitment to quality and excellence. Customised dry fruit and sweet gifts make the celebration more memorable and meaningful. They foster a positive and respectful corporate culture.",
        "open": false
    },
    {
        "question": "What are some unique dry fruit and sweet gift ideas for corporate events?",
        "answer": "Unique dry fruit and sweet gift ideas for corporate events include bespoke dry fruit assortments, customised sweet boxes, and handcrafted sweet confections. High-end items such as luxury dry fruit gift sets, gourmet sweets, and fine mithai confections are also popular. Consider festive sweet hampers, personalised gifts, and exclusive dry fruit assortments. The key is to choose gifts that reflect the recipient's preferences and the event's significance.",
        "open": false
    },
    {
        "question": "Why should companies invest in branded dry fruit and sweet gifts?",
        "answer": "Companies should invest in branded dry fruit and sweet gifts to show appreciation and recognition for their employees, clients, and partners. These gifts enhance corporate relationships and convey a commitment to quality and excellence. Branded dry fruit and sweet gifts are highly valued and leave a lasting impression on the recipients. They also foster a positive and respectful corporate culture. Investing in branded gifts promotes a strong and prestigious brand image.",
        "open": false
    },
    {
        "question": "How can I ensure the quality of dry fruits and sweets for corporate gifting?",
        "answer": "Ensuring the quality of dry fruits and sweets for corporate gifting involves selecting reputable gifting companies and suppliers. Check reviews and testimonials to gauge the reliability and quality of their products. Opt for companies with a proven track record of high-quality craftsmanship and customisation. Regularly review samples and provide clear specifications for the customisation. Partnering with a trusted gourmet gifting company ensures the quality and satisfaction of your gifts.",
        "open": false
    },
    {
        "question": "What are the latest trends in dry fruit and sweet corporate gifting?",
        "answer": "The latest trends in dry fruit and sweet corporate gifting include eco-friendly products, gourmet dry fruits, and bespoke sweet gift sets. Sustainable and environmentally friendly items are increasingly popular. Personalised dry fruit and sweet gifts such as custom mithai boxes and engraved dry fruit hampers are in demand. Handcrafted items that reflect traditional craftsmanship are also trending. Staying updated with these trends ensures your gifts are relevant and appreciated.",
        "open": false
    },
    {
        "question": "What are the benefits of customising dry fruit and sweet corporate gifts?",
        "answer": "Customising dry fruit and sweet corporate gifts adds a personal touch that enhances the gift's value and meaning. It shows that extra thought and effort have been put into selecting the gift. Personalisation can include engraving names, adding personal messages, or incorporating the company logo. Customised gifts are more memorable and appreciated by the recipients. They help in fostering stronger personal and corporate relationships.",
        "open": false
    },
    {
        "question": "How do dry fruit and sweet gifts benefit employee recognition programs?",
        "answer": "Dry fruit and sweet gifts benefit employee recognition programs by adding a touch of sweetness and appreciation to the recognition process. They make employees feel valued and appreciated, boosting morale and motivation. Customised dry fruit and sweet gifts reflect the company's commitment to recognising individual achievements. They enhance job satisfaction and loyalty, promoting a positive work environment. Dry fruit and lovely gifts are more impactful and memorable than generic awards.",
        "open": false
    },
    {
        "question": "Can dry fruit and sweet gifts be ordered in bulk?",
        "answer": "Yes, most gifting companies can order dry fruit and sweet gifts in bulk. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a gourmet gifting company can provide valuable insights and options for bulk orders.",
        "open": false
    },
    {
        "question": "What are some cost-effective dry fruit and sweet gift options?",
        "answer": "Cost-effective dry fruit and sweet gifts include branded dry fruit assortments, personalised sweet boxes, and festive dry fruit hampers. Consider premium dry fruits, gourmet sweets, and customised sweet gifts. The key is choosing gifts that reflect the recipient's preferences and the occasion without compromising quality. Cost-effective gifts can still make a significant impact when chosen thoughtfully.",
        "open": false
    },
    {
        "question": "Why is personalisation important in dry fruit and sweet corporate gifting?",
        "answer": "Personalisation is important in dry fruit and sweet corporate gifting because it adds a unique and meaningful touch to the gifts. It shows that the giver has put thought into selecting the gift, making it more memorable and appreciated. Personalised gifts reflect the recipient's tastes and preferences. They enhance the overall impact of the gift, fostering stronger relationships. Personalisation also helps in promoting brand recall and loyalty.",
        "open": false
    },
    {
        "question": "How can dry fruit and sweet gifts be used for client retention?",
        "answer": "Dry fruit and sweet gifts can be used to retain clients by showing appreciation and strengthening relationships. Customised gifts reflect thoughtfulness and attention to detail, making clients feel valued. They create a positive association with the brand, fostering loyalty. Regularly gifting dry fruit and sweet items can enhance client satisfaction and retention rates. These gifts are a constant reminder of the company's appreciation and recognition.",
        "open": false
    },
    {
        "question": "What are some good dry fruit and sweet gifts for employees?",
        "answer": "Good dry fruit and sweet gifts for employees include customised dry fruit boxes, branded sweet hampers, and premium mithai assortments. Consider festive sweet hampers, gourmet dry fruits, and personalised sweet gifts. These gifts reflect the company's appreciation and foster a positive work environment. Customisation with the company's logo or festive branding adds a personal touch. Consulting a gourmet gifting company can help select the best gifts for employees.",
        "open": false
      },
      {
        "question": "How do dry fruit and sweet gifts enhance personal celebrations?",
        "answer": "Dry fruit and sweet gifts enhance personal celebrations by adding a touch of sweetness and appreciation to the festivities. They are ideal for birthdays, anniversaries, and festive events. These gifts reflect thoughtfulness and the festive spirit, making the celebration more memorable and meaningful. Customised dry fruit and sweet gifts show appreciation for the recipients and foster stronger personal connections.",
        "open": false
      },
      {
        "question": "What are some unique dry fruit and sweet gift ideas for corporate events?",
        "answer": "Unique dry fruit and sweet gift ideas for corporate events include bespoke dry fruit assortments, customised sweet boxes, and handcrafted sweet confections. High-end items such as luxury dry fruit gift sets, gourmet sweets, and fine mithai confections are also popular. Consider festive sweet hampers, personalised gifts, and exclusive dry fruit assortments. The key is to choose gifts that reflect the recipient's preferences and the event's significance.",
        "open": false
      },
      {
        "question": "How can I ensure the delivery of dry fruit and sweet corporate gifts?",
        "answer": "Ensuring timely delivery of dry fruit and sweet corporate gifts involves planning and ordering in advance. Choose a reputable gifting company with a proven track record of reliable delivery services. Provide clear specifications and deadlines to the vendor. Regularly follow up with the vendor to track the progress of your order. Partnering with a trusted gourmet gifting company ensures timely and hassle-free delivery.",
        "open": false
      },
      {
        "question": "What are some popular dry fruit and sweet gifts for clients?",
        "answer": "Popular dry fruit and sweet gifts for clients include luxury dry fruit hampers, branded sweet assortments, and personalised sweet boxes. Handcrafted items like gourmet dry fruits, festive sweet hampers, and bespoke sweet gift sets are also appreciated. Consider high-end sweets, fine mithai confections, and exclusive dry fruit assortments. These gifts reflect the company's appreciation and foster a positive business relationship.",
        "open": false
      },
      {
        "question": "What are some cost-effective dry fruit and sweet gift options for small businesses?",
        "answer": "Small businesses' cost-effective dry fruit and sweet gift options include branded dry fruit assortments, personalised sweet boxes, and festive dry fruit hampers. Consider premium dry fruits, gourmet sweets, and customised sweet gifts. The key is choosing gifts that reflect the recipient's preferences and the occasion without compromising quality. Cost-effective gifts can still make a significant impact when chosen thoughtfully.",
        "open": false
      },
      {
        "question": "How do dry fruit and sweet gifts enhance client relationships?",
        "answer": "Dry fruit and sweet gifts enhance client relationships by showing appreciation and fostering goodwill. Thoughtful and well-chosen gifts reflect the company's gratitude for the client's support and partnership. Regular gifting dry fruit and sweet items helps maintain and strengthen client relationships, promoting long-term business success. Customised gifts with the company logo or personal messages add a special touch, making the gifts more memorable.",
        "open": false
      },
      {
        "question": "What are some premium dry fruit and sweet gifts for senior executives?",
        "answer": "Premium dry fruit and sweet gifts for senior executives include bespoke dry fruit assortments, luxury sweet hampers, and high-end mithai boxes. Handcrafted items such as gourmet dry fruits, fine sweet confections, and personalised sweet gifts are also appreciated. Consider festive sweet hampers, luxury gift sets, and exclusive dry fruit assortments. The key is to choose gifts that reflect the festive spirit and the executive's preferences.",
        "open": false
      },
      {
        "question": "Why is eco-friendly gifting becoming popular in dry fruit and sweet corporate gifting?",
        "answer": "Eco-friendly dry fruit and sweet corporate gifting is becoming popular because they reflect a commitment to sustainability and environmental responsibility. Eco-friendly gifts are made from sustainable materials and processes, reducing the environmental impact. Recipients who value sustainability highly appreciate these gifts. Choosing eco-friendly dry fruit and sweet gifts also enhances the company's socially responsible and environmentally conscious image.",
        "open": false
      },
      {
        "question": "How do I choose the best vendor for dry fruit and sweet gifts?",
        "answer": "Choosing the best dry fruit and sweet gift vendor involves researching reputable gifting companies and suppliers. Check reviews, testimonials, and portfolios to gauge their products' and services' quality and reliability. Opt for vendors with a proven track record of high-quality craftsmanship and customisation. Request samples and provide clear specifications for the customisation. Partnering with a trusted gourmet gifting company ensures a seamless and satisfactory gifting experience.",
        "open": false
      },
      {
        "question": "What are some innovative dry fruit and sweet gift ideas for tech-savvy employees?",
        "answer": "Innovative dry fruit and sweet gift ideas for tech-savvy employees include personalised tech gadgets with gourmet dry fruits, branded tech accessories with sweet assortments, and smart home devices paired with luxury dry fruit hampers. Consider wireless chargers with custom dry fruit boxes, Bluetooth speakers with gourmet sweets, and smartwatches with bespoke dry fruit assortments. Customising these items with the company logo or personal messages adds a special touch.",
        "open": false
      },
      {
        "question": "How do dry fruit and sweet gifts enhance corporate culture?",
        "answer": "Dry fruit and sweet gifts enhance corporate culture by showcasing the company's values and appreciation for its employees, clients, and partners. Thoughtful and well-chosen gifts demonstrate the company's commitment to quality, inclusivity, and goodwill. Customisation with the company logo or branding elements enhances the corporate identity. Dry fruit and sweet gifts that reflect the company's culture foster a positive and respectful work environment.",
        "open": false
      },
      {
        "question": "What are some good dry fruit and sweet gifts for clients under 5000?",
        "answer": "Good dry fruit and sweet gifts for clients under 5000 include bespoke dry fruit assortments, custom-designed sweet boxes, and personalised plaques. Consider items like engraved medals, stylish corporate trophies, and contemporary trophies. The key is choosing gifts that reflect the recipient's achievements and the event's significance without compromising quality. Consulting a trophy and awards gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do dry fruit and sweet gifts promote corporate social responsibility?",
        "answer": "Dry fruit and sweet gifts promote corporate social responsibility by reflecting the company's commitment to sustainability and community engagement. Recipients highly appreciate eco-friendly and socially responsible gifts, which enhance the company's image as socially responsible and environmentally conscious. Choosing gifts that support local artisans and charitable causes also promotes corporate social responsibility.",
        "open": false
      },
      {
        "question": "What are some unique dry fruit and sweet gift ideas for startups?",
        "answer": "Unique dry fruit and sweet gift ideas for startups include: Branded tech gadgets with gourmet dry fruits. Customised sweet boxes with personalised messages. Luxury dry fruit hampers with startup branding. Consider wireless chargers with custom dry fruit boxes, personalised notebooks with gourmet sweets, and premium dry fruit assortments with startup logos. The key is to choose gifts that reflect the festive spirit and the startup's innovative culture.",
        "open": false
      },
      {
        "question": "How can dry fruit and sweet gifts be used for marketing and promotion?",
        "answer": "Dry fruit and sweet gifts can be used for marketing and promotion by enhancing brand visibility and recall. Customising gifts with the company logo or branding elements increases brand awareness. Thoughtful and well-chosen dry fruit and sweet gifts create positive associations with the brand and foster goodwill. Regular gifting of these items helps maintain and strengthen business relationships, promoting long-term success.",
        "open": false
      },
      {
        "question": "What are some good dry fruit and sweet gifts for employees under 1000?",
        "answer": "Good dry fruit and sweet gifts for employees under 1000 include personalised dry fruit boxes, branded sweet assortments, and festive dry fruit hampers. Consider premium dry fruits, gourmet sweets, and customised sweet gifts. The key is choosing gifts that reflect the festive spirit and the company's appreciation without compromising quality. Consulting a gourmet gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do dry fruit and sweet gifts enhance client retention?",
        "answer": "Dry fruit and sweet gifts enhance client retention by showing appreciation and fostering goodwill. Thoughtful and well-chosen gifts reflect the company's gratitude for the client's support and partnership. Regular gifting dry fruit and sweet items helps maintain and strengthen client relationships, promoting long-term business success. Customised gifts with the company logo or personal messages add a special touch, making the gifts more memorable.",
        "open": false
      },
      {
        "question": "What are some unique dry fruit and sweet gift ideas for corporate events?",
        "answer": "Unique dry fruit and sweet gift ideas for corporate events include bespoke dry fruit assortments, customised sweet boxes, and handcrafted sweet confections. High-end items such as luxury dry fruit gift sets, gourmet sweets, and fine mithai confections are also popular. Consider festive sweet hampers, personalised gifts, and exclusive dry fruit assortments. The key is to choose gifts that reflect the recipient's preferences and the event's significance.",
        "open": false
      },
      {
        "question": "What are some eco-friendly dry fruit and sweet gift options for corporate gifting?",
        "answer": "Eco-friendly dry fruit and sweet gift options for corporate gifting include gifts made from sustainable materials, recycled packaging, and organic ingredients. Consider items like reusable containers, biodegradable packaging, and ethically sourced dry fruits and sweets. The key is to choose gifts that reflect the company's commitment to sustainability and environmental responsibility. Consulting an eco-friendly gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do dry fruit and sweet gifts enhance employee recognition?",
        "answer": "Dry fruit and sweet gifts enhance employee recognition by showcasing the company's appreciation for its employees' efforts and achievements. Thoughtful and well-chosen gifts reflect the company's commitment to quality, inclusivity, and goodwill. Customisation with the company logo or branding elements enhances the corporate identity. Dry fruit and sweet gifts that reflect the company's values and employee achievements foster a positive and respectful work environment.",
        "open": false
      },
      {
        "question": "What are some good dry fruit and sweet gifts for employees under 5000?",
        "answer": "Good dry fruit and sweet gifts for employees under 5000 include bespoke dry fruit assortments, custom-designed sweet boxes, and personalised sweet gifts. Consider items like gourmet dry fruit hampers, luxury sweet sets, and exclusive dry fruit assortments. The key is choosing gifts that reflect the recipient's achievements and the event's significance without compromising quality. Consulting a gourmet gifting company can provide additional ideas and options.",
        "open": false
      }
]

 
return (
<>
<MetaTags
        title="Premier Corporate Gifting Company for Dry Fruits and Sweets in India"
        description="Discover exquisite dry fruits and sweets for corporate gifting from India's leading gourmet gifting company. They are perfect for employees and clients."
        keywords="Sweets & Dry Fruits Gifting, Sweets and dry fruit bulk gifting, Sweets bulk gifting companies in India, Premium sweets hamper companies, Buy premium mithai, sweet boxes, & luxury gift hampers, Gourmet gifting company, Gourmet corporate gifting company, Gourmet gifting company in India, Sweets for corporate gifting, Vendor for sweets corporate gifting, Customised dry fruit gifting vendor, Customised dry fruit gifting, Dry fruit bulk gifting, Dry fruit gifting ideas, Dry fruit gifting vendor, Dry fruit gifting company, Corporate dry fruit gift box"
      />
<ServiceHeader title="Dry Fruits and Sweets" imageUrl={`${process.env.PUBLIC_URL}/images/dryswetbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Delight of Corporate Gifting with Dry Fruits and Sweets</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Corporate gifting with dry fruits and sweets is a delightful way to show appreciation and strengthen business relationships. Whether it's for employees, clients, or partners, these gifts add a touch of sweetness and luxury to any occasion. From premium mithai and sweet boxes to luxury dry fruit gift hampers, these gifts are perfect for festivals, corporate events, and special celebrations.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Leading sweets and dry fruits gifting companies in India offer various options to cater to different tastes and preferences. From bulk gifting to customised hampers, these companies ensure that every gift is special. Dry fruits and sweets for corporate gifting are particularly popular as they reflect the company's appreciation and foster a positive work environment. Customisation and branding make these gifts even more impactful.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Choosing the perfect corporate gift involves understanding the recipient's preferences and the occasion. Here are some key aspects that make sweets and dry fruits gifting a popular choice:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances the spirit of celebrations</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthens business relationships</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflects appreciation and gratitude</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for various types of recipients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers a wide range of customisation options</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Ideal for both employees and clients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Available in premium and luxury options</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Can be ordered in bulk for corporate needs</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes lasting memories and connections</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Supports local artisans and craftsmanship</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Corporate gifting with dry fruits and sweets is more than just a gesture; it celebrates relationships and goodwill.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Corporate Gifting with Dry Fruits and Sweets?</h2>
      <p >Selecting the perfect corporate gifts with dry fruits and sweets requires a keen understanding of quality, taste, and customisation. Giftingy is a premier gourmet gifting company offering a curated selection of the finest sweets and dry fruit hampers. Here's why Giftingy should be your top choice for corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/dryswetimg2.jpg`} alt="Best Trophy Designs" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialise in creating gourmet corporate gifts that resonate with the recipient and reflect the company's appreciation. Our corporate gift ideas include beautifully crafted items, from premium mithai and sweet boxes to luxury dry fruit hampers. Whether you are looking for sweets for corporate gifting or customised dry fruit gifts, our collection caters to diverse tastes and preferences.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of our key strengths is our ability to offer customised dry fruit and sweets gifts that reflect the recipient's preferences and the occasion. We pride ourselves on being a leading gourmet corporate gifting company that delivers excellence in craftsmanship. Our products are sourced from the best vendors, ensuring that every item meets the highest standards of quality and taste.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy specialises in bulk gifting, making us the perfect partner for corporate celebrations. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From unique dry fruit gifting ideas to premium sweet hampers, we offer various choices to cater to your needs. Our commitment to customer satisfaction and ability to create lasting impressions make us the preferred choice for corporate gifting with dry fruits and sweets in India.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable dry fruit and sweets gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products with meticulous craftsmanship
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of gifting ideas for various recipients
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as a gourmet corporate gifting company in India
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Convenient online ordering and customisation options
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive selection of beautifully crafted gifts
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your corporate gifting needs ensures that your gifts are delicious, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exceptional Corporate Gifting with Dry Fruits and Sweets</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional corporate gifting solutions with dry fruits and sweets catering to your needs and preferences. Our unique selling propositions (USPs) set us apart as India's leading gourmet corporate gifting company. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your dry fruits and sweets gifts, making them more meaningful and memorable. Our branding on gifts ensures that your company remains at the forefront of the recipient's mind, enhancing brand recall and visibility. We provide value for money by offering high-quality gifts that fit within your budget, ensuring you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/dryswetimg3.jpg`} alt="Branded Medals & Trophies" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="luxuryHomeDecor" />

<FaqPage faqs={faqs} />
</>
);
};
export default DryFruitsAndSweets;