// MetaTag.js
import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTag = ({ title, description, name, keywords, site }) => {
  return (
    <Helmet>
      {/* Meta tags for the document head */}
      <title>{title}</title> {/* Adding meta title */}
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} /> {/* Adding meta keywords */}
      <meta itemprop="name" content={name} />
      <meta itemprop="description" content={description} />
      <meta property="og:url" content={site} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
};

export default MetaTag;
