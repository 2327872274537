import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/kartikayimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalized Kartikay Dibam gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality festive gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const KartikayDibamGifting = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
      }, []);
const faqs = [
    {
      "question": "What are good Kartikay Dibam corporate gifts for employees?",
      "answer": "Good Kartikay Dibam corporate gifts for employees include traditional festive hampers, premium gift boxes, and personalized cultural items. Popular choices are handcrafted items, gourmet sweets, and custom festive accessories. The key is to choose gifts that reflect the recipient's preferences and the cultural significance of the festival. Consulting a Kartikay Dibam corporate gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "Why is corporate gifting during Kartikay Dibam important?",
      "answer": "Corporate gifting during Kartikay Dibam is important because it expresses appreciation and enhances the festive spirit. These gifts strengthen business relationships and foster a positive environment among employees, clients, and partners. Thoughtful and well-chosen festive gifts reflect the company’s appreciation and commitment to cultural values. They also promote a positive work environment and enhance brand visibility.",
      "open": false
    },
    {
      "question": "What are some popular Kartikay Dibam gifts for clients?",
      "answer": "Popular Kartikay Dibam gifts for clients include traditional festive hampers, customized gift boxes, and personalized cultural items. Handcrafted items such as gourmet sweets, premium festive accessories, and custom gift sets are also appreciated. Consider items like elegant Kartikay Dibam hampers, high-end festive gifts, and bespoke accessories. These gifts reflect the company’s appreciation and foster a positive business relationship.",
      "open": false
    },
    {
      "question": "How do I choose the right Kartikay Dibam gifts for corporate gifting?",
      "answer": "Choosing the right Kartikay Dibam gifts for corporate gifting involves understanding the recipient's preferences and the cultural significance of the occasion. Opt for high-quality items that reflect thoughtfulness and festive cheer. Personalization can add a special touch, making the gifts more meaningful. Consider the practicality and relevance of the gifts to ensure they are appreciated and used. Consulting a corporate gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "What are the benefits of working with a Kartikay Dibam corporate gifting company?",
      "answer": "Working with a Kartikay Dibam corporate gifting company ensures you receive high-quality, well-crafted items that reflect the cultural significance and the company’s appreciation. These companies offer a wide range of customization options to make your gifts unique and personal. They provide expertise in selecting and designing gifts that suit your needs and preferences. Reliable delivery services and excellent customer support are additional benefits. Partnering with a reputable company ensures a seamless and satisfactory gifting experience.",
      "open": false
    },
    {
      "question": "Can I order Kartikay Dibam gifts for corporate gifting in bulk?",
      "answer": "Yes, you can order Kartikay Dibam gifts for corporate gifting in bulk from various gifting companies. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a corporate gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some premium Kartikay Dibam gift options?",
      "answer": "Premium Kartikay Dibam gift options include bespoke festive hampers, luxury gift boxes, and high-end cultural items. Handcrafted items such as elegant festive accessories, fine cultural artifacts, and personalized gift sets are also appreciated. Consider items like premium Kartikay Dibam hampers, luxury gifts, and exclusive festive accessories. These gifts reflect thoughtfulness and are ideal for premium corporate gifting.",
      "open": false
    },
    {
      "question": "How do Kartikay Dibam gifts enhance corporate celebrations?",
      "answer": "Kartikay Dibam gifts enhance corporate celebrations by adding a touch of cultural significance and appreciation. They are ideal for occasions such as company anniversaries, client appreciation events, and employee recognition programs. These gifts reflect the company’s commitment to cultural values and festive cheer. Customised festive gifts make the celebration more memorable and meaningful. They foster a positive and respectful corporate culture.",
      "open": false
    },
    {
      "question": "What are some unique Kartikay Dibam gift ideas for corporate events?",
      "answer": "Unique Kartikay Dibam gift ideas for corporate events include bespoke festive hampers, customized gift boxes, and handcrafted cultural items. High-end items such as luxury festive accessories, fine cultural artifacts, and personalized gift sets are also popular. Consider items like premium Kartikay Dibam hampers, luxury gifts, and exclusive festive accessories. The key is to choose gifts that reflect the recipient's preferences and the event's significance.",
      "open": false
    },
    {
      "question": "Why should companies invest in branded Kartikay Dibam gifts?",
      "answer": "Companies should invest in branded Kartikay Dibam gifts to show appreciation and celebrate cultural values among their employees, clients, and partners. These gifts enhance corporate relationships and convey a commitment to cultural appreciation and festive cheer. Branded festive gifts are highly valued and leave a lasting impression on the recipients. They also foster a positive and respectful corporate culture. Investing in branded gifts promotes a strong and prestigious brand image.",
      "open": false
    },
    {
      "question": "How can I ensure the quality of Kartikay Dibam gifts for corporate gifting?",
      "answer": "Ensuring the quality of Kartikay Dibam gifts for corporate gifting involves selecting reputable gifting companies and suppliers. Check reviews and testimonials to gauge the reliability and quality of their products. Opt for companies with a proven track record of high-quality craftsmanship and customization. Regularly review samples and provide clear specifications for the customization. Partnering with a trusted corporate gifting company ensures the quality and satisfaction of your gifts.",
      "open": false
    },
    {
      "question": "What are the latest trends in Kartikay Dibam corporate gifting?",
      "answer": "The latest trends in Kartikay Dibam corporate gifting include eco-friendly products, luxury festive hampers, and bespoke cultural gift sets. Sustainable and environmentally friendly items are increasingly popular. Personalized festive gifts such as custom gift boxes and engraved cultural items are in demand. Handcrafted items that reflect traditional craftsmanship are also trending. Staying updated with these trends ensures your gifts are relevant and appreciated.",
      "open": false
    },
    {
      "question": "What are the benefits of customising Kartikay Dibam corporate gifts?",
      "answer": "Customising Kartikay Dibam corporate gifts adds a personal touch that enhances the gift’s value and cultural significance. It shows that extra thought and effort have been put into selecting the gift. Personalization can include engraving names, adding personal messages, or incorporating the company logo. Customised gifts are more memorable and appreciated by the recipients. They help in fostering stronger personal and corporate relationships.",
      "open": false
    },
    {
      "question": "How do Kartikay Dibam gifts benefit employee recognition programs?",
      "answer": "Kartikay Dibam gifts benefit employee recognition programs by adding a touch of cultural significance and appreciation to the recognition process. They make employees feel valued and appreciated, boosting morale and motivation. Customised festive gifts reflect the company’s commitment to recognizing individual achievements. They enhance job satisfaction and loyalty, promoting a positive work environment. Festive gifts are more impactful and meaningful than generic awards.",
      "open": false
    },
    {
      "question": "Can Kartikay Dibam gifts be ordered in bulk?",
      "answer": "Yes, Kartikay Dibam gifts can be ordered in bulk from most gifting companies. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a corporate gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some cost-effective Kartikay Dibam gift options?",
      "answer": "Cost-effective Kartikay Dibam gift options include branded festive accessories, personalized cultural items, and festive hampers. Consider items like premium festive gift sets, custom cultural artifacts, and customized gift boxes. The key is to choose gifts that reflect the recipient's preferences and the occasion without compromising on quality. Cost-effective gifts can still make a significant impact when chosen thoughtfully.",
      "open": false
    },
    {
      "question": "Why is personalisation important in Kartikay Dibam corporate gifting?",
      "answer": "Personalisation is important in Kartikay Dibam corporate gifting because it adds a unique and meaningful touch to the gifts. It shows that the giver has put thought into selecting the gift, making it more memorable and appreciated. Personalised gifts reflect the recipient's preferences and the cultural significance of the festival. They enhance the overall impact of the gift, fostering stronger relationships. Personalisation also helps in promoting brand recall and loyalty.",
      "open": false
    },
    {
      "question": "How can Kartikay Dibam gifts be used for client retention?",
      "answer": "Kartikay Dibam gifts can be used for client retention by showing appreciation and celebrating cultural values. Customised gifts reflect thoughtfulness and attention to detail, making clients feel valued. They create a positive association with the brand, fostering loyalty. Regular gifting of festive items can enhance client satisfaction and retention rates. These gifts serve as a constant reminder of the company’s appreciation and cultural commitment.",
      "open": false
    },
    {
      "question": "What are some traditional Kartikay Dibam gifts for corporate occasions?",
      "answer": "Traditional Kartikay Dibam gifts for corporate occasions include festive hampers, handcrafted cultural items, and premium gift boxes. Popular choices are gourmet sweets, elegant cultural accessories, and custom festive gifts. These gifts reflect the cultural significance of the festival and convey appreciation and goodwill. They enhance corporate celebrations and strengthen business relationships.",
      "open": false
    },
    {
      "question": "How can Kartikay Dibam gifts be used to enhance brand visibility?",
      "answer": "Kartikay Dibam gifts can enhance brand visibility by incorporating the company logo and branding elements into the gifts. Customised gifts with branding create a lasting impression and reinforce brand recognition. High-quality, unique gifts are more likely to be remembered and used, increasing brand exposure. Thoughtful and well-chosen gifts reflect the company's commitment to cultural values and create a positive brand image.",
      "open": false
    },
    {
        "question": "What are some premium Kartikay Dibam gifts for senior executives?",
        "answer": "Premium Kartikay Dibam gifts for senior executives include bespoke festive accessories, luxury gift boxes, and high-end cultural items. Handcrafted items such as elegant festive artifacts, fine cultural accessories, and personalized gift sets are also appreciated. Consider items like festive hampers, luxury Kartikay Dibam gifts, and exclusive festive accessories. The key is to choose gifts that reflect the festive spirit and the executive's preferences.",
        "open": false
      },
      {
        "question": "Why is eco-friendly gifting becoming popular in Kartikay Dibam corporate gifting?",
        "answer": "Eco-friendly gifting is becoming popular in Kartikay Dibam corporate gifting because it reflects a commitment to sustainability and environmental responsibility. Eco-friendly gifts are made from sustainable materials and processes, reducing the environmental impact. These gifts are highly appreciated by recipients who value sustainability. Choosing eco-friendly festive gifts also enhances the company's image as socially responsible and environmentally conscious.",
        "open": false
      },
      {
        "question": "How do I choose the best vendor for Kartikay Dibam gifts?",
        "answer": "Choosing the best vendor for Kartikay Dibam gifts involves researching reputable gifting companies and suppliers. Check reviews, testimonials, and portfolios to gauge the quality and reliability of their products and services. Opt for vendors with a proven track record of high-quality craftsmanship and customisation. Request samples and provide clear specifications for the customisation. Partnering with a trusted corporate gifting company ensures a seamless and satisfactory gifting experience.",
        "open": false
      },
      {
        "question": "What are some innovative Kartikay Dibam gift ideas for tech-savvy employees?",
        "answer": "Innovative Kartikay Dibam gift ideas for tech-savvy employees include personalized tech gadgets with festive accessories, branded tech accessories with cultural items, and luxury tech devices paired with festive gifts. Consider items like wireless chargers with custom festive products, Bluetooth speakers with premium cultural accessories, and smartwatches with bespoke festive gifts. Customising these items with the company logo or personal messages adds a special touch.",
        "open": false
      },
      {
        "question": "How do Kartikay Dibam gifts enhance corporate culture?",
        "answer": "Kartikay Dibam gifts enhance corporate culture by showcasing the company's values and appreciation for its employees, clients, and partners. Thoughtful and well-chosen gifts demonstrate the company's commitment to cultural values, inclusivity, and festive cheer. Customisation with the company logo or branding elements enhances the corporate identity. Festive gifts that reflect the company's culture foster a positive and respectful work environment.",
        "open": false
      },
      {
        "question": "What are some good Kartikay Dibam gifts for clients under 5000?",
        "answer": "Good Kartikay Dibam gifts for clients under 5000 include bespoke festive accessories, custom-designed cultural items, and personalised gift boxes. Consider items like engraved festive gifts, stylish cultural artifacts, and contemporary festive sets. The key is to choose gifts that reflect the recipient's preferences and the occasion without compromising on quality. Consulting a corporate gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do Kartikay Dibam gifts promote corporate social responsibility?",
        "answer": "Kartikay Dibam gifts promote corporate social responsibility by reflecting the company's commitment to sustainability and community engagement. Eco-friendly and socially responsible festive gifts are highly appreciated by recipients. These gifts enhance the company's image as socially responsible and environmentally conscious. Choosing festive gifts that support local artisans and charitable causes also promotes corporate social responsibility.",
        "open": false
      },
      {
        "question": "What are some unique Kartikay Dibam gift ideas for startups?",
        "answer": "Unique Kartikay Dibam gift ideas for startups include branded tech gadgets with festive accessories, personalised cultural items with startup branding, and luxury tech devices paired with festive gifts. Consider items like wireless chargers with custom festive products, personalised notebooks with engraved cultural accessories, and premium festive gifts with startup logos. The key is to choose gifts that reflect the festive spirit and the startup's innovative culture.",
        "open": false
      },
      {
        "question": "How can Kartikay Dibam gifts be used for marketing and promotion?",
        "answer": "Kartikay Dibam gifts can be used for marketing and promotion by enhancing brand visibility and recall. Customising gifts with the company logo or branding elements increases brand awareness. Thoughtful and well-chosen festive gifts create positive associations with the brand and foster goodwill. Regular gifting of festive items helps maintain and strengthen business relationships, promoting long-term success.",
        "open": false
      },
      {
        "question": "What are some good Kartikay Dibam gifts for employees under 1000?",
        "answer": "Good Kartikay Dibam gifts for employees under 1000 include personalised festive accessories, branded cultural items, and festive hampers. Consider items like premium festive gift sets, custom cultural artifacts, and customised gift boxes. The key is to choose gifts that reflect the festive spirit and the company's appreciation without compromising on quality. Consulting a corporate gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do Kartikay Dibam gifts enhance client retention?",
        "answer": "Kartikay Dibam gifts enhance client retention by showing appreciation and fostering goodwill. Thoughtful and well-chosen gifts reflect the company's gratitude for the client's support and partnership. Regular gifting of festive items helps maintain and strengthen client relationships, promoting long-term business success. Customised gifts with the company logo or personal messages add a special touch, making the gifts more memorable.",
        "open": false
      },
      {
        "question": "What are some unique Kartikay Dibam gift ideas for corporate events?",
        "answer": "Unique Kartikay Dibam gift ideas for corporate events include bespoke festive accessories, customised gift boxes, and handcrafted cultural items. High-end items such as luxury festive accessories, fine cultural artifacts, and personalized gift sets are also popular. Consider items like premium Kartikay Dibam hampers, luxury gifts, and exclusive festive accessories. The key is to choose gifts that reflect the recipient's preferences and the event's significance.",
        "open": false
      },
      {
        "question": "How do Kartikay Dibam gifts benefit employee engagement programs?",
        "answer": "Kartikay Dibam gifts benefit employee engagement programs by showing appreciation and recognition for employees' efforts and contributions. Thoughtful and well-chosen gifts enhance job satisfaction and motivation. Customised gifts with the company logo or personal messages add a special touch. Regular gifting of festive items during corporate events fosters a positive work environment and strengthens employee engagement.",
        "open": false
      },
      {
        "question": "What are some good Kartikay Dibam gifts for clients under 3000?",
        "answer": "Good Kartikay Dibam gifts for clients under 3000 include luxury festive gift boxes, bespoke cultural items, and personalised gift sets. Consider items like high-end cultural artifacts, fine festive accessories, and exclusive gift boxes. The key is to choose gifts that reflect the recipient's preferences and the occasion without compromising on quality. Consulting a corporate gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How can Kartikay Dibam gifts be used for team building?",
        "answer": "Kartikay Dibam gifts can be used for team building by showing appreciation and fostering goodwill among team members. Thoughtful and well-chosen gifts reflect the company's gratitude for the team's efforts and contributions. Regular gifting of festive items during team-building activities helps maintain and strengthen team cohesion, promoting a positive and collaborative work environment. Customised gifts with the company logo or personal messages add a special touch, making the gifts more meaningful.",
        "open": false
      }
  ]
  
   
return (
<>
<MetaTags
        title="Premier Kartikay Dibam Corporate Gifting Company in India"
        description="Discover exquisite Kartikay Dibam corporate gifts from the leading corporate gifting company in India. Perfect for employees, clients, and corporate events."
        keywords="Kartikay Dibam corporate gifts for employees, Kartikay Dibam corporate gifts company, Kartikay Dibam corporate gifts for clients, Kartikay Dibam corporate gifting, Kartikay Dibam corporate gift ideas, Kartikay Dibam corporate gift vendors, Kartikay Dibam corporate gift suppliers, Kartikay Dibam corporate gift suppliers in India, Kartikay Dibam gifting company, Kartikay Dibam gifting suppliers"
      />
<ServiceHeader title="Kartikay Dibam Corporate Gifting" imageUrl={`${process.env.PUBLIC_URL}/images/kartikaybn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Essence of Kartikay Dibam Corporate Gifting</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Kartikay Dibam, also known as Kartik Purnima, is a significant Hindu festival celebrated on the full moon day of the Kartik month. This auspicious festival is associated with the worship of Lord Kartikeya and marks the end of the Diwali festivities. Kartikay Dibam corporate gifting is an excellent opportunity to show appreciation, celebrate cultural heritage, and strengthen business relationships.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Corporate gifts during Kartikay Dibam should be thoughtful, culturally significant, and reflective of the festive spirit. From traditional gifts to unique corporate gifts for Kartikay Dibam, the options are extensive. These gifts can range from Kartikay Dibam corporate gifts for employees to elegant gifts for clients. By choosing the right corporate gifts, you can enhance the festive cheer and create lasting memories.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Here are some key reasons why Kartikay Dibam corporate gifting is important:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Show appreciation and recognition</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Celebrate cultural heritage and traditions</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Foster a positive work environment</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthen professional relationships</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhance company culture and values</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promote brand loyalty and visibility</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Encourage motivation and productivity</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Create lasting memories and connections</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Support employees and clients in celebrating the festival</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflect the company's commitment to cultural inclusivity</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Kartikay Dibam corporate gifting is more than just presents; it’s a celebration of culture, tradition, and goodwill.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Kartikay Dibam Corporate Gifting?</h2>
      <p >Selecting the perfect Kartikay Dibam corporate gifts requires a keen understanding of quality, cultural significance, and customization. Giftingy stands out as a premier Kartikay Dibam corporate gifting company, offering a curated selection of the finest gifts for this festive occasion. Here’s why Giftingy should be your top choice for Kartikay Dibam corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/kartikayimg3.jpg`} alt="Premium Kartikay Dibam Gifts for Employees" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialize in creating corporate gifts that resonate with the recipient and reflect the company’s appreciation and festive spirit. Our range of corporate gift ideas includes beautifully crafted items, from traditional festive hampers to contemporary gift boxes. Whether you are looking for gifts for employees, clients, or corporate events, our collection caters to diverse tastes and preferences.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of our key strengths is our ability to offer personalized gifts that reflect the recipient’s preferences and the occasion. We pride ourselves on being a leading Kartikay Dibam corporate gifting company that delivers excellence in craftsmanship. Our products are sourced from the best vendors, ensuring that every item meets the highest standards of quality and cultural significance.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy also specializes in bulk gifting, making us the perfect partner for corporate celebrations. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From unique Kartikay Dibam gifting ideas to premium festive hampers, we offer a wide range of choices to cater to your specific needs. Our commitment to customer satisfaction and our ability to create lasting impressions make us the preferred choice for Kartikay Dibam corporate gifting in India.

               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customizable festive gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products with meticulous craftsmanship
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of gifting ideas for various recipients

            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as a Kartikay Dibam corporate gifting company in India
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Convenient online ordering and customization options
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive selection of beautifully crafted festive gifts
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your corporate gifting needs ensures that your gifts are not only culturally significant but also meaningful and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exceptional Kartikay Dibam Corporate Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional Kartikay Dibam corporate gifting solutions that cater to your specific needs and preferences. Our unique selling propositions (USPs) set us apart as a leading corporate gifting company in India. Here’s what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your Kartikay Dibam gifts, making them more meaningful and culturally significant. Our branding on gifts ensures that your company remains at the forefront of the recipient’s mind, enhancing brand recall and visibility. We provide value for money by offering high-quality gifts that fit within your budget, ensuring that you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/kartikayimg3.jpg`} alt="Best Kartikay Dibam Gift Ideas" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="KartikayGifting" />

<FaqPage faqs={faqs} />
</>
);
};
export default KartikayDibamGifting;