import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/newoinimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised onboarding kits tailored to your company's values." },
    { title: "Branding on Gifts", detail: "Reinforce your brand identity with branded merchandise." },
    { title: "Value for Money", detail: "High-quality onboarding kits that fit your budget." },
    { title: "Concept Creation", detail: "Innovative and thoughtful kit designs based on your needs." },
    { title: "Diverse Range of Gifts", detail: "Choose from a wide selection of essentials and branded items." },
    { title: "Quality Assurance", detail: "Each kit is carefully crafted to meet the highest standards." },
    { title: "Timely Delivery", detail: "Reliable service ensuring that kits are ready when needed." },
    { title: "Eco-Friendly Options", detail: "Eco-friendly products for companies focused on sustainability." },
];
const OnboardingEmployeeKit = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
      }, []);
const faqs = [
    
        {
            "question": "What should be included in an Onboarding Employee Kit?",
            "answer": "An ideal Onboarding Employee Kit should include practical items and branded merchandise that reflects your company's culture. Essentials like notebooks, pens, and tech gadgets are commonly included. Branded apparel, water bottles, and welcome notes add a personal touch. The aim is to provide new employees with the tools to start their jobs comfortably while making them feel valued and appreciated. Giftingy offers fully customised Employee Welcome Kits that cater to different needs and preferences.",
            "open": false
        },
        {
            "question": "Why is an Employee Welcome Kit important for onboarding?",
            "answer": "An Employee Welcome Kit is important for onboarding because it immediately sets the tone for a positive experience. It helps new employees feel appreciated and valued, creating a sense of belonging. The kit is both a practical resource and a thoughtful gesture, easing the transition into the new role. Giftingy's New Employee Welcome Gift Sets are designed to leave a lasting impression and boost employee engagement from day one.",
            "open": false
        },
        {
            "question": "What are the best items for a New Joinee Kit?",
            "answer": "The best items for a New Joinee Kit include branded stationery, tech gadgets like USB drives or headphones, and office essentials like planners and water bottles. Personalised items like notebooks with the employee's name or branded apparel are also popular. Giftingy offers a range of high-quality, customisable products that make your New Joinee Kit both functional and memorable.",
            "open": false
        },
        {
            "question": "How can I customise an Onboarding Employee Kit?",
            "answer": "Customising an Onboarding Employee Kit involves selecting items that align with your brand and adding personal touches like the employee's name or a welcome note. Giftingy allows you to choose from various products and customise them with your company's logo, colours, and messaging. Our team can help design a kit that reflects your company's culture while meeting the practical needs of new hires.",
            "open": false
        },
        {
            "question": "How does a Workplace Welcome Kit enhance employee engagement?",
            "answer": "A Workplace Welcome Kit enhances employee engagement by making new hires feel valued and appreciated. Employees receiving a thoughtful kit filled with practical items and personalised gifts shows that the company is invested in their success and well-being. This positive first impression helps build trust and encourages a sense of loyalty towards the company. Giftingy's customised Employee Welcome Kits are designed to reflect your brand's culture and make new hires feel excited and motivated as they start their journey.",
            "open": false
        },
        {
            "question": "What is the significance of personalisation in New Joinee Kits?",
            "answer": "Personalisation in New Joinee Kits adds a unique and memorable touch that resonates with employees. Personalised items like engraved pens, notebooks with the employee's name, or custom apparel make the kit feel more special and thoughtful. Personalisation enhances the overall experience and strengthens the emotional connection between the employee and the company. Giftingy specialises in creating personalised Employee Welcome Kits that make new hires feel welcomed and valued.",
            "open": false
        },
        {
            "question": "How do Onboarding Kits contribute to a positive company culture?",
            "answer": "Onboarding Kits contribute to a positive company culture by promoting unity, belonging, and shared values. When new hires receive a well-curated kit that includes branded merchandise and useful resources, it reinforces the company's commitment to its employees and creates a welcoming environment. Giftingy's Workplace Welcome Kits reflect your company's culture and help new employees align with your brand's values.",
            "open": false
        },
        {
            "question": "Can Onboarding Employee Kits be customised for different roles and levels?",
            "answer": "Yes, Onboarding Employee Kits can be customised for different organisational roles and levels. For example, senior executives might receive more premium items such as luxury stationery or tech gadgets, while entry-level employees could receive essential office supplies and branded merchandise. Giftingy offers flexible customisation options to create tailored kits that suit each role's specific needs and preferences, ensuring that every new hire feels valued and appreciated.",
            "open": false
        },
        {
            "question": "What are some cost-effective options for New Joinee Kits?",
            "answer": "Cost-effective options for New Joinee Kits include essential items like branded notebooks, pens, and water bottles. Adding a few personalised touches, such as a welcome note or customised keychain, can make the kit feel special without significantly increasing costs. Giftingy provides affordable yet high-quality products that can be customised to create impactful welcome kits that fit your budget.",
            "open": false
        },
        {
            "question": "How can Onboarding Kits be used to reinforce company branding?",
            "answer": "Onboarding Kits can reinforce company branding by including items that prominently feature the company's logo, colours, and messaging. Branded merchandise such as apparel, stationery, and tech accessories serve a practical purpose and help new employees feel more connected to the brand. Giftingy specialises in creating customised Employee Welcome Kits that effectively communicate your brand's identity while enhancing the onboarding experience.",
            "open": false
        },
        {
            "question": "What should be included in a Luxury Employee Welcome Kit?",
            "answer": "A luxury Employee Welcome Kit could include premium items like high-end tech gadgets, stationery, branded apparel, and wellness products. Personalised gifts such as engraved pens or custom leather accessories can add an extra touch of elegance. Giftingy offers a range of luxury options for Onboarding Employee Kits that are perfect for making a strong impression on senior executives or key hires.",
            "open": false
        },
        {
            "question": "How do onboarding kits improve employee retention?",
            "answer": "Onboarding kits improve employee retention by making new hires feel valued and appreciated from the very beginning. A thoughtful and well-curated Employee Welcome Kit shows that the company is invested in the employee's success and well-being, fostering loyalty and job satisfaction. Giftingy's Onboarding Employee Kits are designed to enhance the onboarding experience and set the stage for long-term employee engagement and retention.",
            "open": false
        },
        {
            "question": "What are some innovative items to include in an Onboarding Employee Kit?",
            "answer": "Innovative items in an Onboarding Employee Kit include tech gadgets like wireless chargers, smart notebooks, or portable speakers. Wellness products like fitness trackers, stress-relief tools, and eco-friendly items like reusable water bottles are popular. Giftingy offers a wide range of creative and innovative products that can be tailored to suit your company's brand and the preferences of your new hires.",
            "open": false
        },
        {
            "question": "Why should companies invest in high-quality Onboarding Kits?",
            "answer": "Investing in high-quality Onboarding Kits is important because it reflects the company's commitment to providing a positive and supportive work environment. High-quality items enhance the employee's first impression and contribute to a sense of pride and belonging within the company. Giftingy's premium Employee Welcome Kits are designed to impress and set the tone for a successful and engaged workforce.",
            "open": false
        },
        {
            "question": "How can Onboarding Employee Kits be made more sustainable?",
            "answer": "Onboarding Employee Kits can be more sustainable by including eco-friendly products such as bamboo stationery, recycled notebooks, and reusable water bottles. Choosing suppliers prioritising sustainability and selecting items made from sustainable materials can reduce the environmental impact. Giftingy offers a range of eco-friendly options for Employee Welcome Kits, allowing companies to promote their commitment to sustainability while delivering thoughtful gifts.",
            "open": false
        }
    ]
    
return (
<>
<MetaTags
        title="Premier Corporate Diwali Gifting Company in India"
        description="Discover exquisite Diwali corporate gifts and luxury hampers from India's leading Diwali corporate gifting company. Perfect for employees and clients."
        keywords="Onboarding Employee Kit, Employee Welcome Kit, New Employee Welcome Gift Set, Employee Welcome Kit, Workplace Welcome Kit, New Joinee Kit for Corporates, New Joinee Kit for Corporate Employee"
      />
<ServiceHeader title="Onboarding Employee Kit" imageUrl={`${process.env.PUBLIC_URL}/images/newoinbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">Creating the Perfect Employee Welcome Kit for a Seamless Onboarding Experience</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      An Onboarding Employee Kit is crucial in creating a lasting first impression for new hires. The kit serves as a thoughtful gesture, reflecting the company's culture and values while welcoming new employees. It's more than just a gift set; it's a strategic tool to engage employees from the beginning and foster a sense of belonging. From essential office supplies to branded merchandise, a well-crafted Employee Welcome Kit sets the tone for a positive onboarding experience and boosts employee morale right from day one.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Offering a comprehensive New Employee Welcome Gift Setor Workplace Welcome Kit is becoming a standard practice for many companies in India and globally. These kits provide practical items for daily work and convey that the company cares about the comfort and integration of its new team members. A carefully curated New Joinee Kit for Corporates can include everything from branded stationery and tech gadgets to personal items that add a touch of personalisation.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Why invest in a quality onboarding kit? Because first impressions matter! New employees often judge the company based on their initial experience, and a thoughtful welcome kit shows the company's commitment to employee satisfaction and engagement. Here's what makes a great Employee Welcome Kit:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances Employee Engagement: A welcome kit fosters a sense of belonging and enthusiasm among new hires.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflects Company Culture: The items in the kit should align with your company's values and culture.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Includes Practical Essentials: Use useful items in daily work, from stationery to tech gadgets.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers Personalisation: Adding the employee's name or a personal note makes the gift more special.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Showcases Brand Identity: Include branded merchandise to reinforce your company's identity.</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Improves Onboarding Experience: A thoughtful kit eases the transition for new employees.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Boosts Employee Morale: A well-received kit can make new hires feel valued and appreciated.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Sets a Positive Tone: A welcome kit creates excitement and a positive mindset.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Provides Useful Information: Include a handbook or guide that helps new employees navigate their first few weeks.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Builds Lasting Connections: A welcome kit lays the foundation for long-term employee loyalty.</p>
            </li>
         </div>
      </ul>
     
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Your Onboarding Employee Kits?</h2>
      <p >Selecting the perfect Employee Welcome Kit requires careful planning and attention to detail. Giftingy is a premier corporate gifting company offering tailored solutions for onboarding kits that resonate with your new hires. We understand that every company is unique, so we provide customised kits that align with your brand's values and culture. Here's why Giftingy should be your top choice for New Joinee Kits for Corporate Employees:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/newoinimg2.jpg`} alt="New Employee Welcome Gift Set" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialise in crafting Onboarding Employee Kits that are functional and memorable. Our New Employee Welcome Gift Sets range includes everything from branded stationery and tech accessories to personal care items and wellness products. We ensure each item is carefully selected to enhance the onboarding experience and make new hires feel welcome.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of the key features of our service is personalisation. We understand that small details can make a big difference, so we offer options to add the employee's name, a welcome note, or even a personalised message from the CEO. This adds a unique touch and ensures that your employees feel valued.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy offers a seamless process for designing and delivering onboarding kits. Whether you need bulk orders for a large team or a few special kits for senior executives, our team is here to support you every step of the way. From selecting high-quality items to ensuring timely delivery, we take care of everything, allowing you to focus on welcoming your new team members.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customised Onboarding Kits: Tailored to reflect your company's brand and culture.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-Quality Items: Only the best products make it into our kits.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Personalisation Options: Add a personal touch with customised messages or branding.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Seamless Delivery: Efficient and reliable service, ensuring your kits are ready when needed.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive Range of Products: Choose from a wide selection of branded merchandise, tech gadgets, and essentials.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expert Consultation: Our team works closely with you to create the perfect kit for your needs.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Hassle-Free Ordering: Simple process from design to delivery.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Sustainable Options: We offer eco-friendly products for companies focused on sustainability.
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your onboarding kits means choosing quality, reliability, and creativity. We aim to help you make a positive first impression and set the stage for a successful and engaged workforce.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Partner for Tailored Onboarding Employee Kits</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we understand the significance of a positive onboarding experience, so we craft bespoke Employee Welcome Kits that align with your company's values and enhance employee satisfaction. Our unique selling propositions (USPs) set us apart as India's leading provider of New Joinee Kits for Corporate Employees. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your onboarding kits, ensuring each kit reflects the company's brand and culture. Our branding on gifts helps keep your company top of mind while promoting brand identity and loyalty. We provide value for money by offering high-quality kits within your budget, making it easy to create a lasting first impression without overspending.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/newoinimg3.jpg`} alt="Personalised Onboarding Employee Kit" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="OnboardingEmployeeKits" />

<FaqPage faqs={faqs} />
</>
);
};
export default OnboardingEmployeeKit;