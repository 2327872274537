import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/plantersimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalized planters that reflect your brand identity." },
    { title: "Eco-Friendly Gifting", detail: "Sustainable and environmentally responsible options." },
    { title: "Quality Assurance", detail: "We ensure every plant is healthy and thriving." },
    { title: "Timely Delivery", detail: "Reliable service with a focus on punctual delivery." },
    { title: "Wide Range of Options", detail: "From small desk plants to large indoor greenery." },
    { title: "Bulk Order Expertise", detail: "Competitive pricing and seamless service for large orders." },
    { title: "Memorable Gifts", detail: "Planters that leave a lasting impression on recipients." },
  
];
const PlantersCorporateGifting = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
       
      }, []);
const faqs =
    [
        {
            "question": "What types of plants are best for corporate gifting?",
            "answer": "The best plants for corporate gifting are those that are low maintenance and thrive indoors, such as succulents, peace lilies, and snake plants. These plants are easy to care for and bring a touch of nature into the workplace. Giftingy offers a variety of indoor plants for corporate gifts that are ideal for enhancing office spaces and promoting a healthier work environment. We also provide customization options to make these gifts even more special.",
            "open": false
        },
        {
            "question": "How do I choose the right planter for corporate gifting?",
            "answer": "Choosing the right planter involves considering the recipient's workspace, the type of plant, and the message you want to convey. Smaller planters are ideal for desks, while larger ones can be placed in office corners or reception areas. Giftingy’s range of planters for corporate gifting includes options for all types of spaces and preferences. We also offer personalized planters with company logos or special messages to enhance the gifting experience.",
            "open": false
        },
        {
            "question": "Can I order planters in bulk for corporate events?",
            "answer": "Giftingy offers bulk orders for planters, making it easy for companies to plan large-scale corporate gifting. Whether you need plants as gifts for a corporate event, employee appreciation, or client gifting, we can accommodate your needs. Our bulk ordering process is straightforward, and we ensure timely delivery of high-quality planters that leave a lasting impression on the recipients.",
            "open": false
        },
        {
            "question": "What are the benefits of gifting plants to employees and clients?",
            "answer": "Gifting plants to employees and clients offers numerous benefits, including enhancing the workspace, improving air quality, and promoting well-being. Plants also serve as a constant reminder of your company’s appreciation and commitment to sustainability. Giftingy’s indoor plants for return gifts and corporate gifting are designed to impact aesthetically and environmentally positively. Our carefully selected plants are easy to maintain, making them a practical and thoughtful gift.",
            "open": false
        },
        {
            "question": "How do I ensure the plants I gift remain healthy?",
            "answer": "Ensuring that gifted plants remain healthy involves choosing the right type of plant, providing care instructions, and selecting high-quality planters. Giftingy sources plants from reputable vendors to ensure they are healthy and well-suited for indoor environments. We also offer guidance on plant care to help recipients maintain their gifts. Our gifting company for plant bulk orders is committed to delivering only the best, ensuring your corporate gifts thrive long after receiving them.",
            "open": false
        },
        {
            "question": "What makes planters a sustainable choice for corporate gifting?",
            "answer": "Planters are a sustainable choice for corporate gifting because they contribute positively to the environment by purifying air and adding greenery to indoor spaces. Unlike disposable or perishable gifts, plants continue to grow and thrive, symbolizing growth and longevity in business relationships. Giftingy’s planters for corporate gifting focus on sustainability, offering eco-friendly options that align with a company’s commitment to environmental responsibility. By choosing plants, you are making a thoughtful, lasting impact on your recipients and the planet.",
            "open": false
        },
        {
            "question": "Can planters be customized for corporate branding?",
            "answer": "Yes, planters can be customized for corporate branding. Giftingy offers a variety of customization options, including adding company logos, branding messages, or personalized tags on the planters. This enhances the gift's personal touch and reinforces brand visibility. Our corporate gifting company specializes in creating branded planters that reflect your company’s identity while making a positive impression on recipients.",
            "open": false
        },
        {
            "question": "What are some unique ways to present planters as corporate gifts?",
            "answer": "Unique ways to present planters as corporate gifts include pairing them with personalized messages, incorporating them into gift baskets, or selecting themed planters that align with your company’s branding or event. You can also choose planters that match the decor of the recipient’s workspace or opt for a range of plants that cater to different tastes. Giftingy offers unique planters and corporate gifting ideas that make your gifts stand out and leave a lasting impact.",
            "open": false
        },
        {
            "question": "How do I care for indoor plants gifted as corporate gifts?",
            "answer": "Caring for indoor plants gifted as corporate gifts involves ensuring they receive adequate light, water, and occasional fertilization. Most indoor plants, such as succulents or peace lilies, are low-maintenance and thrive with minimal care. Giftingy provides care instructions with each plant to help recipients maintain their gifts. Our indoor plants for corporate gifts are selected for their hardiness and ease of care, making them ideal for busy professionals.",
            "open": false
        },
        {
            "question": "Why are planters a popular choice for return gifts at corporate events?",
            "answer": "Planters are a popular choice for return gifts at corporate events because they offer a thoughtful, eco-friendly alternative to traditional gifts. They symbolize growth, prosperity, and long-term relationships, making them perfect for expressing appreciation and goodwill. Giftingy’s return gift plants' wholesale options allow companies to order in bulk, ensuring consistency in quality and presentation. These gifts leave a lasting impression and are cherished by recipients long after the event.",
            "open": false
        },
        {
            "question": "How can planters enhance the corporate office environment?",
            "answer": "Planters enhance the corporate office environment by adding greenery, improving air quality, and creating a more inviting and pleasant workspace. Plants help reduce stress and increase productivity, making them a valuable addition to any office. Giftingy’s indoor plants for corporate gifts are designed to fit seamlessly into office spaces, offering aesthetic and health benefits that contribute to a positive work atmosphere.",
            "open": false
        },
        {
            "question": "What types of planters are best suited for small office spaces?",
            "answer": "Compact planters such as desk plants, succulents, or small potted herbs are ideal. These plants require minimal space and maintenance while providing the benefits of greenery and improved air quality. Giftingy offers a range of planters for corporate gifting tailored for small office environments, ensuring that even limited spaces can enjoy the beauty and benefits of plants.",
            "open": false
        },
        {
            "question": "Can I mix different types of plants in a single planter for corporate gifting?",
            "answer": "Mixing different plant types in a single planter can create a visually appealing and diverse gift. Plants with varying colours, textures, and heights add interest and enhance the aesthetic appeal of the gift. Giftingy’s corporate gifting company offers custom planter arrangements that combine different plants to create a unique and personalized gift.",
            "open": false
        },
        {
            "question": "What are some budget-friendly planter options for corporate gifting?",
            "answer": "Budget-friendly planter options for corporate gifting include succulents, small desk plants, and basic ceramic or terracotta pots. These affordable yet stylish options make them suitable for large-scale corporate gifting. Giftingy’s vendor for return gift plants wholesale provides a range of budget-friendly planters that maintain high quality while staying within your corporate gifting budget.",
            "open": false
        },
        {
            "question": "How do I choose a reliable vendor for bulk plant orders?",
            "answer": "Choosing a reliable vendor for bulk plant orders involves researching their reputation, reading customer reviews, and checking their track record for timely delivery and quality. It’s also important to ensure the vendor offers customization options and provides detailed care instructions with each plant. Giftingy, a leading corporate gifting company, is known for its reliable service, quality plants, and expertise in handling large corporate orders, making us the preferred choice for many businesses.",
            "open": false
        }
    
    
  ]
 
return (
<>
<MetaTags
        title="Top Planters Corporate Gifting Company in India"
        description="Explore eco-friendly planters for corporate gifting in India. Giftingy offers various indoor plants for return gifts, employee appreciation, and client gifts."
        keywords="Planters' corporate gifting ideas, Planters corporate gifting ideas India, Planters corporate gifting company, Planters corporate gifting, Plants for corporate gifting, Bulk plants for gifts, Indoor plants for return gifts, Indoor plants for corporate gifts, Return gift plants wholesale, Vendor for return gift plants wholesale, Gifting company for return gift plants wholesale, Gifting company for plants wholesale, Gifting company for plants bulk order"
      />
<ServiceHeader title="Planters Corporate Gifting Ideas" imageUrl={`${process.env.PUBLIC_URL}/images/plantersbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">Elevate Your Corporate Gifting with Planters</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Planters have become popular for corporate gifting, offering a blend of nature and elegance that resonates with recipients. As a refreshing alternative to traditional gifts, planters enhance the aesthetic appeal of any workspace and contribute to a healthier environment. Whether you're looking for planters corporate gifting ideas or planning to order in bulk, this green gifting approach will leave a lasting impression on clients and employees alike.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      In India, the trend of gifting plants is gaining momentum, with many companies opting for indoor plants for corporate gifts. These gifts are not only visually pleasing but also serve as a constant reminder of your brand's commitment to sustainability and well-being. There is a wide range of options, from small desk plants to more substantial indoor greenery. Gifting plants also reflects a sense of care and thoughtfulness, making them an ideal choice for corporate gifting.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Here are some reasons why planters make great corporate gifts:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Eco-Friendly: Contributes to a greener environment.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Low Maintenance: Easy to care for, making them ideal for busy professionals.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Aesthetic Appeal: Enhances the beauty of any workspace.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes Well-being: Helps improve air quality and reduce stress.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Versatile: Suitable for various corporate events and occasions.</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Long-lasting: Unlike perishable gifts, plants last longer and grow over time.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Customizable: Can be branded with company logos or messages.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Symbolic of Growth: Represents growth and prosperity, aligning with corporate values.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Sustainable Gift: Reflects a company’s commitment to sustainability.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Memorable: Leaves a lasting impression on the recipient.</p>
            </li>
         </div>
      </ul>
  
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Planters Corporate Gifting?</h2>
      <p >Here's why Giftingy is your go-to choice for planters corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/plantersimg2.jpg`} alt="Bulk Order Planters for Corporate Events" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy is a premier planters corporate gifting companyin India, known for its exceptional range of indoor plants and planters tailored for corporate gifting. We understand that every corporate gift needs to be special, so we offer a curated selection of planters that look great and convey a message of growth and sustainability.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               At Giftingy, we source the best plants for corporate gifting from reliable vendors, ensuring that each plant is healthy and well-cared for. Our range includes a variety of indoor plants that are perfect for return gifts, employee appreciation, or client gifts. We offer bulk orders and wholesale options, making it easy for companies to plan large-scale gifting easily.

               </p>
            </div>
       
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Quality Plants: We offer only the best indoor plants that thrive in corporate environments.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Custom Packaging: Each planter can be customized with your company logo or a special message.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide Range of Options: We have something for every need, from small desk plants to larger indoor plants.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Eco-Friendly Gifting: Our planters reflect a commitment to sustainability and environmental responsibility.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable Service: With years of experience in the corporate gifting industry, we ensure timely delivery and exceptional customer service.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Bulk Orders Available: We cater to large corporate orders, offering competitive pricing for bulk purchases.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Memorable Gifts: Our planters leave a lasting impression, making your corporate gifting memorable.
            </p>
         </div>

      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Premier Planters Corporate Gifting Partner</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            Giftingy is committed to providing high-quality planters for thoughtful and sustainable corporate gifts. Our unique selling propositions (USPs) highlight what sets us apart in the corporate gifting industry:
            </p>
           
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/plantersimg3.jpg`} alt="Elegant Planters for Corporate Gifting" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="luxuryHomeDecor" />

<FaqPage faqs={faqs} />
</>
);
};
export default PlantersCorporateGifting;