import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink
import '../components/FooterCategory.css';

const FooterCategory = () => {
    return (
        <footer className="footer1">
            <div className='footerMainDiv'>
                <h5 className='footerMain'>Our Categories</h5>
            </div>
            <div className="container">
                <div className="row">
                    {/* Categories Section */}
                    <div className="col-md-4">
                        <ul className="footer-links1 footerUls">
                            <li><NavLink to="/services/corporate-gifting">Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/incentive-gifts">Incentive Gifts</NavLink></li>
                            <li><NavLink to="/services/exhibition-and-events-gifting">Exhibition & Events Gifting</NavLink></li>
                            <li><NavLink to="/services/branded-medals-and-trophies">Medals & Trophies</NavLink></li>
                            <li><NavLink to="/services/corporate-leather-gifting">Corporate Leather Gifting</NavLink></li>
                            <li><NavLink to="/services/pongal-corporate-gifting">Pongal Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/kartikay-dibam-corporate-gifting">Kartikay Dibam Gifting</NavLink></li>
                            <li><NavLink to="/services/chocolate-corporate-gifting">Chocolate Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/easter-corporate-gifts">Easter Corporate Gifts</NavLink></li>
                            <li><NavLink to="/services/dussehra-corporate-gifts">Dussehra Corporate Gifts</NavLink></li>
                            <li><NavLink to="/services/handicraft-corporate-gifting">Handicraft Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/coffee-corporate-gifts">Coffee As Corporate Gift</NavLink></li>
                            <li><NavLink to="/services/blankets-for-corporate-gifting">Blankets for Corporate Gifting</NavLink></li>
                        </ul>
                    </div>

                    {/* Contact Information */}
                    <div className="col-md-4">
                        <ul className="footer-links1 footerUls">
                            <li><NavLink to="/services/Vastu-corporate-gifting">Vastu Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/corporate-gifting-for-milestones-and-Celebrations">Milestones & Celebrations</NavLink></li>
                            <li><NavLink to="/services/divinity-gifts">Divinity Gifts</NavLink></li>
                            <li><NavLink to="/services/indian-traditional-gifts">Indian Traditional Gifts</NavLink></li>
                            <li><NavLink to="/services/festival-gifting">Festival Gifting</NavLink></li>
                            <li><NavLink to="/services/navratri-corporate-gifting">Navratri Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/branded-apparel-gifting">Branded Apparel Gifting</NavLink></li>
                            <li><NavLink to="/services/dryfruits-and-sweets-gifting">Dryfruits & Sweets Gifting</NavLink></li>
                            <li><NavLink to="/services/travel-gifts-for-businesses">Travel Gifts in Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/ganesh-chaturthi-corporate-gifting">Ganesh Chaturthi Corporate Gifts</NavLink></li>
                            <li><NavLink to="/services/promotional-giveaways-corporate-gifts">Promotional Giveaways Corporate Gifts</NavLink></li>
                            <li><NavLink to="/services/drinkware-products-for-corporate-gifting">Drinkware Products for Corporate Gifts</NavLink></li>
                            <li><NavLink to="/services/backpack-as-corporate-gifts">Backpack As Corporate Gift</NavLink></li>
                        </ul>
                    </div>

                    <div className="col-md-4">
                        <ul className="footer-links1 footerUls">
                            <li><NavLink to="/services/akshay-tritiya-corporate-gifting">Akshay Tritiya Gifting</NavLink></li>
                            <li><NavLink to="/services/gifts-for-her">Gifts for Her</NavLink></li>
                            <li><NavLink to="/services/gifts-for-male">Gifts for Male</NavLink></li>
                            <li><NavLink to="/services/eid-corporate-gifting">Eid Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/onboarding-employee-kit">Onboarding Employee Kit</NavLink></li>
                            <li><NavLink to="/services/golf-corporate-gifting">Golf Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/corporate-gifts-for-clients">Gifts for Clients</NavLink></li>
                            <li><NavLink to="/services/corporate-gifts-for-employees">Gifts for Employees</NavLink></li>
                            <li><NavLink to="/services/planters-corporate-gifting-ideas">Planters Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/kitchen-and-dining-corporate-gifting">Kitchen & Dining Corporate Gifts</NavLink></li>
                            <li><NavLink to="/services/corporate-jewellery-gifts">Corporate Jewellery Gifts</NavLink></li>
                            <li><NavLink to="/services/utility-corporate-gift">Utility Corporate Gift</NavLink></li>
                            <li><NavLink to="/services/womens-day-corporate-gift">Women's Day Corporate Gift</NavLink></li>
                        </ul>
                    </div>

                    <div className="col-md-4">
                        <ul className="footer-links1 footerUls">
                            <li><NavLink to="/services/dhanteras-corporate-gifting">Dhanteras Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/personalised-corporate-gifts">Personalised Corporate Gifts</NavLink></li>
                            <li><NavLink to="/services/bar-accessories-corporate-gifting">Bar Accessories Gifting</NavLink></li>
                            <li><NavLink to="/services/diwali-corporate-gifting">Diwali Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/wedding-return-gifts">Wedding Return Gifts</NavLink></li>
                            <li><NavLink to="/services/luxury-gifting">Luxury Gifting</NavLink></li>
                            <li><NavLink to="/services/luxury-home-decor-gifting">Luxury Home Decor</NavLink></li>
                            <li><NavLink to="/services/health-and-wellness-gifts">Health & Wellness Gifts</NavLink></li>
                            <li><NavLink to="/services/makar-sankranti-corporate-gifts">Makar Sankranti Gifts</NavLink></li>
                            <li><NavLink to="/services/beauty-and-fashion-corporate-gifting">Beauty & Fashion Gifting</NavLink></li>
                            <li><NavLink to="/services/tea-corporate-gifts">Tea Corporate Gifts</NavLink></li>
                            <li><NavLink to="/services/bedsheets-for-corporate-gifting">Bedsheets for Corporate Gifting</NavLink></li>
                            <li><NavLink to="/services/brass-items-as-corporate-gifts">Brass Items As Corporate Gifts</NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterCategory;
