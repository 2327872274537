import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/pongalimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised Pongal gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality festive gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const PongalCorporateGifting = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
      }, []);
const faqs =[
    {
      "question": "What are good Pongal corporate gifts for employees?",
      "answer": "Good Pongal corporate employee gifts include traditional festive hampers, premium gift boxes, and personalised cultural items. Popular choices are handcrafted items, gourmet sweets, and custom festive accessories. The key is to choose gifts that reflect the recipient's preferences and the festival's cultural significance. Consulting a Pongal corporate gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "Why is corporate gifting during Pongal important?",
      "answer": "Corporate gifting during Pongal is important because it expresses appreciation and enhances the festive spirit. These gifts strengthen business relationships and foster a positive environment among employees, clients, and partners. Thoughtful and well-chosen festive gifts reflect the company's appreciation and commitment to cultural values. They also promote a positive work environment and enhance brand visibility.",
      "open": false
    },
    {
      "question": "What are some popular Pongal gifts for clients?",
      "answer": "Popular Pongal gifts for clients include traditional festive hampers, customised gift boxes, and personalised cultural items. Handcrafted items such as gourmet sweets, premium festive accessories, and custom gift sets are also appreciated. Consider elegant Pongal hampers, high-end festive gifts, and bespoke accessories. These gifts reflect the company's appreciation and foster a positive business relationship.",
      "open": false
    },
    {
      "question": "How do I choose the right Pongal gifts for corporate gifting?",
      "answer": "Choosing the right Pongal gifts for corporate gifting involves understanding the recipient's preferences and the cultural significance of the occasion. Opt for high-quality items that reflect thoughtfulness and festive cheer. Personalisation can add a special touch, making the gifts more meaningful. Consider the practicality and relevance of the gifts to ensure they are appreciated and used. Consulting a corporate gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "What are the benefits of working with a Pongal corporate gifting company?",
      "answer": "Working with a Pongal corporate gifting company ensures you receive high-quality, well-crafted items that reflect the cultural significance and the company's appreciation. These companies offer various customisation options to make your gifts unique and personal. They provide expertise in selecting and designing gifts that suit your needs and preferences. Reliable delivery services and excellent customer support are additional benefits. Partnering with a reputable company ensures a seamless and satisfactory gifting experience.",
      "open": false
    },
    {
      "question": "Can I order Pongal gifts for corporate gifting in bulk?",
      "answer": "Yes, you can order Pongal gifts for corporate gifting in bulk from various gifting companies. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a corporate gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some premium Pongal gift options?",
      "answer": "Premium Pongal gift options include bespoke festive hampers, luxury gift boxes, and high-end cultural items. Handcrafted items such as elegant festive accessories, fine cultural artefacts, and personalised gift sets are also appreciated. Consider premium Pongal hampers, luxury gifts, and exclusive festive accessories. These gifts reflect thoughtfulness and are ideal for premium corporate gifting.",
      "open": false
    },
    {
      "question": "How do Pongal gifts enhance corporate celebrations?",
      "answer": "Pongal gifts enhance corporate celebrations by adding a touch of cultural significance and appreciation. They are ideal for company anniversaries, client appreciation events, and employee recognition programs. These gifts reflect the company's commitment to cultural values and festive cheer. Customised festive gifts make the celebration more memorable and meaningful. They foster a positive and respectful corporate culture.",
      "open": false
    },
    {
      "question": "What are some unique Pongal gift ideas for corporate events?",
      "answer": "Unique Pongal gift ideas for corporate events include bespoke festive hampers, customised gift boxes, and personalised cultural items. Handcrafted items such as gourmet sweets, premium festive accessories, and custom gift sets are also appreciated. Consider elegant Pongal hampers, high-end festive gifts, and bespoke accessories. These gifts reflect the company's appreciation and foster a positive business relationship.",
      "open": false
    },
    {
        "question": "What are some premium Pongal gifts for senior executives?",
        "answer": "Premium Pongal gifts for senior executives include bespoke festive accessories, luxury gift boxes, and high-end cultural items. Handcrafted items such as elegant festive artefacts, fine cultural accessories, and personalised gift sets are also appreciated. Consider festive hampers, luxury Pongal gifts, and exclusive accessories. The key is to choose gifts that reflect the festive spirit and the executive's preferences.",
        "open": false
      },
      {
        "question": "Why is eco-friendly gifting becoming popular in Pongal corporate gifting?",
        "answer": "Eco-friendly gifting is becoming popular in Pongal corporate gifting because it reflects a commitment to sustainability and environmental responsibility. Eco-friendly gifts are made from sustainable materials and processes, reducing the environmental impact. Recipients who value sustainability highly appreciate these gifts. Choosing eco-friendly festive gifts also enhances the company's socially responsible and environmentally conscious image.",
        "open": false
      },
      {
        "question": "How do I choose the best vendor for Pongal gifts?",
        "answer": "Choosing the best vendor for Pongal gifts involves researching reputable gifting companies and suppliers. Check reviews, testimonials, and portfolios to gauge their products' and services' quality and reliability. Opt for vendors with a proven track record of high-quality craftsmanship and customisation. Request samples and provide clear specifications for the customisation. Partnering with a trusted corporate gifting company ensures a seamless and satisfactory gifting experience.",
        "open": false
      },
      {
        "question": "What are some innovative Pongal gift ideas for tech-savvy employees?",
        "answer": "Innovative Pongal gift ideas for tech-savvy employees include personalised tech gadgets, branded tech accessories with cultural items, and luxury tech devices paired with festive gifts. Consider wireless chargers with custom festive products, Bluetooth speakers with premium cultural accessories, and smartwatches with bespoke festive gifts. Customising these items with the company logo or personal messages adds a special touch.",
        "open": false
      },
      {
        "question": "How do Pongal gifts enhance corporate culture?",
        "answer": "Pongal gifts enhance corporate culture by showcasing the company's values and appreciation for its employees, clients, and partners. Thoughtful and well-chosen gifts demonstrate the company's commitment to cultural values, inclusivity, and festive cheer. Customisation with the company logo or branding elements enhances the corporate identity. Festive gifts that reflect the company's culture foster a positive and respectful work environment.",
        "open": false
      },
      {
        "question": "What are some good Pongal gifts for clients under 5000?",
        "answer": "Good Pongal gifts for clients under 5000 include bespoke festive accessories, custom-designed cultural items, and personalised gift boxes. Consider engraved gifts, stylish cultural artefacts, and contemporary festive sets. The key is choosing gifts that reflect the recipient's preferences and the occasion without compromising quality. Consulting a corporate gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do Pongal gifts promote corporate social responsibility?",
        "answer": "Pongal gifts promote corporate social responsibility by reflecting the company's commitment to sustainability and community engagement. Recipients highly appreciate eco-friendly and socially responsible festive gifts, which enhance the company's image as socially responsible and environmentally conscious. Choosing festive gifts that support local artisans and charitable causes also promotes corporate social responsibility.",
        "open": false
      },
      {
        "question": "What are some unique Pongal gift ideas for startups?",
        "answer": "Unique Pongal gift ideas for startups include branded tech gadgets with festive accessories, personalised cultural items with startup branding, and luxury tech devices paired with festive gifts. Consider wireless chargers with custom festive products, personalised notebooks with engraved cultural accessories, and premium festive gifts with startup logos. The key is to choose gifts that reflect the festive spirit and the startup's innovative culture.",
        "open": false
      },
      {
        "question": "How can Pongal gifts be used for marketing and promotion?",
        "answer": "Pongal gifts can be used for marketing and promotion by enhancing brand visibility and recall. Customising gifts with the company logo or branding elements increases brand awareness. Thoughtful and well-chosen festive gifts create positive associations with the brand and foster goodwill. Regular gifting of festive items helps maintain and strengthen business relationships, promoting long-term success.",
        "open": false
      },
      {
        "question": "What are some good Pongal gifts for employees under 1000?",
        "answer": "Good Pongal gifts for employees under 1000 include personalised festive accessories, branded cultural items, and hampers. Consider premium festive gift sets, cultural artefacts, and customised gift boxes. The key is choosing gifts that reflect the festive spirit and the company's appreciation without compromising quality. Consulting a corporate gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do Pongal gifts enhance client retention?",
        "answer": "Pongal gifts enhance client retention by showing appreciation and fostering goodwill. Thoughtful and well-chosen gifts reflect the company's gratitude for the client's support and partnership. Regular gifting of festive items helps maintain and strengthen client relationships, promoting long-term business success. Customised gifts with the company logo or personal messages add a special touch, making the gifts more memorable.",
        "open": false
      },
      {
        "question": "What are some unique Pongal gift ideas for corporate events?",
        "answer": "Unique Pongal gift ideas for corporate events include bespoke festive accessories, customised gift boxes, and handcrafted cultural items. High-end luxury festive accessories, fine cultural artefacts, and personalised gift sets are also popular. Consider premium Pongal hampers, luxury gifts, and exclusive festive accessories. The key is to choose gifts that reflect the recipient's preferences and the event's significance.",
        "open": false
      },
      {
        "question": "How do Pongal gifts benefit employee engagement programs?",
        "answer": "Pongal gifts benefit employee engagement programs by showing appreciation and recognition for employees' efforts and contributions. Thoughtful and well-chosen gifts enhance job satisfaction and motivation. Customised gifts with the company logo or personal messages add a special touch. Regular gifting of festive items during corporate events fosters a positive work environment and strengthens employee engagement.",
        "open": false
      },
      {
        "question": "What are some good Pongal gifts for clients under 3000?",
        "answer": "Good Pongal gifts for clients under 3000 include luxury festive gift boxes, bespoke cultural items, and personalised gift sets. Consider high-end cultural artefacts, fine festive accessories, and exclusive gift boxes. The key is choosing gifts that reflect the recipient's preferences and the occasion without compromising quality. Consulting a corporate gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How can Pongal gifts be used for team building?",
        "answer": "Pongal gifts can be used for team building by showing appreciation and fostering goodwill among team members. Thoughtful and well-chosen gifts reflect the company's gratitude for the team's efforts and contributions. Regular gifting of festive items during team-building activities helps maintain and strengthen team cohesion, promoting a positive and collaborative work environment. Customised gifts with the company logo or personal messages add a special touch, making the gifts more meaningful.",
        "open": false
      }
  ]
 
return (
<>
<MetaTags
        title="Premier Pongal Corporate Gifting Company in India"
        description="Discover exquisite Pongal corporate gifts from India's leading corporate gifting company. Perfect for employees, clients, and corporate events."
        keywords="Pongal corporate gifts for employees, Pongal corporate gifts for clients, Pongal Corporate Gifts Company, Pongal corporate gifting vendor, Pongal corporate gifting company, Pongal corporate gift ideas, Pongal Festival corporate gift, Unique corporate gifts for Pongal, Pongal corporate gifting"
      />

<ServiceHeader title="Pongal Corporate Gifting" imageUrl={`${process.env.PUBLIC_URL}/images/pongalbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Significance of Pongal Corporate Gifting</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Pongal, a harvest festival celebrated primarily in South India, marks the beginning of the Tamil month of Thai and is dedicated to the Sun God. This auspicious festival signifies prosperity, gratitude, and new beginnings. Corporate gifts during Pongal can strengthen business relationships, show appreciation, and foster a positive work environment.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Pongal corporate gifts should be thoughtful, culturally significant, and reflect the festive spirit. The options are vast, from traditional gifts to unique corporate gifts for Pongal. These gifts can range from Pongal corporate gifts for employees to elegant gifts for clients. Choosing the right corporate gifts can enhance the festive cheer and create lasting memories.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Here are some key reasons why Pongal corporate gifting is important:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Show appreciation and recognition</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Celebrate cultural heritage and traditions</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Foster a positive work environment</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthen professional relationships</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhance company culture and values</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promote brand loyalty and visibility</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Encourage motivation and productivity</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Create lasting memories and connections</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Support employees and clients in celebrating the festival</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflect the company's commitment to cultural inclusivity</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Pongal corporate gifting is more than presents; it celebrates it's culture, tradition, and goodwill.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Pongal Corporate Gifting?</h2>
      <p >Selecting the perfect Pongal corporate gifts requires a keen understanding of quality, cultural significance, and customisation. Giftingy is a premier Pongal corporate gifting company offering a curated selection of the finest gifts for this festive occasion. Here's why Giftingy should be your top choice for Pongal corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/pongalimg2.jpg`} alt="Unique Pongal Gift Ideas" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialise in creating corporate gifts that resonate with the recipient and reflect the company's appreciation and festive spirit. Our corporate gift ideas include beautifully crafted items, from traditional festive hampers to contemporary gift boxes. Whether you are looking for gifts for employees, clients, or corporate events, our collection caters to diverse tastes and preferences.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of our key strengths is our ability to offer personalised gifts that reflect the recipient's preferences and the occasion. We pride ourselves on being a leading Pongal corporate gifting company that delivers excellence in craftsmanship. Our products are sourced from the best vendors, ensuring that every item meets the highest quality and cultural significance standards.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy specialises in bulk gifting, making us the perfect partner for corporate celebrations. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From unique Pongal gifting ideas to premium festive hampers, we offer various choices to cater to your needs. Our commitment to customer satisfaction and ability to create lasting impressions make us the preferred choice for Pongal corporate gifting in India.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable festive gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products with meticulous craftsmanship
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of gifting ideas for various recipients
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as a Pongal corporate gifting company in India
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Convenient online ordering and customisation options
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive selection of beautifully crafted festive gifts
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your corporate gifting needs ensures that your gifts are culturally significant, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exceptional Pongal Corporate Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional Pongal corporate gifting solutions catering to your needs and preferences. Our unique selling propositions (USPs) set us apart as a leading corporate gifting company in India. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your Pongal gifts, making them more meaningful and culturally significant. Our branding on gifts ensures that your company remains at the forefront of the recipient's mind, enhancing brand recall and visibility. We provide value for money by offering high-quality gifts that fit within your budget, ensuring you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/pongalimg3.jpg`} alt="Pongal Corporate Gifting Solutions" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="PongalGifting" />

<FaqPage faqs={faqs} />
</>
);
};
export default PongalCorporateGifting;