import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';

import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/akshayimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised Akshay Tritiya gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality festive gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const DiaryCorporateGift = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
        // Set meta title, description, and keywords
        document.title = "Customized Corporate Diary Gifts - Branded & Eco-Friendly Options";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute('content', "Discover premium customized corporate diaries for gifting. Explore eco-friendly, handcrafted, and branded dairy options for corporate gifts. Perfect for bulk orders.");
        } else {
          const meta = document.createElement('meta');
          meta.name = "description";
          meta.content = "Discover premium customized corporate diaries for gifting. Explore eco-friendly, handcrafted, and branded dairy options for corporate gifts. Perfect for bulk orders.";
          document.head.appendChild(meta);
        }
        
        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
            metaKeywords.setAttribute('content', "Diary as a corporate gift, Diary corporate gift, Customized corporate dairy supplier, Customized corporate diary manufacturer, Customized corporate diary, Customized corporate diaries, Customized diary printing bulk, Customized diary printing company, Branded corporate diary printing, Branded diary printing, Branded diary printing with logo, Handcrafted branded diary printing, Handcrafted branded diary, Handcrafted diary for corporate gifting, Eco-friendly diary for corporate gifting, Eco-friendly diary options for bulk buy, Eco-friendly stationery products manufacturer in India, Eco-friendly stationery kit, Eco-friendly stationery kit supplier, Stationery items manufacturers gifting ideas, Stationery items corporate gifting ideas");
        } else {
            const meta = document.createElement('meta');
            meta.name = "keywords";
            meta.content = "Diary as a corporate gift, Diary corporate gift, Customized corporate dairy supplier, Customized corporate diary manufacturer, Customized corporate diary, Customized corporate diaries, Customized diary printing bulk, Customized diary printing company, Branded corporate diary printing, Branded diary printing, Branded diary printing with logo, Handcrafted branded diary printing, Handcrafted branded diary, Handcrafted diary for corporate gifting, Eco-friendly diary for corporate gifting, Eco-friendly diary options for bulk buy, Eco-friendly stationery products manufacturer in India, Eco-friendly stationery kit, Eco-friendly stationery kit supplier, Stationery items manufacturers gifting ideas, Stationery items corporate gifting ideas";
            document.head.appendChild(meta);
        }
        
        
      }, []);
const faqs =[
    {
        "question": "Why choose a diary as a corporate gift?",
        "answer": "A diary is a versatile and practical gift that the recipient can use daily. It serves not only as a tool for organization but also as a constant reminder of your brand. Customizing the diary with your company logo or a personalized message enhances its value, making it a thoughtful and appreciated corporate gift.",
        "open": false
    },
    {
        "question": "What customization options are available for corporate diaries?",
        "answer": "At Giftingy, we offer various customization options for corporate diaries. These include logo embossing, personalized messages, custom covers, and bespoke design elements. You can choose from multiple materials, colours, and finishes to create a diary that perfectly represents your brand's identity.",
        "open": false
    },
    {
        "question": "Can I order eco-friendly diaries for corporate gifting?",
        "answer": "Giftingy provides eco-friendly diary options made from sustainable materials such as recycled paper and natural fibres. These diaries are perfect for companies prioritising sustainability and aligning their corporate gifting with their environmental values.",
        "open": false
    },
    {
        "question": "How can branded diaries enhance my corporate gifting strategy?",
        "answer": "Branded diaries are an excellent way to improve your corporate gifting strategy by promoting brand visibility and reinforcing your brand's presence in the recipient's daily life. Every time the diary is used, it serves as a reminder of your brand, helping to strengthen relationships with clients, employees, and partners.",
        "open": false
    },
    {
        "question": "What are the benefits of ordering diaries in bulk for corporate gifting?",
        "answer": "Ordering diaries in bulk for corporate gifting offers several advantages, including cost savings, consistency in quality, and the ability to reach a large audience with a uniform gift. Bulk orders are ideal for corporate events, employee onboarding, and large-scale client gifting programs.",
        "open": false
    },
    {
        "question": "Are there handcrafted diary options for premium corporate gifts?",
        "answer": "Giftingy offers handcrafted diaries perfect for premium corporate gifts. These diaries are made with meticulous attention to detail, using high-quality materials that give them a luxurious feel. Handcrafted diaries can be customized to include unique design elements that reflect your brand's identity.",
        "open": false
    },
    {
        "question": "What are the most popular diary styles for corporate gifting?",
        "answer": "Popular diary styles for corporate gifting include leather-bound diaries, eco-friendly notebooks, spiral-bound planners, and pocket-sized journals. Each style offers different benefits, so the choice depends on the recipient's preferences and the intended use of the diary.",
        "open": false
    },
    {
        "question": "How do I ensure the quality of customized corporate diaries?",
        "answer": "Ensuring the quality of customized corporate diaries involves working with a reputable supplier like Giftingy. We provide samples, detailed previews, and quality checks throughout production. Our commitment to high standards guarantees that your diaries will meet or exceed your expectations.",
        "open": false
    },
    {
        "question": "Can I include other stationery items in a corporate diary gift set?",
        "answer": "Absolutely. Giftingy can create comprehensive corporate gift sets with a diary and other stationery items such as pens, bookmarks, and eco-friendly stationery kits. These sets can be customized to suit your brand and your recipients' preferences.",
        "open": false
    },
    {
        "question": "What is the minimum order quantity for customized corporate diaries?",
        "answer": "The minimum order quantity varies depending on the customisation level and your order's specific requirements. Giftingy is flexible and can accommodate small and large orders, ensuring you get the best solution for your corporate gifting needs.",
        "open": false
    },
    {
        "question": "How does the diary's quality reflect my brand's image?",
        "answer": "The quality of the diary you choose as a corporate gift reflects directly on your brand's image. A high-quality diary conveys professionalism, attention to detail, and a commitment to excellence. Giftingy ensures that all our diaries meet premium quality standards, helping to reinforce your brand's positive reputation.",
        "open": false
    },
    {
        "question": "What are the trends in corporate diary gifting?",
        "answer": "Current trends in corporate diary gifting include eco-friendly materials, minimalist designs, and personalized touches such as monogramming. Companies increasingly opt for diaries aligning with their brand values and sustainability goals. Giftingy stays ahead of these trends to offer the latest and most stylish options for corporate diaries.",
        "open": false
    },
    {
        "question": "Can I get branded diaries with eco-friendly packaging?",
        "answer": "Yes, Giftingy offers eco-friendly packaging options for branded diaries. Our packaging solutions are made from sustainable materials, ensuring that your corporate gift is environmentally responsible from start to finish. This enhances your brand's commitment to sustainability and corporate social responsibility.",
        "open": false
    },
    {
        "question": "What is the turnaround time for bulk diary orders?",
        "answer": "The turnaround time depends on the level of customization and the size of the order. Giftingy typically offers a fast and efficient production process, with clear timelines provided at the start of the project. We ensure timely delivery without compromising on quality.",
        "open": false
    },
    {
        "question": "Can diaries be customized for specific corporate events?",
        "answer": "Giftingy can customize diaries specifically for corporate events, such as conferences, seminars, or annual meetings. We can include event branding, specific themes, and personalized content to make the diaries relevant and valuable to the attendees.",
        "open": false
    },
    {
        "question": "Why are eco-friendly diaries a good choice for corporate gifting?",
        "answer": "Eco-friendly diaries are a great choice for corporate gifting because they align with growing environmental awareness and sustainability efforts. These diaries are made from recycled or sustainable materials, making them an excellent way to demonstrate your company's commitment to corporate social responsibility.",
        "open": false
    },
    {
        "question": "How can I use diaries to enhance employee engagement?",
        "answer": "Diaries can improve employee engagement by providing a tool for organization, goal-setting, and reflection. A well-designed, personalized diary can encourage employees to stay organized and motivated. Including motivational messages or company values within the diary can further reinforce engagement.",
        "open": false
    },
    {
        "question": "Are there luxury diary options for executive gifting?",
        "answer": "Giftingy offers luxury diary options that are perfect for executive gifting. These diaries are made from premium materials such as leather and feature sophisticated designs that appeal to senior executives. Luxury diaries can be customized to include monograms, logos, or other personal touches that make them unique and special.",
        "open": false
    },
    {
        "question": "Can I create a themed corporate gift set with a diary?",
        "answer": "Absolutely. Giftingy can help you create a themed corporate gift set with a diary and other complementary items. For example, you could pair a diary with a luxury pen, an eco-friendly notebook, or a personalized bookmark. Themed sets are a great way to make your corporate gift even more memorable.",
        "open": false
    },
    {
        "question": "What is the best way to present a corporate diary gift?",
        "answer": "The best way to present a corporate diary gift is with elegant packaging that reflects the quality of the gift. Giftingy offers various packaging options, including branded boxes, eco-friendly wraps, and personalized gift tags. A well-presented diary enhances the recipient's experience and makes the gift feel more special and thoughtful.",
        "open": false
    }
]

  
return (
<>
<ServiceHeader title="Diary Corporate Gift" imageUrl={`${process.env.PUBLIC_URL}/images/akshaybn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">Elevate Your Brand with Customized Diary Corporate Gifts</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Diary corporate gifts are a timeless and practical choice for businesses looking to make a lasting impression on their clients, employees, and partners. A well-designed and customized diary serves as a daily organizational tool and reinforces your brand identity every time it is used. Whether it's for annual planning, note-taking, or journaling, a high-quality diary is a gift that resonates with professionals across all industries.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      At Giftingy, we specialize in providing a wide range of customized corporate diaries tailored to meet your branding needs. From eco-friendly options to handcrafted diaries, we offer solutions catering to various tastes and preferences. Our diaries can be customized with your company's logo, tagline, or other branding elements, ensuring your brand stays in mind.
      </p>
     
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Choosing a diary as a corporate gift reflects thoughtfulness and practicality, making it ideal for various corporate events, milestones, and celebrations. Here's why a customized diary corporate gift can be an excellent addition to your corporate gifting strategy:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes Daily Brand Exposure: Every diary use reinforces your brand's presence in the recipient's daily routine.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers Practical Utility: Diaries are highly functional, making them a valuable tool for professionals to organize their schedules and tasks.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Supports Eco-Friendly Choices: We offer eco-friendly diaries made from sustainable materials, aligning your brand with environmental responsibility.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances Brand Loyalty: A well-crafted diary with a personal touch can foster a stronger connection between your brand and the recipient.
               </p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Available in Bulk: Our diaries are ideal for large-scale corporate events or employee onboarding kits. They can be produced in bulk quantities with consistent quality.</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Versatile Design Options: Choose from various styles, materials, and finishes to create a diary that perfectly represents your brand's identity.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Perfect for All Occasions: A diary is suitable for all corporate gifting occasions, whether a New Year gift, a conference giveaway, or a client appreciation token.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Customizable Features: Add personalized elements like company logos, names, or messages to make each diary unique.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Year-Round Relevance: Diaries are used throughout the year, ensuring long-lasting brand engagement.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Boosts Professionalism: Providing a diary encourages organization and time management, reflecting your brand’s commitment to professionalism.</p>
            </li>
         </div>
      </ul>
     
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Diary Corporate Gifts?</h2>
      <p >Giftingy is a leading provider of customized corporate diaries in India, offering various options catering to different corporate gifting needs. Our expertise in creating personalized diaries ensures your gifts are unique and meaningful. Here's what sets us apart:
      </p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/akshayimg2.jpg`} alt="High-End Akshay Tritiya Gifts for Clients" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
              <ul>
                <li>Customization Excellence: We provide comprehensive customization services, including logo embossing, personalized messages, and bespoke design elements that make your diary stand out.</li>
                <li>Premium Quality: Our diaries are made from high-quality materials, ensuring durability and a premium feel that reflects positively on your brand.</li>
                <li>Eco-Friendly Options: We offer eco-friendly diaries made from recycled materials and natural fibres for brands prioritising sustainability.</li>
                </ul>
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               <ul>
                <li>Bulk Production Capability: Whether you need a few dozen diaries or several thousand, we can fulfil large orders with consistent quality and timely delivery.
                </li>
                <li>Innovative Designs: Our design team stays ahead of trends to offer functional but also stylish and modern diaries, appealing to a wide range of recipients.</li>
                <li>Reliable Service: From consultation to delivery, Giftingy is committed to providing exceptional service, ensuring your corporate gifting process is smooth and hassle-free.</li>
                </ul>
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy specialises in bulk gifting, making us the perfect partner for corporate celebrations. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From unique Akshay Tritiya gifting ideas to premium festive hampers, we offer various choices to cater to your needs. Our commitment to customer satisfaction and ability to create lasting impressions make us the preferred choice for Akshay Tritiya corporate gifting in India.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable festive gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products with meticulous craftsmanship
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of gifting ideas for various recipients
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as an Akshay Tritiya corporate gifting company in India
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Convenient online ordering and customisation options
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive selection of beautifully crafted festive gifts
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your corporate gifting needs ensures that your gifts are culturally significant, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exceptional Akshay Tritiya Corporate Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional Akshay Tritiya corporate gifting solutions catering to your needs and preferences. Our unique selling propositions (USPs) set us apart as a leading corporate gifting company in India. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your Akshay Tritiya gifts, making them more meaningful and culturally significant. Our branding on gifts ensures that your company remains at the forefront of the recipient's mind, enhancing brand recall and visibility. We provide value for money by offering high-quality gifts that fit within your budget, ensuring you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/akshayimg3.jpg`} alt="Akshay Tritiya Corporate Gifting Solutions" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="luxuryHomeDecor" />

<FaqPage faqs={faqs} />
</>
);
};
export default DiaryCorporateGift;