import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/vastuimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised Vastu gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality Vastu gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const VastuCorporateGifting = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
       
        
      }, []);
const faqs =[
    {
      "question": "What are good Vastu corporate gifts?",
      "answer": "Good Vastu corporate gifts include accessories, premium boxes, and personalised products. Popular choices are Vastu pyramids, Feng Shui items, and custom Vastu gift sets. The key is to choose gifts that reflect the recipient's needs and the occasion. Consulting a Vastu gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "Why is corporate gifting with Vastu important?",
      "answer": "Corporate gifting with Vastu is important because it expresses appreciation and promotes well-being. These gifts enhance the positive energy and harmony in the recipient's environment, fostering stronger business relationships. Thoughtful and well-chosen Vastu gifts reflect the company's commitment to the well-being and prosperity of employees, clients, and partners. They also promote a positive work environment and enhance brand visibility.",
      "open": false
    },
    {
      "question": "What are some popular Vastu gifts for employees?",
      "answer": "Popular Vastu gifts for employees include Vastu pyramids, Feng Shui items, and personalised Vastu accessories. Handcrafted items such as premium Vastu gift boxes, Vastu idols, and custom Vastu gift sets are also appreciated. Consider luxury Vastu accessories, high-end Vastu products, and bespoke Vastu gifts. These gifts reflect the company's appreciation and foster a positive work environment.",
      "open": false
    },
    {
      "question": "How do I choose the right Vastu gifts for corporate gifting?",
      "answer": "Choosing the right Vastu gifts for corporate gifting involves understanding the recipient's needs and the occasion. Opt for high-quality items that reflect thoughtfulness and spiritual significance. Personalisation can add a special touch, making the gifts more meaningful. Consider the practicality and relevance of the gifts to ensure they are appreciated and used. Consulting a Vastu gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "What are the benefits of working with a Vastu gifting company?",
      "answer": "Working with a Vastu gifting company ensures you receive high-quality, well-crafted items that reflect the spiritual significance and the company's appreciation. These companies offer various customisation options to make your gifts unique and personal. They provide expertise in selecting and designing gifts that suit your needs and preferences. Reliable delivery services and excellent customer support are additional benefits. Partnering with a reputable company ensures a seamless and satisfactory gifting experience.",
      "open": false
    },
    {
      "question": "Can I order Vastu gifts for corporate gifting in bulk?",
      "answer": "Yes, you can order Vastu gifts from various gifting companies in bulk for corporate gifting. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a Vastu gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some premium Vastu gift options?",
      "answer": "Premium Vastu gift options include bespoke Vastu accessories, luxury Vastu gift boxes, and high-end Vastu products. Handcrafted items such as Vastu pyramids, Feng Shui, and personalised Vastu gifts are also appreciated. Consider premium Vastu idols, luxury Vastu accessories, and exclusive Vastu gift sets. These gifts reflect thoughtfulness and are ideal for premium corporate gifting.",
      "open": false
    },
    {
      "question": "How do Vastu gifts enhance corporate celebrations?",
      "answer": "Vastu gifts enhance corporate celebrations by adding a touch of spiritual significance and appreciation. They are ideal for company anniversaries, client appreciation events, and employee recognition programs. These gifts reflect the company's commitment to well-being and prosperity. Customised Vastu gifts make the celebration more memorable and meaningful. They foster a positive and harmonious corporate culture.",
      "open": false
    },
    {
      "question": "What are some unique Vastu gift ideas for corporate events?",
      "answer": "Unique Vastu gift ideas for corporate events include bespoke Vastu accessories, customised Vastu gift boxes, and handcrafted Vastu products. High-end items such as luxury Vastu idols, Feng Shui items, and personalised Vastu gifts are also popular. Consider premium Vastu gift sets, luxury Vastu accessories, and exclusive Vastu gifts. The key is to choose gifts that reflect the recipient's needs and the event's significance.",
      "open": false
    },
    {
      "question": "Why should companies invest in branded Vastu gifts?",
      "answer": "Companies should invest in branded Vastu gifts to show appreciation and promote well-being among their employees, clients, and partners. These gifts enhance corporate relationships and convey a commitment to harmony and prosperity. Branded Vastu gifts are highly valued and leave a lasting impression on the recipients. They also foster a positive and respectful corporate culture. Investing in branded gifts promotes a strong and prestigious brand image.",
      "open": false
    },
    {
      "question": "How can I ensure the quality of Vastu gifts for corporate gifting?",
      "answer": "Ensuring the quality of Vastu gifts for corporate gifting involves selecting reputable gifting companies and suppliers. Check reviews and testimonials to gauge the reliability and quality of their products. Opt for companies with a proven track record of high-quality craftsmanship and customisation. Regularly review samples and provide clear specifications for the customisation. Partnering with a trusted Vastu gifting company ensures the quality and satisfaction of your gifts.",
      "open": false
    },
    {
      "question": "What are the latest trends in Vastu corporate gifting?",
      "answer": "The latest trends in Vastu corporate gifting include eco-friendly products, luxury Vastu accessories, and bespoke Vastu gift sets. Sustainable and environmentally friendly items are increasingly popular. Personalised Vastu gifts, such as custom Vastu pyramids and engraved Vastu accessories, are in demand. Handcrafted items that reflect traditional craftsmanship are also trending. Staying updated with these trends ensures your gifts are relevant and appreciated.",
      "open": false
    },
    {
      "question": "What are the benefits of customising Vastu corporate gifts?",
      "answer": "Customising Vastu corporate gifts adds a personal touch that enhances the gift's value and spiritual significance. It shows that extra thought and effort have been put into selecting the gift. Personalisation can include engraving names, adding personal messages, or incorporating the company logo. Customised gifts are more memorable and appreciated by the recipients. They help in fostering stronger personal and corporate relationships.",
      "open": false
    },
    {
      "question": "How do Vastu gifts benefit employee recognition programs?",
      "answer": "Vastu gifts benefit employee recognition programs by adding a touch of spiritual significance and appreciation to the recognition process. They make employees feel valued and appreciated, boosting morale and motivation. Customised Vastu gifts reflect the company's commitment to recognising individual achievements. They enhance job satisfaction and loyalty, promoting a positive work environment. Vastu gifts are more impactful and meaningful than generic awards.",
      "open": false
    },
    {
      "question": "Can Vastu gifts be ordered in bulk?",
      "answer": "Yes, Vastu gifts can be ordered in bulk from most gifting companies. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a Vastu gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some cost-effective Vastu gift options?",
      "answer": "Cost-effective Vastu gift options include branded Vastu accessories, personalised Vastu products, and festive Vastu hampers. Consider items like premium Vastu pyramids, custom Vastu gift sets, and customised Vastu gifts. The key is choosing gifts that reflect the recipient's needs and the occasion without compromising quality. Cost-effective gifts can still make a significant impact when chosen thoughtfully.",
      "open": false
    },
    {
      "question": "Why is personalisation important in Vastu corporate gifting?",
      "answer": "Personalisation is important in Vastu corporate gifting because it adds a unique and meaningful touch to the gifts. It shows that the giver has put thought into selecting the gift, making it more memorable and appreciated. Personalised gifts reflect the recipient's needs and preferences. They enhance the overall impact of the gift, fostering stronger relationships. Personalisation also helps in promoting brand recall and loyalty.",
      "open": false
    },
    {
      "question": "How can Vastu gifts be used for client retention?",
      "answer": "Vastu gifts can be used for client retention by showing appreciation and promoting well-being. Customised gifts reflect thoughtfulness and attention to detail, making clients feel valued. They create a positive association with the brand, fostering loyalty. Regular gifting of Vastu items can enhance client satisfaction and retention rates. These gifts are a constant reminder of the company's appreciation and commitment to prosperity.",
      "open": false
    },
    {
      "question": "What are some good Vastu gifts for employees?",
      "answer": "Good Vastu gifts for employees include customised Vastu accessories, branded Vastu products, and premium Vastu gift boxes. Consider festive Vastu hampers, luxury Vastu pyramids, and personalised Vastu gifts. These gifts reflect the company's appreciation and foster a positive work environment. Customisation with the company's logo or festive branding adds a personal touch. Consulting a Vastu gifting company can help select the best gifts for employees.",
      "open": false
    },
    {
      "question": "How do Vastu gifts enhance personal celebrations?",
      "answer": "Vastu gifts enhance personal celebrations by adding a touch of spiritual significance and thoughtfulness. They are ideal for birthdays, anniversaries, and personal milestones. Customised Vastu gifts make the celebration more memorable and meaningful. They reflect the giver's appreciation and care. Incorporating Vastu principles into personal gifts adds a unique and positive dimension to the celebration.",
      "open": false
    },
    {
        "question": "How can I ensure the timely delivery of Vastu corporate gifts?",
        "answer": "Ensuring the timely delivery of Vastu corporate gifts involves planning and ordering in advance. Choose a reputable gifting company with a proven track record of reliable delivery services. Provide clear specifications and deadlines to the vendor. Regularly follow up with the vendor to track the progress of your order. Partnering with a trusted Vastu gifting company ensures timely and hassle-free delivery.",
        "open": false
      },
      {
        "question": "What are some popular Vastu gifts for clients?",
        "answer": "Popular Vastu gifts for clients include luxury Vastu gift boxes, branded Vastu accessories, and personalised Vastu products. Handcrafted items such as premium Vastu pyramids, festive Vastu hampers, and bespoke Vastu gift sets are also appreciated. Consider high-end Vastu products, fine Vastu accessories, and exclusive Vastu gifts. These gifts reflect the company's appreciation and foster a positive business relationship.",
        "open": false
      },
      {
        "question": "What are some cost-effective Vastu gift options for small businesses?",
        "answer": "Cost-effective Vastu gift options for small businesses include branded Vastu accessories, personalised Vastu products, and festive Vastu hampers. Consider items like premium Vastu pyramids, custom Vastu gift sets, and customised Vastu gifts. The key is choosing gifts that reflect the recipient's needs and the occasion without compromising quality. Cost-effective gifts can still make a significant impact when chosen thoughtfully.",
        "open": false
      },
      {
        "question": "How do Vastu gifts enhance client relationships?",
        "answer": "Vastu gifts enhance client relationships by showing appreciation and fostering goodwill. Thoughtful and well-chosen gifts reflect the company's gratitude for the client's support and partnership. Regular gifting of Vastu items helps maintain and strengthen client relationships, promoting long-term business success. Customised gifts with the company logo or personal messages add a special touch, making the gifts more memorable.",
        "open": false
      },
      {
        "question": "What are some premium Vastu gifts for senior executives?",
        "answer": "Premium Vastu gifts for senior executives include bespoke Vastu accessories, luxury Vastu gift boxes, and high-end Vastu products. Handcrafted items such as elegant Vastu pyramids, fine Vastu accessories, and personalised Vastu gifts are also appreciated. Consider festive Vastu hampers, luxury Vastu gift sets, and exclusive Vastu gifts. The key is to choose gifts that reflect the festive spirit and the executive's preferences.",
        "open": false
      },
      {
        "question": "Why is eco-friendly gifting becoming popular in Vastu corporate gifting?",
        "answer": "Eco-friendly gifting is becoming popular in Vastu corporate gifting because it reflects a commitment to sustainability and environmental responsibility. Eco-friendly gifts are made from sustainable materials and processes, reducing the environmental impact. Recipients who value sustainability highly appreciate these gifts. Choosing eco-friendly Vastu gifts also enhances the company's socially responsible and environmentally conscious image.",
        "open": false
      },
      {
        "question": "How do I choose the best vendor for Vastu gifts?",
        "answer": "Choosing the best vendor for Vastu gifts involves researching reputable gifting companies and suppliers. Check reviews, testimonials, and portfolios to gauge their products' and services' quality and reliability. Opt for vendors with a proven track record of high-quality craftsmanship and customisation. Request samples and provide clear specifications for the customisation. Partnering with a trusted Vastu gifting company ensures a seamless and satisfactory gifting experience.",
        "open": false
      },
      {
        "question": "What are some innovative Vastu gift ideas for tech-savvy employees?",
        "answer": "Innovative Vastu gift ideas for tech-savvy employees include personalised tech gadgets with Vastu accessories, branded tech accessories with Vastu products, and luxury tech devices paired with Vastu gifts. Consider wireless chargers with custom Vastu products, Bluetooth speakers with premium Vastu accessories, and smartwatches with bespoke Vastu gifts. Customising these items with the company logo or personal messages adds a special touch.",
        "open": false
      },
      {
        "question": "How do Vastu gifts enhance corporate culture?",
        "answer": "Vastu gifts enhance corporate culture by showcasing the company's values and appreciation for its employees, clients, and partners. Thoughtful and well-chosen gifts demonstrate the company's commitment to quality, inclusivity, and goodwill. Customisation with the company logo or branding elements enhances the corporate identity. Vastu gifts that reflect the company's culture foster a positive and respectful work environment.",
        "open": false
      },
      {
        "question": "What are some good Vastu gifts for clients under 5000?",
        "answer": "Good Vastu gifts for clients under 5000 include bespoke Vastu accessories, custom-designed Vastu products, and personalised plaques. Consider engraved Vastu gifts, stylish Vastu accessories, and contemporary Vastu sets. The key is choosing gifts that reflect the recipient's needs and the occasion without compromising quality. Consulting a Vastu gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do Vastu gifts promote corporate social responsibility?",
        "answer": "Vastu gifts promote corporate social responsibility by reflecting the company's commitment to sustainability and community engagement. Recipients highly appreciate eco-friendly and socially responsible Vastu gifts. These gifts enhance the company's image as socially responsible and environmentally conscious. Choosing Vastu gifts that support local artisans and charitable causes also promotes corporate social responsibility.",
        "open": false
      },
      {
        "question": "What are some unique Vastu gift ideas for startups?",
        "answer": "Unique Vastu gift ideas for startups include branded tech gadgets with Vastu accessories, personalised Vastu products with startup branding, and luxury tech devices paired with Vastu gifts. Consider wireless chargers with custom Vastu products, personalised notebooks with engraved Vastu accessories, and premium Vastu gifts with startup logos. The key is to choose gifts that reflect the festive spirit and the startup's innovative culture.",
        "open": false
      },
      {
        "question": "How can Vastu gifts be used for marketing and promotion?",
        "answer": "Vastu gifts can be used for marketing and promotion by enhancing brand visibility and recall. Customising gifts with the company logo or branding elements increases brand awareness. Thoughtful and well-chosen Vastu gifts create positive associations with the brand and foster goodwill. Regular gifting of Vastu items helps maintain and strengthen business relationships, promoting long-term success.",
        "open": false
      },
      {
        "question": "What are some good Vastu gifts for employees under 1000?",
        "answer": "Good Vastu gifts for employees under 1000 include personalised Vastu accessories, branded Vastu products, and festive Vastu hampers. Consider items like premium Vastu pyramids, custom Vastu gift sets, and customised Vastu gifts. The key is choosing gifts that reflect the festive spirit and the company's appreciation without compromising quality. Consulting a Vastu gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do Vastu gifts enhance client retention?",
        "answer": "Vastu gifts enhance client retention by showing appreciation and fostering goodwill. Thoughtful and well-chosen gifts reflect the company's gratitude for the client's support and partnership. Regular gifting of Vastu items helps maintain and strengthen client relationships, promoting long-term business success. Customised gifts with the company logo or personal messages add a special touch, making the gifts more memorable.",
        "open": false
      },
      {
        "question": "What are some unique Vastu gift ideas for corporate events?",
        "answer": "Unique Vastu gift ideas for corporate events include bespoke Vastu accessories, customised Vastu gift boxes, and handcrafted Vastu products. High-end items such as luxury Vastu idols, Feng Shui items, and personalised Vastu gifts are also popular. Consider premium Vastu gift sets, luxury Vastu accessories, and exclusive Vastu gifts. The key is to choose gifts that reflect the recipient's needs and the event's significance.",
        "open": false
      },
      {
        "question": "How do Vastu gifts benefit employee engagement programs?",
        "answer": "Vastu gifts benefit employee engagement programs by showing appreciation and recognition for employees' efforts and contributions. Thoughtful and well-chosen gifts enhance job satisfaction and motivation. Customised gifts with the company logo or personal messages add a special touch. Regular gifting Vastu items during corporate events fosters a positive work environment and strengthens employee engagement.",
        "open": false
      },
      {
        "question": "What are some good Vastu gifts for clients under 3000?",
        "answer": "Good Vastu gifts for clients under 3000 include luxury Vastu gift boxes, bespoke Vastu accessories, and personalised Vastu products. Consider high-end Vastu products, fine Vastu accessories, and exclusive Vastu gifts. The key is choosing gifts that reflect the recipient's needs and the occasion without compromising quality. Consulting a Vastu gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How can Vastu gifts be used for team building?",
        "answer": "Vastu gifts can be used for team building by showing appreciation and fostering goodwill among team members. Thoughtful and well-chosen gifts reflect the company's gratitude for the team's efforts and contributions. Regular gifting Vastu items during team-building activities helps maintain and strengthen team cohesion, promoting a positive and collaborative work environment. Customised gifts with the company logo or personal messages add a special touch, making the gifts more meaningful.",
        "open": false
      }
  ]
  
return (
<>
<MetaTags
        title="Premier Vastu Corporate Gifting Company in India"
        description="Discover exquisite Vastu corporate gifts from India's leading Vastu gifting company. Perfect for employees, clients, and corporate events."
        keywords="Vastu Corporate Gifting, Vastu gifting company in India, Vastu corporate gifting, Vastu corporate gifting ideas, Vastu corporate gifting accessories, Vastu gifting company, Vastu Products gifting vendors, Vastu in corporate office gifting vendors, Vastu in corporate gifting, Vastu gifting for clients in a corporate office, Vastu gifting for clients, Vastu gifting for employees in a corporate office, Vastu gifting for employees, Vastu gifting on festivals, Vastu gifting for corporate events, Vastu gifting in exhibition, Vastu store for gifting items, Vastu company for gifting items"
      />
<ServiceHeader title="Vastu Corporate Gifting" imageUrl={`${process.env.PUBLIC_URL}/images/vastubn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Significance of Vastu Corporate Gifting</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Vastu corporate gifting is a meaningful way to enhance business relationships and promote positivity and harmony in the workplace. Vastu, an ancient Indian science of architecture, emphasises balance and harmony in physical spaces. By incorporating Vastu principles into corporate gifts, businesses can convey their commitment to the well-being and prosperity of their clients, employees, and partners.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Leading Vastu gifting companies in India offer a wide range of aesthetically pleasing and spiritually significant products. From Vastu accessories for corporate offices to unique Vastu gift ideas for clients and employees, these gifts are perfect for festivals, corporate events, and special occasions. Vastu corporate gifts reflect appreciation and promote positive energy and harmony in the recipient's environment.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Choosing the perfect Vastu corporate gift involves understanding the recipient's needs and the occasion. Here are some key aspects that make Vastu corporate gifting a popular choice:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances the spirit of well-being and harmony</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthens business relationships</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflects appreciation and gratitude</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for various types of recipients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers a wide range of Vastu products</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Ideal for both employees and clients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Available in premium and aesthetically pleasing options</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Can be ordered in bulk for corporate needs</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes lasting memories and positive energy</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Supports local artisans and craftsmanship</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Vastu corporate gifting is more than just a gesture; it celebrates harmony, prosperity, and well-being.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Vastu Corporate Gifting?</h2>
      <p >Selecting the perfect Vastu corporate gifts requires a keen understanding of quality, design, and spiritual significance. Giftingy is a premier Vastu gifting company offering a curated selection of the finest Vastu products. Here's why Giftingy should be your top choice for Vastu corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/vastuimg2.jpg`} alt="Vastu Corporate Gifting Solutions" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialise in creating Vastu corporate gifts that resonate with the recipient and reflect the company's commitment to well-being and prosperity. Our corporate gift ideas include beautifully crafted items, from Vastu accessories for corporate offices to unique Vastu gifts for clients and employees. Whether you are looking for Vastu gifts for corporate events or festivals, our collection caters to diverse tastes and preferences.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of our key strengths is our ability to offer personalised Vastu gifts that reflect the recipient's needs and the occasion. We pride ourselves on being a leading Vastu gifting company that delivers excellence in craftsmanship. Our products are sourced from the best vendors, ensuring that every item meets the highest quality and spiritual significance standards.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy specialises in bulk gifting, making us the perfect partner for corporate celebrations. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From unique Vastu gifting ideas to premium Vastu accessories, we offer various choices to cater to your needs. Our commitment to customer satisfaction and ability to create lasting impressions make us the preferred choice for Vastu corporate gifting in India.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable Vastu gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products with meticulous craftsmanship
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of Vastu gifting ideas for various recipients
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as a Vastu gifting company in India
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Convenient online ordering and customisation options
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive selection of beautifully crafted Vastu gifts
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your corporate gifting needs ensures that your gifts are meaningful, spiritually significant, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exceptional Vastu Corporate Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional Vastu corporate gifting solutions catering to your needs and preferences. Our unique selling propositions (USPs) set us apart as India's leading Vastu gifting company. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your Vastu gifts, making them more meaningful and spiritually significant. Our branding on gifts ensures that your company remains at the forefront of the recipient's mind, enhancing brand recall and visibility. We provide value for money by offering high-quality Vastu gifts that fit within your budget, ensuring you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/vastuimg3.jpg`} alt="Unique Vastu Gift Ideas" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="luxuryHomeDecor" />

<FaqPage faqs={faqs} />
</>
);
};
export default VastuCorporateGifting;