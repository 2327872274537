import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/navratriimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised Durga Puja and Navratri gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality festive gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const NavratriCorporateGifting = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
        
      }, []);
const faqs = [
    {
      "question": "What are good Durga Puja corporate gifts?",
      "answer": "Good Durga Puja corporate gifts include traditional festive hampers, premium gift boxes, and personalised cultural items. Popular choices are handcrafted items, gourmet sweets, and custom festive accessories. The key is to choose gifts that reflect the recipient's preferences and the festival's cultural significance. Consulting a Durga Puja corporate gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "Why is corporate gifting during Durga Puja and Navratri important?",
      "answer": "Corporate gifting during Durga Puja and Navratri is important because it expresses appreciation and enhances the festive spirit. These gifts strengthen business relationships and foster a positive environment among employees, clients, and partners. Thoughtful and well-chosen festive gifts reflect the company's appreciation and commitment to cultural values. They also promote a positive work environment and enhance brand visibility.",
      "open": false
    },
    {
      "question": "What are some popular Navratri gifts for employees?",
      "answer": "Popular Navratri employee gifts include traditional festive hampers, customised gift boxes, and personalised cultural items. Handcrafted items such as gourmet sweets, premium festive accessories, and custom gift sets are also appreciated. Consider elegant Navratri hampers, high-end festive gifts, and bespoke Navratri accessories. These gifts reflect the company's appreciation and foster a positive work environment.",
      "open": false
    },
    {
      "question": "How do I choose the right Durga Puja and Navratri gifts for corporate gifting?",
      "answer": "Choosing the right Durga Puja and Navratri gifts for corporate gifting involves understanding the recipient's preferences and the cultural significance of the occasion. Opt for high-quality items that reflect thoughtfulness and festive cheer. Personalisation can add a special touch, making the gifts more meaningful. Consider the practicality and relevance of the gifts to ensure they are appreciated and used. Consulting a corporate gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "What are the benefits of working with a Durga Puja and Navratri corporate gifting company?",
      "answer": "Working with a Durga Puja and Navratri corporate gifting company ensures you receive high-quality, well-crafted items that reflect the cultural significance and the company's appreciation. These companies offer various customisation options to make your gifts unique and personal. They provide expertise in selecting and designing gifts that suit your needs and preferences. Reliable delivery services and excellent customer support are additional benefits. Partnering with a reputable company ensures a seamless and satisfactory gifting experience.",
      "open": false
    },
    {
      "question": "Can I order Durga Puja and Navratri gifts for corporate gifting in bulk?",
      "answer": "Yes, you can order bulk Durga Puja and Navratri gifts for corporate gifting from various gifting companies. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a corporate gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some premium Durga Puja and Navratri gift options?",
      "answer": "Premium Durga Puja and Navratri gift options include bespoke festive hampers, luxury gift boxes, and high-end cultural items. Handcrafted items such as elegant festive accessories, fine cultural artefacts, and personalised gift sets are also appreciated. Consider premium festive hampers, luxury Durga Puja gifts, and exclusive Navratri accessories. These gifts reflect thoughtfulness and are ideal for premium corporate gifting.",
      "open": false
    },
    {
      "question": "How do Durga Puja and Navratri gifts enhance corporate celebrations?",
      "answer": "Durga Puja and Navratri gifts enhance corporate celebrations by adding a touch of cultural significance and appreciation. They are ideal for occasions such as company anniversaries, client appreciation events, and employee recognition programs. These gifts reflect the company's commitment to cultural values and festive cheer. Customised festive gifts make the celebration more memorable and meaningful. They foster a positive and respectful corporate culture.",
      "open": false
    },
    {
      "question": "What are some unique Durga Puja and Navratri gift ideas for corporate events?",
      "answer": "Unique Durga Puja and Navratri gift ideas for corporate events include bespoke festive hampers, customised gift boxes, and handcrafted cultural items. High-end luxury festive accessories, fine cultural artefacts, and personalised gift sets are also popular. Consider premium Durga Puja hampers, luxury Navratri gifts, and exclusive festive accessories. The key is to choose gifts that reflect the recipient's preferences and the event's significance.",
      "open": false
    },
    {
      "question": "Why should companies invest in branded Durga Puja and Navratri gifts?",
      "answer": "Companies should invest in branded Durga Puja and Navratri gifts to show appreciation and celebrate cultural values among their employees, clients, and partners. These gifts enhance corporate relationships and convey a commitment to cultural appreciation and festive cheer. Branded festive gifts are highly valued and leave a lasting impression on the recipients. They also foster a positive and respectful corporate culture. Investing in branded gifts promotes a strong and prestigious brand image.",
      "open": false
    },
    {
      "question": "How can I ensure the quality of Durga Puja and Navratri gifts for corporate gifting?",
      "answer": "Ensuring the quality of Durga Puja and Navratri gifts for corporate gifting involves selecting reputable gifting companies and suppliers. Check reviews and testimonials to gauge the reliability and quality of their products. Opt for companies with a proven track record of high-quality craftsmanship and customisation. Regularly review samples and provide clear specifications for the customisation. Partnering with a trusted corporate gifting company ensures the quality and satisfaction of your gifts.",
      "open": false
    },
    {
      "question": "What are the latest trends in Durga Puja and Navratri corporate gifting?",
      "answer": "The latest trends in Durga Puja and Navratri corporate gifting include eco-friendly products, luxury festive hampers, and bespoke cultural gift sets. Sustainable and environmentally friendly items are increasingly popular. Personalised festive gifts such as custom gift boxes and engraved cultural items are in demand. Handcrafted items that reflect traditional craftsmanship are also trending. Staying updated with these trends ensures your gifts are relevant and appreciated.",
      "open": false
    },
    {
      "question": "What are the benefits of customising Durga Puja and Navratri corporate gifts?",
      "answer": "Customising Durga Puja and Navratri corporate gifts adds a personal touch that enhances the gift's value and cultural significance. It shows that extra thought and effort have been put into selecting the gift. Personalisation can include engraving names, adding personal messages, or incorporating the company logo. Customised gifts are more memorable and appreciated by the recipients. They help in fostering stronger personal and corporate relationships.",
      "open": false
    },
    {
      "question": "How do Durga Puja and Navratri gifts benefit employee recognition programs?",
      "answer": "Durga Puja and Navratri gifts benefit employee recognition programs by adding a touch of cultural significance and appreciation to the recognition process. They make employees feel valued and appreciated, boosting morale and motivation. Customised festive gifts reflect the company's commitment to recognising individual achievements. They enhance job satisfaction and loyalty, promoting a positive work environment. Festive gifts are more impactful and meaningful than generic awards.",
      "open": false
    },
    {
      "question": "Can Durga Puja and Navratri gifts be ordered in bulk?",
      "answer": "Yes, most gifting companies can order Durga Puja and Navratri gifts in bulk. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a corporate gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some cost-effective Durga Puja and Navratri gift options?",
      "answer": "Cost-effective Durga Puja and Navratri gift options include branded festive accessories, personalised cultural items, and festive hampers. Consider premium festive gift sets, cultural artefacts, and customised gift boxes. The key is choosing gifts that reflect the recipient's preferences and the occasion without compromising quality. Cost-effective gifts can still make a significant impact when chosen thoughtfully.",
      "open": false
    },
    {
      "question": "Why is personalisation important in Durga Puja and Navratri corporate gifting?",
      "answer": "Personalisation is important in Durga Puja and Navratri corporate gifting because it adds a unique and meaningful touch to the gifts. It shows that the giver has put thought into selecting the gift, making it more memorable and appreciated. Personalised gifts reflect the recipient's preferences and the festival's cultural significance. They enhance the overall impact of the gift, fostering stronger relationships. Personalisation also helps in promoting brand recall and loyalty.",
      "open": false
    },
    {
      "question": "How can Durga Puja and Navratri gifts be used for client retention?",
      "answer": "Durga Puja and Navratri gifts can be used to retain clients by showing appreciation and celebrating cultural values. Customised gifts reflect thoughtfulness and attention to detail, making clients feel valued. They create a positive association with the brand and enhance client relationships. Well-chosen festive gifts can strengthen business ties and encourage repeat business. They also demonstrate the company's commitment to maintaining long-term and meaningful relationships.",
      "open": false
    },
    {
        "question": "What are some unique Durga Puja and Navratri gift ideas for corporate events?",
        "answer": "Unique Durga Puja and Navratri gift ideas for corporate events include bespoke festive hampers, customised gift boxes, and handcrafted cultural items. High-end luxury festive accessories, fine cultural artefacts, and personalised gift sets are also popular. Consider premium Durga Puja hampers, luxury Navratri gifts, and exclusive festive accessories. The key is to choose gifts that reflect the recipient's preferences and the event's significance.",
        "open": false
      },
      {
        "question": "How can I ensure the timely delivery of Durga Puja and Navratri corporate gifts?",
        "answer": "Ensuring timely delivery of Durga Puja and Navratri corporate gifts involves planning and ordering in advance. Choose a reputable gifting company with a proven track record of reliable delivery services. Provide clear specifications and deadlines to the vendor. Regularly follow up with the vendor to track the progress of your order. Partnering with a trusted corporate gifting company ensures timely and hassle-free delivery.",
        "open": false
      },
      {
        "question": "What are some popular Durga Puja and Navratri gifts for clients?",
        "answer": "Popular Durga Puja and Navratri gifts for clients include luxury festive hampers, branded cultural items, and personalised gift boxes. Handcrafted items such as premium festive accessories, festive hampers, and bespoke gift sets are also appreciated. Consider high-end cultural artefacts, fine festive accessories, and exclusive gift boxes. These gifts reflect the company's appreciation and foster a positive business relationship.",
        "open": false
      },
      {
        "question": "What are some cost-effective Durga Puja and Navratri gift options for small businesses?",
        "answer": "Cost-effective Durga Puja and Navratri gift options for small businesses include branded festive accessories, personalised cultural items, and festive hampers. Consider premium festive gift sets, custom cultural artefacts, and customised gift boxes. The key is choosing gifts that reflect the recipient's preferences and the occasion without compromising quality. Cost-effective gifts can still make a significant impact when chosen thoughtfully.",
        "open": false
      },
      {
        "question": "How do Durga Puja and Navratri gifts enhance client relationships?",
        "answer": "Durga Puja and Navratri gifts enhance client relationships by showing appreciation and fostering goodwill. Thoughtful and well-chosen gifts reflect the company’s appreciation for the client’s business and partnership. Regular gifting of festive items helps maintain and strengthen client relationships, promoting long-term business success. Customised gifts with the company logo or personal messages add a special touch, making the gifts more memorable.",
        "open": false
      },
      {
        "question": "What are some premium Durga Puja and Navratri gifts for senior executives?",
        "answer": "Premium Durga Puja and Navratri gifts for senior executives include bespoke festive accessories, luxury gift boxes, and high-end cultural items. Handcrafted items such as elegant festive artefacts, fine cultural accessories, and personalised gift sets are also appreciated. Consider festive hampers, luxury Durga Puja gifts, and exclusive Navratri accessories. The key is choosing gifts that reflect the festive spirit and the recipient’s status.",
        "open": false
      },
      {
        "question": "Why is eco-friendly gifting becoming popular in Durga Puja and Navratri corporate gifting?",
        "answer": "Eco-friendly gifting is becoming popular in Durga Puja and Navratri corporate gifting because it reflects a commitment to sustainability and environmental responsibility. Eco-friendly gifts are made from sustainable materials and processes, reducing the environmental impact. Recipients who value sustainability highly appreciate these gifts. Choosing eco-friendly festive gifts enhances the company's responsible and environmentally conscious image.",
        "open": false
      },
      {
        "question": "How do I choose the best vendor for Durga Puja and Navratri gifts?",
        "answer": "Choosing the best vendor for Durga Puja and Navratri gifts involves researching reputable gifting companies and suppliers. Check reviews, testimonials, and portfolios to gauge their products' and services' quality and reliability. Opt for vendors with a proven track record of high-quality craftsmanship and customisation. Request samples and provide clear specifications for the customisation. Partnering with a trusted corporate gifting company ensures a seamless and satisfactory gifting experience.",
        "open": false
      },
      {
        "question": "What are some innovative Durga Puja and Navratri gift ideas for tech-savvy employees?",
        "answer": "Innovative Durga Puja and Navratri gift ideas for tech-savvy employees include personalised tech gadgets with festive accessories, branded tech accessories with cultural items, and luxury tech devices with festive gifts. Consider wireless chargers with custom festive products, Bluetooth speakers with premium cultural accessories, and smartwatches with bespoke festive gifts. Customising these items with the company logo or personal messages adds a special touch.",
        "open": false
      },
      {
        "question": "How do Durga Puja and Navratri gifts enhance corporate culture?",
        "answer": "Durga Puja and Navratri gifts enhance corporate culture by showcasing the company’s appreciation for its employees, clients, and partners. Thoughtful and well-chosen gifts demonstrate the company’s commitment to cultural values, inclusivity, and festive cheer. Customisation with the company logo or branding elements enhances the corporate identity. Festive gifts that reflect the company’s values foster a positive and respectful work environment.",
        "open": false
      },
      {
        "question": "What are some good Durga Puja and Navratri gifts for clients under 5000?",
        "answer": "Good Durga Puja and Navratri gifts for clients under 5000 include bespoke festive accessories, custom-designed cultural items, and personalised gift boxes. Consider engraved gifts, stylish cultural artefacts, and contemporary festive sets. The key is choosing gifts that reflect the recipient's preferences and the occasion without compromising quality. Consulting a corporate gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do Durga Puja and Navratri gifts promote corporate social responsibility?",
        "answer": "Durga Puja and Navratri gifts promote corporate social responsibility by reflecting the company’s commitment to sustainability and community engagement. Recipients highly appreciate eco-friendly and socially responsible festive gifts. These gifts enhance the company’s socially responsible and environmentally conscious image. Choosing festive gifts that support local artisans and charitable causes also promotes corporate social responsibility.",
        "open": false
      },
      {
        "question": "What are some unique Durga Puja and Navratri gift ideas for startups?",
        "answer": "Unique Durga Puja and Navratri gift ideas for startups include branded tech gadgets with festive accessories, personalised cultural items with startup branding, and luxury tech devices paired with festive gifts. Consider wireless chargers with custom festive products, personalised notebooks with engraved cultural accessories, and premium festive gifts with startup logos. The key is to choose gifts that reflect the festive spirit and the startup’s innovative culture.",
        "open": false
      },
      {
        "question": "How can Durga Puja and Navratri gifts be used for marketing and promotion?",
        "answer": "Durga Puja and Navratri gifts can be used for marketing and promotion by enhancing brand visibility and recall. Customising gifts with the company logo or branding elements increases brand awareness. Thoughtful and well-chosen festive gifts create positive associations with the brand and foster goodwill. Regular gifting of festive items helps maintain and strengthen business relationships, promoting long-term success.",
        "open": false
      },
      {
        "question": "What are some good Durga Puja and Navratri gifts for employees under 1000?",
        "answer": "Good Durga Puja and Navratri gifts for employees under 1000 include personalised festive accessories, branded cultural items, and festive hampers. Consider premium festive gift sets, cultural artefacts, and customised gift boxes. The key is choosing gifts that reflect the festive spirit and the company’s appreciation without compromising quality. Consulting a corporate gifting company can provide additional ideas and options.",
        "open": false
      },
      {
        "question": "How do Durga Puja and Navratri gifts enhance client retention?",
        "answer": "Durga Puja and Navratri gifts enhance client retention by showing appreciation and fostering goodwill. Thoughtful and well-chosen gifts reflect the company’s appreciation for the client’s business and partnership. Regular gifting of festive items helps maintain and strengthen client relationships, promoting long-term business success. Customised gifts with the company logo or personal messages add a special touch, making the gifts more memorable.",
        "open": false
      },
      {
        "question": "What are some unique Durga Puja and Navratri gift ideas for corporate events?",
        "answer": "Unique Durga Puja and Navratri gift ideas for corporate events include bespoke festive accessories, customised gift boxes, and handcrafted cultural items. High-end luxury festive accessories, fine cultural artefacts, and personalised gift sets are also popular. Consider premium Durga Puja hampers, luxury Navratri gifts, and exclusive festive accessories. The key is to choose gifts that reflect the recipient's preferences and the event's significance.",
        "open": false
      },
      {
        "question": "How do Durga Puja and Navratri gifts benefit employee engagement programs?",
        "answer": "Durga Puja and Navratri gifts benefit employee engagement programs by showing appreciation and recognition for employees' contributions. Thoughtful and well-chosen gifts enhance job satisfaction and motivation. Customised gifts with the company logo or personal messages add a special touch. Regular gifting of festive items fosters a positive work environment and strengthens employee loyalty and engagement.",
        "open": false
      }
  ]
  
return (
<>
<MetaTags
        title="Premier Durga Puja and Navratri Corporate Gifting Company in India"
        description="Discover exquisite Durga Puja and Navratri corporate gifts from India's leading corporate gifting company. They are perfect for employees, clients, and corporate events."
        keywords="Durga Puja Corporate gifting company, Durga puja corporate gifts, Durga puja corporate gifts for employees, Navratri Gifts Company, Dussehra Gift Company, Durga Puja Gift Company, Navratri Gifts for Employees, Navratri Gifts for clients, Navratri Corporate Gifts Company, Durga Puja Corporate Gift Company, Navratri & Durga Puja Corporate Gifts, Navratri Corporate gifting, Durga Puja Corporate Gifting, Dussehra Corporate Gifting, Durga puja festival gift, Navratri festival gift, Dussehra festival gift"
      />
<ServiceHeader title="Durga Puja and Navratri Corporate Gifting" imageUrl={`${process.env.PUBLIC_URL}/images/navratribn.png`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Essence of Durga Puja and Navratri Corporate Gifting</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Durga Puja and Navratri are two of India's most significant festivals celebrated with great enthusiasm and devotion. These festivals symbolise the triumph of good over evil and are marked by vibrant celebrations, cultural activities, and devotional practices. Corporate gifting during these festivals is a meaningful way to show appreciation and strengthen business relationships. Businesses can convey gratitude and foster a positive environment among employees, clients, and partners by choosing thoughtful and culturally significant gifts.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Leading corporate gifting companies in India offer various options to cater to different tastes and preferences during Durga Puja and Navratri. From traditional gifts to contemporary hampers, these companies ensure that every gift is special and reflects the festive spirit. Durga Puja and Navratri corporate gifts reflect appreciation, enhance festive cheer, and foster stronger bonds within the corporate community.
      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Choosing the perfect corporate gift for these festivals involves understanding the recipient's preferences and the cultural significance of the occasion. Here are some key aspects that make Durga Puja and Navratri corporate gifting a popular choice:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances the festive spirit and cultural significance</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthens business relationships</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflects appreciation and gratitude</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for various types of recipients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers a wide range of traditional and contemporary gifts</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Ideal for both employees and clients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Available in premium and aesthetically pleasing options</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Can be ordered in bulk for corporate needs</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes lasting memories and positive connections</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Supports local artisans and craftsmanship</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Durga Puja and Navratri corporate gifting are more than just a gesture; they are celebrationsit's of culture, tradition, and goodwill.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Durga Puja and Navratri Corporate Gifting?</h2>
      <p >Selecting the perfect Durga Puja and Navratri corporate gifts requires a keen understanding of quality, cultural significance, and customisation. Giftingy is a premier corporate gifting company offering a curated selection of the finest gifts for these festivals. Here's why Giftingy should be your top choice for Durga Puja and Navratri corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/navratriimg2.jpg`} alt="Premium Durga Puja Gifts for Employees" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialise in creating corporate gifts that resonate with the recipient and reflect the company's appreciation and festive spirit. Our corporate gift ideas include beautifully crafted items, from traditional festive hampers to contemporary gift boxes. Whether you are looking for gifts for employees, clients, or corporate events, our collection caters to diverse tastes and preferences.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of our key strengths is our ability to offer personalised gifts that reflect the recipient's preferences and the occasion. We pride ourselves on being a leading Durga Puja and Navratri corporate gifting company that delivers excellence in craftsmanship. Our products are sourced from the best vendors, ensuring that every item meets the highest quality and cultural significance standards.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy specialises in bulk gifting, making us the perfect partner for corporate celebrations. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From unique Navratri and Durga Puja gifting ideas to premium festive hampers, we offer various choices to cater to your needs. Our commitment to customer satisfaction and ability to create lasting impressions make us the preferred choice for Durga Puja and Navratri corporate gifting in India.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable festive gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products with meticulous craftsmanship
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of gifting ideas for various recipients
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as a Durga Puja and Navratri corporate gifting company in India
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Convenient online ordering and customisation options
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive selection of beautifully crafted festive gifts
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your corporate gifting needs ensures that your gifts are culturally significant, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exceptional Durga Puja and Navratri Corporate Gifts </h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional Durga Puja and Navratri corporate gifting solutions that cater to your needs and preferences. Our unique selling propositions (USPs) set us apart as a leading corporate gifting company in India. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your Durga Puja and Navratri gifts, making them more meaningful and culturally significant. Our branding on gifts ensures that your company remains at the forefront of the recipient's mind, enhancing brand recall and visibility. We provide value for money by offering high-quality gifts that fit within your budget, ensuring you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/navratriimg3.jpg`} alt="Customisable Navratri Gifts" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="luxuryHomeDecor" />

<FaqPage faqs={faqs} />
</>
);
};
export default NavratriCorporateGifting;