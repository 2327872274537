import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/easterig1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised Easter gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Enhance brand visibility with customised branding." },
    { title: "Value for Money", detail: "High-quality Easter gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative Easter gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting choices." },
];
const EasterCorporateGifts = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
      }, []);
const faqs =[
    {
      "question": "What are some popular Easter corporate gifts for employees?",
      "answer": "Popular Easter corporate gifts for employees include handmade Easter gifts, personalised gift baskets, and festive hampers filled with gourmet treats. These gifts reflect appreciation and recognition, boosting morale and valuing employees. At Giftingy, we offer a range of Easter corporate gifts for employees catering to diverse preferences, ensuring that your gifts are meaningful and memorable.",
      "open": false
    },
    {
      "question": "How do I choose the right Easter corporate gifts for clients?",
      "answer": "Choosing the right Easter corporate gifts for clients involves considering their preferences, cultural significance, and the message you want to convey. Opt for high-quality, personalised gifts that reflect your appreciation and commitment to the partnership. Giftingy offers a curated selection of Easter corporate gifts for clients designed to impress and strengthen business relationships.",
      "open": false
    },
    {
      "question": "Can I order Easter corporate gifts in bulk for my company?",
      "answer": "Giftingy specialises in Easter corporate gifts for bulk buying, making it easy for companies to order large quantities of high-quality gifts. Bulk ordering ensures consistency and timely delivery, allowing you to celebrate Easter with all your employees and clients. Our seamless process and customisation options make bulk gifting convenient and impactful.",
      "open": false
    },
    {
      "question": "What are some eco-friendly Easter corporate gift options?",
      "answer": "Eco-friendly Easter corporate gifts include items made from sustainable materials, such as organic food hampers, biodegradable packaging, and handmade decor. These gifts are environmentally responsible and reflect your company's commitment to sustainability. Giftingy offers a range of eco-friendly options that align with your values and the spirit of Easter.",
      "open": false
    },
    {
      "question": "Why should I consider handmade Easter gifts for corporate gifting?",
      "answer": "Handmade Easter gifts add a personal and authentic touch to your corporate gifting. These gifts showcase craftsmanship and attention to detail, making them more meaningful and appreciated by recipients. Giftingy offers a variety of handmade Easter gifts for corporate employees and clients, ensuring that your gifts stand out for their uniqueness and quality.",
      "open": false
    },
    {
      "question": "What makes Giftingy stand out as an Easter corporate gifting company?",
      "answer": "Giftingy stands out as a leading Easter corporate gifting company due to our commitment to quality, customisation, and customer satisfaction. We offer a wide range of Easter corporate gifts that cater to diverse needs, from luxury hampers to handmade gifts. Our ability to personalise gifts and provide seamless bulk ordering ensures that your corporate gifting experience is smooth, timely, and impactful.",
      "open": false
    },
    {
      "question": "How can I ensure that my Easter corporate gifts are unique?",
      "answer": "To ensure your Easter corporate gifts are unique, opt for customisation options such as personalised messages, company branding, or handmade items that reflect traditional craftsmanship. Giftingy offers a range of unique Easter gift basket hampers and other customised gifts that stand out for their quality and attention to detail, making your gifts memorable and distinct.",
      "open": false
    },
    {
      "question": "What are the benefits of ordering Easter corporate gifts in bulk?",
      "answer": "Ordering Easter corporate gifts in bulk offers several advantages, including cost savings, consistency in gift presentation, and the convenience of managing large orders efficiently. Bulk ordering also ensures that all recipients receive their gifts on time, making it easier to coordinate corporate celebrations. Giftingy provides tailored solutions for Easter corporate gifting bulk, ensuring a hassle-free and cost-effective experience.",
      "open": false
    },
    {
      "question": "How do I choose the best Easter gift basket hampers for corporate gifting?",
      "answer": "When choosing Easter gift basket hampers for corporate gifting, consider your recipients' preferences, the quality of the items included, and the overall presentation of the hamper. Giftingy offers a curated selection of Easter gift basket hampers perfect for clients and employees, combining premium products with elegant packaging to create a lasting impression.",
      "open": false
    },
    {
      "question": "Why are handmade Easter gifts popular in corporate gifting?",
      "answer": "Handmade Easter gifts are popular in corporate gifting because they add a personal touch and reflect high craftsmanship. These gifts are often unique, showcasing traditional skills and attention to detail that mass-produced items may need more. Giftingy offers a variety of handmade Easter gifts for corporate employees and clients, ensuring that your gifts are meaningful and appreciated.",
      "open": false
    },
    {
      "question": "What are some cost-effective Easter corporate gifts?",
      "answer": "Cost-effective Easter corporate gifts include personalised mugs, small gourmet treats, eco-friendly items, and branded stationery. These gifts can still make a significant impact while staying within budget. Giftingy provides a range of cost-effective options ideal for large-scale corporate gifting, ensuring you can celebrate Easter without overspending.",
      "open": false
    },
    {
      "question": "How does Giftingy handle the delivery of bulk Easter corporate gifts?",
      "answer": "Giftingy has a robust logistics network that ensures the timely and safe delivery of bulk Easter corporate gifts. We coordinate closely with our clients to ensure that all gifts are delivered according to schedule, with careful attention to packaging and presentation. Whether you're ordering for a small team or a large corporation, Giftingy ensures that every gift arrives in perfect condition.",
      "open": false
    },
    {
      "question": "Can I include a personal message with my Easter corporate gifts?",
      "answer": "Yes, Giftingy offers customisation options that allow you to include a personal message with your Easter corporate gifts. Adding a personal note or message enhances the sentiment behind the gift, making it more meaningful for the recipient. Our team will work with you to ensure your message is beautifully presented alongside the gift.",
      "open": false
    },
    {
      "question": "What are the advantages of choosing luxury Easter corporate gifts?",
      "answer": "Luxury Easter corporate gifts offer several benefits, including enhanced brand perception, the ability to impress high-profile clients, and the opportunity to demonstrate your company's commitment to quality. Giftingy provides a selection of luxury Easter corporate gifts designed to make a lasting impact, reflecting your brand's values and dedication to excellence.",
      "open": false
    },
    {
      "question": "What are the latest trends in Easter corporate gifting?",
      "answer": "The latest trends in Easter corporate gifting include eco-friendly gifts, customised hampers, and handmade products. There is also a growing interest in experiential gifts, such as virtual experiences or wellness packages. Giftingy stays ahead of these trends by offering a diverse range of Easter corporate gifts that cater to modern preferences, ensuring your gifts are both current and memorable.",
      "open": false
    }
  ]
 
return (
<>
<MetaTags
        title="Premier Easter Corporate Gifting Company in India"
        description="Discover elegant Easter corporate gifts for employees and clients. Order from India's leading Easter corporate gifting company with bulk buying options and customisation."
        keywords="Easter corporate gifts, Easter corporate gifting company, Easter corporate gifts for employees, Easter corporate gifts for clients, Easter corporate gifts for bulk buying, Easter corporate gifting bulk, Easter gifting bulk, Easter gifting buy in bulk, Easter gift basket hampers, Easter hamper ideas, Handmade Easter gifts for corporates, Handmade Easter gifts for corporate employees, Handmade Easter gifts for corporate clients, Best Easter gift for corporates, Best Easter gift for corporate employees"
      />
<ServiceHeader title="Easter Corporate Gifts: Celebrate with Thoughtful Gestures" imageUrl={`${process.env.PUBLIC_URL}/images/easterbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Appeal of Easter Corporate Gifting</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Easter is a time of renewal, joy, and celebration, making it the perfect occasion for corporate gifting. Easter corporate gifts are an excellent way to show appreciation to employees, clients, and partners. These gifts, from luxurious hampers to handmade delights, add a personal touch that resonates with the festive spirit. Whether you want to reward your team or impress your clients, the right Easter gift can strengthen relationships and enhance your brand's reputation.
      </p>
     
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Corporate gifting during Easter provides an opportunity to reinforce connections and express gratitude. Leading Easter corporate gifting companies in India offer various options, from customised gift baskets to premium hampers catering to different tastes and preferences. Here's why Easter corporate gifts are a popular choice:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Celebrate the Season: Easter gifts align with the festive spirit, making them timely and relevant.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthen Relationships: Thoughtful gifts show appreciation and foster stronger business ties.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Boost Employee Morale: Recognise your team's efforts with meaningful Easter gifts.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Impress Clients: Stand out with unique and personalised Easter gifts for your clients.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Wide Range of Options: There's something for everyone, from luxury hampers to handmade delights.</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Customisation Available: Personalise your gifts to reflect your brand's identity.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Support Local Artisans: Choose handmade gifts that showcase craftsmanship.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Ideal for Bulk Buying: Order in bulk to ensure consistency and timely delivery.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Eco-Friendly Choices: Opt for sustainable and environmentally friendly gift options.</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promote Brand Visibility: Branded Easter gifts keep your company in the recipient's mind.</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>At Giftingy, we understand Easter's significance and corporate gifting's importance. Our curated selection of Easter corporate gifts is designed to make a lasting impression and help you celebrate the season in style.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Easter Corporate Gifts?</h2>
      <p >Selecting the perfect Easter corporate gifts requires a keen eye for quality, design, and customisation. Giftingy stands out as a premier Easter corporate gifting company, offering a curated selection of gifts that cater to diverse needs. Here's why Giftingy should be your top choice for Easter corporate gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/easterig2.jpg`} alt="Premium Easter Gift Hampers" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialise in creating Easter gifts that resonate with the festive spirit while reflecting your company's appreciation. Our collection includes everything from Easter gift basket hampers to handmade gifts perfect for corporate clients and employees. Whether you're looking to gift luxury items or eco-friendly options, we have something for everyone.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               Our key strengths lie in our ability to offer customised Easter gifts that reflect the recipient's preferences and the occasion. We pride ourselves on being a leading provider of Easter corporate gifts for bulk buying, ensuring that your gifts are delivered on time and in perfect condition. Our products are sourced from the best vendors, ensuring that every item meets the highest standards of quality and design.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy also specialises in bulk gifting, making us the perfect partner for large corporate celebrations. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From unique Easter hamper ideas to handmade Easter gifts for corporate employees, we offer various choices to cater to your needs. Our commitment to customer satisfaction and ability to create lasting impressions make us the preferred choice for Easter corporate gifting in India.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable Gifts: Personalised Easter gifts that resonate with your brand.

            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-Quality Products: Premium items that reflect excellence in craftsmanship.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Diverse Range of Gifts: Options for employees, clients, and partners.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise in Bulk Gifting: Seamless process for large orders.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Timely Delivery: Reliable and punctual service.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Eco-Friendly Options: Sustainable gifting choices.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Support for Local Artisans: Handmade gifts that showcase traditional craftsmanship.
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customer Satisfaction: Our top priority is ensuring a smooth and satisfying gifting experience.
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your Easter corporate gifting needs ensures that your gifts are elegant, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Partner for Exceptional Easter Corporate Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we are committed to providing exceptional Easter corporate gifting solutions that cater to your specific needs and preferences. Our unique selling propositions (USPs) distinguish us as India's leading Easter corporate gifting company:
            </p>
          
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/easterig3.jpg`} alt="Elegant Easter Corporate Gifts" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="EasterCorporateGifts" />

<FaqPage faqs={faqs} />
</>
);
};
export default EasterCorporateGifts;