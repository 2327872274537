import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import TopHeader from './TopHeader';
import './Header.css';

function Header() {
  useEffect(() => {
    const activeLink = document.querySelector('.nav-link.active');
    if (activeLink) {
      activeLink.click();
    }
  }, []);

  const handleTabSwitch = (e, tabContentId) => {
    e.preventDefault();
    document.querySelectorAll('.nav-link').forEach(link => link.classList.remove('active'));
    document.querySelectorAll('.tab-content').forEach(content => content.classList.remove('active'));

    e.target.classList.add('active');
    document.querySelector(tabContentId).classList.add('active');
  };

  return (
    <>
      <TopHeader />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container custom-container">
          <NavLink className="navbar-brand" to="/">Giftingy</NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mx-2">
                <NavLink className="nav-link" exact to="/">Home</NavLink>
              </li>
              <li className="nav-item dropdown mx-2">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="servicesDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Services
                </a>
                <div className="dropdown-menu services-menu" aria-labelledby="servicesDropdown">
                  <div className="row">
                    <div className="d-none d-lg-block col-md-3">
                      <img src="https://gethirednow.in/giftingy/giftingImg.jpg" alt="Service Image" className="img-fluid" />
                    </div>
                    <div className="col-lg-9">
                      <div className="row">
                        <div className="col-md-3 border-right">
                          <NavLink className="dropdown-item" to="/services/corporate-gifting">Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/incentive-gifts">Incentive Gifts</NavLink>
                          <NavLink className="dropdown-item" to="/services/exhibition-and-events-gifting">Exhibition & Events Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/branded-medals-and-trophies">Branded Medals And Trophies</NavLink>
                          <NavLink className="dropdown-item" to="/services/corporate-leather-gifting">Corporate Leather Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/pongal-corporate-gifting">Pongal Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/kartikay-dibam-corporate-gifting">Kartikay Dibam Gifting</NavLink>
                        </div>
                        <div className="col-md-3 border-right">
                          <NavLink className="dropdown-item" to="/services/chocolate-corporate-gifting">Chocolate Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/luxury-home-decor-gifting">Luxury Home Decor</NavLink>
                          <NavLink className="dropdown-item" to="/services/personalised-corporate-gifts">Personalised Corporate Gifts</NavLink>
                          <NavLink className="dropdown-item" to="/services/dryfruits-and-sweets-gifting">Dryfruits And Sweets Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/bar-accessories-corporate-gifting">Bar Accessories Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/Vastu-corporate-gifting">Vastu Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/corporate-gifting-for-milestones-and-Celebrations">Milestones & Celebrations</NavLink>
                        </div>
                        <div className="col-md-3 border-right">
                          <NavLink className="dropdown-item" to="/services/divinity-gifts">Divinity Gifts</NavLink>
                          <NavLink className="dropdown-item" to="/services/indian-traditional-gifts">Indian Traditional Gifts</NavLink>
                          <NavLink className="dropdown-item" to="/services/festival-gifting">Festival Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/navratri-corporate-gifting">Navratri Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/akshay-tritiya-corporate-gifting">Akshay Tritiya Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/gifts-for-her">Gifts for Her</NavLink>
                          <NavLink className="dropdown-item" to="/services/gifts-for-male">Gifts for Male</NavLink>
                        </div>
                        <div className="col-md-3">
                          <NavLink className="dropdown-item" to="/services/luxury-gifting">Luxury Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/wedding-return-gifts">Wedding Return Gifts</NavLink>
                          <NavLink className="dropdown-item" to="/services/diwali-corporate-gifting">Diwali Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/eid-corporate-gifting">Eid Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/dhanteras-corporate-gifting">Dhanteras Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/golf-corporate-gifting">Golf Corporate Gifting</NavLink>
                          <NavLink className="dropdown-item" to="/services/corporate-gifts-for-clients">Gifts for Clients</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/about">About</NavLink>
              </li>
              <li className="nav-item mx-2">
                <a className="nav-link" href="https://giftingy.com/blogs/">Blogs</a>
              </li>
              <li className="nav-item mx-2">
                <NavLink className="nav-link" to="/contact">Contact</NavLink>
              </li>
              <li className="nav-item mx-2">
                <form className="form-inline my-2 my-lg-0">
                  <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                  <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                </form>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
