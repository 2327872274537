import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import 'animate.css';
import './Services.css';
import MetaTags from '../../components/MetaTags';
import CardFlip from '../../components/CardFlip';
import Testimonials from '../../components/Testimonials';
import FaqPage from '../../components/FaqPage'; 
import pdfs from '../../components/PdfsData'; 
import Catalogue from '../../components/CatalogueView';

import ServiceHeader from './ServiceHeader';
const backgroundImageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/images/festivalgifimg1.jpg)`,
    backgroundSize: 'cover', // Change to 'cover' to fill the container
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '30px',
  };
  
const features = [
    { title: "Custom Designs", detail: "Personalised festival gifts tailored to your preferences." },
    { title: "Branding on Gifts", detail: "Keeps your brand memorable and visible." },
    { title: "Value for Money", detail: "High-quality festival gifts within your budget." },
    { title: "Concept Creation", detail: "Unique and innovative gift ideas." },
    { title: "Diverse Range of Gifts", detail: "Extensive selection for all festive occasions." },
    { title: "Quality Assurance", detail: "Premium quality guaranteed." },
    { title: "Timely Delivery", detail: "Reliable and punctual delivery services." },
    { title: "Eco-Friendly Options", detail: "Sustainable and responsible gifting." },
];
const FestivalGifting = () => {
    useEffect(() => {
        new WOW.WOW().init();
    
      }, []);
const faqs = [
    {
      "question": "What are good festival gift ideas?",
      "answer": "Traditional sweets, decorative items, and premium gift sets are good festival gift ideas. Popular choices are customised accessories, branded merchandise, and unique handcrafted items. Consider items like festive hampers, personalised gifts, and luxury products. The key is to choose gifts that reflect the festive spirit and the recipient's preferences. Consulting a festival gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "Why is festival gifting important?",
      "answer": "Festival gifting is important because it expresses appreciation and gratitude during the festive season. These gifts enhance the joy and spirit of celebrations, fostering stronger relationships. Thoughtful and well-chosen gifts reflect the company's appreciation for employees, clients, and partners. They also promote a positive work environment and enhance brand visibility. Investing in quality festival gifts signifies a commitment to hospitality and goodwill.",
      "open": false
    },
    {
      "question": "What are some popular corporate festival gifts for employees?",
      "answer": "Popular corporate festival gifts for employees include branded merchandise, customised accessories, and premium gift sets. Handcrafted traditional crafts, decorative home accents, and personalised gifts are also appreciated. Consider items like festive hampers, tech gadgets, and luxury products. These gifts reflect the company's appreciation and foster a positive work environment. Consulting a corporate festival gifting company can help select the best employee gifts.",
      "open": false
    },
    {
      "question": "How do I choose the right festival gifts?",
      "answer": "Choosing the right festival gifts involves understanding the recipients' preferences and the cultural context of the festival. Opt for high-quality items that reflect thoughtfulness and the festive spirit. Personalisation can add a special touch, making the gifts more meaningful. Consider the practicality and relevance of the gifts to ensure they are appreciated and used. Consulting a festival gifting company can provide valuable insights and recommendations.",
      "open": false
    },
    {
      "question": "What are the benefits of working with a festival gifting company?",
      "answer": "Working with a festival gifting company ensures you receive high-quality, well-crafted items that reflect the festive spirit and the company's appreciation. These companies offer various customisation options to make your gifts unique and personal. They provide expertise in selecting and designing gifts that suit your needs and preferences. Reliable delivery services and excellent customer support are additional benefits. Partnering with a reputable company ensures a seamless and satisfactory gifting experience.",
      "open": false
    },
    {
      "question": "Can I order corporate holiday gifts in bulk?",
      "answer": "Yes, you can order corporate holiday gifts in bulk from various gifting companies. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a corporate holiday gift bulk company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some premium festive gift options?",
      "answer": "Premium festive gift options include bespoke jewellery, luxury gift sets, and high-end tech gadgets. Handcrafted items such as traditional crafts, decorative home accents, and personalised accessories are also appreciated. Consider items like festive hampers, fine wines, and gourmet foods. These gifts reflect thoughtfulness and the festive spirit, making them ideal for premium festive gifting. Consulting a premium festive gift company can provide additional ideas and options.",
      "open": false
    },
    {
      "question": "How do festival gifts enhance corporate celebrations?",
      "answer": "Festival gifts enhance corporate celebrations by adding a touch of thoughtfulness and appreciation. They are ideal for Diwali, Christmas, and other festive events. These gifts reflect the company's commitment to quality and excellence. Customised festival gifts make the celebration more memorable and meaningful. They foster a positive and respectful corporate culture. Consulting a festive corporate gifts company can help select the best gifts for corporate celebrations.",
      "open": false
    },
    {
      "question": "What are some unique festival gift ideas?",
      "answer": "Unique festival gift ideas include bespoke jewellery, customised accessories, and handcrafted souvenirs. High-end luxury tech gadgets, fine wines, and gourmet foods are also popular. Consider items like festive hampers, personalised gifts, and exclusive branded merchandise. The key is to choose gifts that reflect the festive spirit and the recipient's preferences. Consulting a festival gifting company can provide additional ideas and options.",
      "open": false
    },
    {
      "question": "Why should companies invest in branded festival gifts?",
      "answer": "Companies should invest in branded festival gifts to show appreciation and recognition for their employees, clients, and partners. These gifts enhance corporate relationships and convey a commitment to quality and excellence. Branded festival gifts are highly valued and leave a lasting impression on the recipients. They also foster a positive and respectful corporate culture. Investing in branded festival gifts promotes a strong and prestigious brand image.",
      "open": false
    },
    {
      "question": "How can I ensure the quality of festival gifts?",
      "answer": "Ensuring the quality of festival gifts involves selecting reputable gifting companies and suppliers. Check reviews and testimonials to gauge the reliability and quality of their products. Opt for companies with a proven track record of high-quality craftsmanship and customisation. Regularly review samples and provide clear specifications for the customisation. Partnering with a trusted festival gifting company ensures the quality and satisfaction of your gifts.",
      "open": false
    },
    {
      "question": "What are the latest trends in festival gifting?",
      "answer": "The latest trends in festival gifting include eco-friendly products, tech gadgets, and bespoke gift sets. Sustainable and environmentally friendly items are increasingly popular. Personalised festival gifts such as custom jewellery and engraved accessories are in demand. Handcrafted items that reflect traditional craftsmanship are also trending. Staying updated with these trends ensures your gifts are relevant and appreciated.",
      "open": false
    },
    {
      "question": "What are the benefits of customising festival gifts?",
      "answer": "Customising festival gifts adds a personal touch that enhances value and meaning. It shows that extra thought and effort have been put into selecting the gift. Personalisation can include engraving names, adding personal messages, or incorporating the company logo. Customised gifts are more memorable and appreciated by the recipients. They help in fostering stronger personal and corporate relationships.",
      "open": false
    },
    {
      "question": "How do festival gifts benefit employee recognition programs?",
      "answer": "Festival gifts benefit employee recognition programs by adding a touch of appreciation and recognition to the festive celebrations. They make employees feel valued and appreciated, boosting morale and motivation. Customised festival gifts reflect the company's commitment to recognising individual achievements. They enhance job satisfaction and loyalty, promoting a positive work environment. Festival gifts are more impactful and memorable than generic awards.",
      "open": false
    },
    {
      "question": "Can festival gifts be ordered in bulk?",
      "answer": "Yes, festival gifts can be ordered in bulk from most gifting companies. Bulk ordering is ideal for large corporate celebrations and ensures consistency in quality and design across all gifts. Many companies offer discounts and special packages for bulk orders. Bulk ordering also allows for efficient planning and timely delivery. Consulting a festival gifting company can provide valuable insights and options for bulk orders.",
      "open": false
    },
    {
      "question": "What are some cost-effective festival gift options?",
      "answer": "Cost-effective festival gift options include personalised accessories, branded merchandise, and handcrafted souvenirs. Consider items like festive hampers, premium chocolates, and customised stationery. The key is choosing gifts that reflect the festive spirit and the recipient's preferences without compromising quality. Cost-effective gifts can still make a significant impact when chosen thoughtfully. Consulting a festival gifting company can provide additional ideas and options.",
      "open": false
    },
    {
      "question": "Why is personalisation important in festival gifting?",
      "answer": "Personalisation is important in festival gifting because it adds a unique and meaningful touch to the gifts. It shows that the giver has put thought into selecting the gift, making it more memorable and appreciated. Personalised gifts reflect the recipient's tastes and preferences. They enhance the overall impact of the gift, fostering stronger relationships. Personalisation also helps in promoting brand recall and loyalty.",
      "open": false
    },
    {
      "question": "How can festival gifts be used for client retention?",
      "answer": "Festival gifts can be used to retain clients by showing appreciation and strengthening relationships. Customised gifts reflect thoughtfulness and attention to detail, making clients feel valued. They create a positive association with the brand, fostering loyalty. Regular gifting of festival items can enhance client satisfaction and retention rates. Festival gifts are a constant reminder of the company's appreciation and recognition.",
      "open": false
    },
    {
      "question": "What are some good branded festival gifts for employees?",
      "answer": "Good branded festival gifts for employees include customised accessories, branded merchandise, and premium gift sets. Consider items like tech gadgets, personalised stationery, and luxury products. These gifts reflect the company's appreciation and foster a positive work environment. Customisation with the company's logo or festive branding adds a personal touch. Consulting a branded corporate gifting company can help select the best employee gifts.",
      "open": false
    },
    {
      "question": "How do festival gifts enhance personal celebrations?",
      "answer": "Festival gifts enhance personal celebrations by adding a touch of joy and appreciation to the festivities. They are ideal for Diwali, Christmas, and other festive events. These gifts reflect thoughtfulness and the festive spirit, making the celebration more memorable and meaningful. Customised festival gifts show appreciation for the recipients and foster stronger personal connections. Investing in quality festival gifts signifies a commitment to hospitality and detail.",
      "open": false
    }
  ]
  
     
return (
<>
<MetaTags
        title="Premier Festival Gifting Company in India"
        description="Discover exquisite festival gifts and premium corporate gifts from India's leading festival gifting company. Perfect for personal and corporate celebrations."
        keywords="Festival gifting companies in India, Festival gifting companies, Festival gift ideas, Corporate holiday gifts in bulk, Corporate festival gifting, Corporate festival gifts for employees, Corporate festival gifts for clients, Festive corporate gifts, Festive corporate gifts company, Premium festive gift company, Corporate festival gifts for employees in bulk, Corporate festival gifts for employees branded, Branded corporate gifting, Branded festival gifts for employees, Branded festival gifts for management, Branded festival gifts for clients"
      />
<ServiceHeader title="Festival Gifting" imageUrl={`${process.env.PUBLIC_URL}/images/festivalgifbn.jpg`}/>

<div className="container1">
   <div className="content">
      <h2 className="wow fadeInUp heading1" data-wow-delay="0.2s">The Joy of Festival Gifting</h2>
      <p className="wow fadeInUp" data-wow-delay="0.3s">
      Festival gifting is an integral part of celebrations in India, reflecting the festive season's joy, gratitude, and goodwill. Whether it's Diwali, Christmas, or any other festival, gifting is a way to express appreciation and strengthen relationships. Festival gifts range from traditional sweets and decorative items to premium corporate gifts and branded merchandise. The right gift can make the festive season more memorable and meaningful for both personal and corporate recipients.
      </p>
      <p className="wow fadeInUp" data-wow-delay="0.4s">
      Festival gifting companies in India offer various options to cater to different tastes and preferences. From unique festival gift ideas to bulk corporate holiday gifts, these companies ensure that every gift is special. Corporate festival gifts for employees and clients are particularly important as they reflect the company's appreciation and foster a positive work environment. These gifts can be customised and branded, making them even more impactful.

      </p>
   </div>
</div>
<div className="features">
   <div className="container2 uniqueGiftBg" style={backgroundImageStyle}>
      <p className="wow fadeInUp paraBold" data-wow-delay="0.6s">
      Choosing the perfect festival gift involves understanding the recipient's preferences and the cultural context of the festival. Here are some key aspects that make festival gifting a popular choice:
      </p>
      <ul className="wow fadeInUp unlist" data-wow-delay="0.7s">
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Enhances the festive spirit and joy</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Strengthens personal and corporate relationships</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Reflects appreciation and gratitude</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Suitable for various types of recipients</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Offers a wide range of customisation options</p>
            </li>
         </div>
         <div className="unique-row">
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Ideal for both personal and corporate gifting</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Available in premium and branded options</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Can be ordered in bulk for corporate needs</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Promotes lasting memories and connections</p>
            </li>
            <li className="unique-gift-item">
               <p className="unique-gift-text wow fadeInUp">Supports local artisans and craftsmanship</p>
            </li>
         </div>
      </ul>
      <p className='textWhite'>Festival gifting is more than a tradition; it celebrates relationships and goodwill.</p>
   </div>
</div>
<div className="container new-section mt-5">
   <div className='latestsec'>
      <h2 className="wow fadeInUp headingnew" data-wow-delay="0.5s">Why Choose Giftingy for Festival Gifting?</h2>
      <p >Selecting the perfect festival gifts requires a keen understanding of cultural nuances and a commitment to quality. Giftingy is a premier festival gifting company offering a curated selection of the finest festival gifts. Here's why Giftingy should be your top choice for festival gifting:</p>
   </div>
   <div className="row">
      <div className="col-md-6 centerimg">
         <img className="gifting-image1 wow zoomIn" src={`${process.env.PUBLIC_URL}/images/festivalgifimg3.jpg`} alt="Festival Gifting Solutions" />
      </div>
      <div className="col-md-6 position-relative zooms">
         <div className="box-container">
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               At Giftingy, we specialise in creating festival gifts that resonate with the festive spirit and reflect the company's appreciation. Our festival gift ideas include beautifully crafted items, from traditional sweets and decorative items to premium corporate gifts and branded merchandise. Whether you are looking for corporate holiday gifts in bulk or unique festival gifts for employees and clients, our collection caters to diverse tastes and preferences.
               </p>
            </div>
            <div className="box box-one wow fadeInUp">
               <p className="box-content">
               One of our key strengths is our ability to offer customised festival gifts that reflect the recipient's preferences and the cultural context of the festival. We pride ourselves on being a leading corporate festival gifts company that delivers excellence in craftsmanship. Our products are sourced from the best manufacturers, ensuring that every item meets the highest standards of quality and design.
               </p>
            </div>
            <div className="box box-two wow fadeInUp">
               <p className="box-content ">
               Giftingy also specialises in bulk and branded corporate festival gifts, making us the perfect partner for large corporate celebrations. Our seamless and hassle-free process ensures that your gifts are delivered on time and in perfect condition. From festive corporate gifts to branded festival gifts for employees and clients, we offer various choices to cater to your needs. Our commitment to customer satisfaction and ability to create lasting impressions make us the preferred choice for festival gifting in India.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>
<div className=" gift-ideas-container">
   <div className="gift-ideas-content container">
     
      <div className="gift-ideas-boxes">
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Customisable festival gifts to suit individual preferences
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            High-quality products with meticulous craftsmanship
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Wide range of festival gifting ideas for various occasions
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Expertise as a festival gifting company in India
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Convenient online ordering and customisation options
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Reliable and timely delivery services
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Commitment to customer satisfaction
            </p>
         </div>
         <div className="gift-idea-item">
            <div className="gift-idea-pointer"></div>
            <p className="gift-idea-text wow fadeInUp">
            Extensive selection of beautifully crafted festival gifts
            </p>
         </div>
         <p className='textWhite'>Choosing Giftingy for your festival gifting needs ensures that your gifts are beautiful, meaningful, and appreciated.</p>
      </div>
   </div>
</div>
<Catalogue pdfs={pdfs} />
<section className='section-part'>
   <div className='container'>
      <div className='row'>
         <div className='col-md-5'>
            <h2 className="wow fadeInUp section-parthead" data-wow-delay="0.5s">Unique Selling Propositions of Giftingy</h2>
            <h3 className='textYellow'>Giftingy: Your Source for Exceptional Festival Gifts</h3>
            <p className="wow fadeInUp section-partpara " data-wow-delay="0.8s">
            At Giftingy, we pride ourselves on offering exceptional festival gifting solutions catering to your needs and preferences. Our unique selling propositions (USPs) set us apart as India's leading festival gifting company. Here's what makes us unique:
            </p>
            <p className='wow fadeInUp section-partpara section-partlheight'>Giftingy offers custom designs that add a personal touch to your festival gifts, making them more meaningful and memorable. Our branding on gifts ensures that your company remains at the forefront of the recipient's mind, enhancing brand recall and visibility. We provide value for money by offering high-quality gifts that fit within your budget, ensuring you get the best return on your investment.</p>
         </div>
         <div className='col-md-7'>
            <div className="gallery image-container1">
               <img className="gifting-image wow zoomIn foreground-image" src={`https://momentzin.swipepages.media/2022/8/5fbb4e04c0514d0011e9d74f/multi-layer-image2.svg`} />
               <img className="gifting-image wow zoomIn background-image" src={`${process.env.PUBLIC_URL}/images/festivalgifimg2.jpg`} alt="Customisable Festival Gifts" />
            </div>
         </div>
      </div>
   </div>
</section>
<CardFlip features={features} />


<Testimonials serviceId="luxuryHomeDecor" />

<FaqPage faqs={faqs} />
</>
);
};
export default FestivalGifting;