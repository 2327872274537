// Canonical.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Canonical = () => {
  const location = useLocation();

  useEffect(() => {
    const setCanonicalLink = () => {
      const canonicalLink = document.querySelector('link[rel="canonical"]');
      const currentURL = `${window.location.origin}${location.pathname}`;

      if (canonicalLink) {
        canonicalLink.setAttribute('href', currentURL);
      } else {
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = currentURL;
        document.head.appendChild(link);
      }
    };

    setCanonicalLink();
  }, [location]);

  return null;
};

export default Canonical;
